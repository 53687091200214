import AuthenticatedResource  from 'resources/public/AuthenticatedResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class NoteResource extends AuthenticatedResource {
  static urlRoot = '/organizations/:organization_id/:notable_type/:notable_id/notes' as const

  readonly id: number | undefined = undefined;

  pk() {
    return this.id?.toString();
  }

}