import { useHistory, useParams } from 'react-router-dom';
// import { useSuspense } from '@rest-hooks/react';

// import OnboardingResource from 'resources/organization/OnboardingResource';

import LastView from 'utils/localStorage/LastView';

const Redirect = () => {

    const params = useParams();
    const organizationId = params.organizationId;

    const history = useHistory();

    const currentPath = history.location.pathname;
    const normalizedPath = currentPath.endsWith('/') ? '' : organizationId + '/';

    // const {status} = useSuspense(OnboardingResource.detail(), {organization_id: organizationId});

    // const targetRoute = status === "started"
    //                             ? "start"
    //                             : "settings/details";

    const targetRoute = LastView.read() || "events";
    LastView.write(targetRoute);
    history.push(`${normalizedPath}${targetRoute}`);

    return null;
};

export default Redirect;
