import React, { useState } from "react";
import PropTypes from "prop-types";

import CheckInAlert from './CheckInAlert';
import CheckInButton from './CheckInButton';

const Actions = ({ uuid, redeemState, orderAmount, allowCheckout, onCheckIn }) => {
  const [checkInAll, setCheckInAll] = useState(false);
  
  const isCheckedIn = redeemState === "completed";
  
  return (
    <div style={{width: '100%', display: 'flex', flexDirection: 'column', gap: '8px'}}>

      {(!isCheckedIn && orderAmount > 1) && (
        <CheckInAlert 
          orderAmount={orderAmount}
          checkInAll={checkInAll}
          onCheckInAllChange={setCheckInAll}
        />
      )}
      
      <CheckInButton
        uuid={uuid}
        isCheckedIn={isCheckedIn}
        orderAmount={orderAmount}
        checkInAll={checkInAll}
        allowCheckout={allowCheckout}
        onCheckIn={onCheckIn}
      />
      
    </div>
  );
};

Actions.propTypes = {
  uuid: PropTypes.string,
  redeemState: PropTypes.string,
  orderAmount: PropTypes.number,
  allowCheckout: PropTypes.bool,
  onCheckIn: PropTypes.func,
};

export default Actions;