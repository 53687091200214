import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import logo from 'assets/images/logo-membrzclub.png'

import setCustomTheme from 'utils/setCustomTheme'
import { defaultTheme, getContainerClassName } from 'utils/themes';

import { t } from 'i18n/index';

import 'assets/css/layouts.css';

const PublicLayout = ({ isLogin=false, theme, children }) => {
  const [logoSwitch, setLogoSwitch] = useState(logo)
  const containerClassName = getContainerClassName(isLogin, "layout-sidebar");

  useEffect(() => {
    if (theme && theme.theme) {
      setLogoSwitch(theme.banner_url || logo);
      setCustomTheme(theme.theme);
    }
    return  () => {
      setLogoSwitch(logo);
      setCustomTheme(defaultTheme);
    }
  }, [])

  return (
    <div className={containerClassName}>
      <div className="left-pane">
        <div className="wrapper">

          <div className="logo">
            <img src={ logoSwitch } alt='logo'/>
          </div>

          <div className="content">
              { children }

              { !isLogin &&
                <p className="no-account">
                  {t('layouts:public:pagePrimaryDescription')} <br />
                  {t('layouts:public:pageSecondaryDescription')} <a href="https://crowdclass.com" target="_blank" rel="noreferrer">{t('layouts:public:here')}</a>
                </p>
              }
          </div>
        </div>
      </div>

      <div className={"right-pane"}></div>

    </div>
  );
};

PublicLayout.propTypes = {
  isLogin: PropTypes.bool,
  theme: PropTypes.object,
  children: PropTypes.node,
}
export default PublicLayout;
