import React from "react";
import PropTypes from "prop-types";

import NumberTextField from "components/NumberTextfield";

const MinAmount = ({ min_amount, onChange, disabled }) => {
  return (
    <NumberTextField
      name="min_amount"
      size="small"
      color="warning"
      fullWidth
      value={min_amount || ""}
      onChange={onChange}
      disabled={disabled}
      label="Min Amount per user. Leave empty for 1"
    />
  );
};

MinAmount.propTypes = {
  min_amount: PropTypes.number,
  price: PropTypes.number,
  currency: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default MinAmount;
