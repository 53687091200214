import React from 'react';
import PropTypes from 'prop-types';

import Description from 'ui/text/Description';

import TimerIcon from '@mui/icons-material/Timer';
import moment from 'moment';

const Date = ({end_date}) => {
  return (
    <div>
        <TimerIcon style={{color: 'var(--highlightColor)'}} fontSize='14px'/>
        <Description secondary>{moment(end_date).format('DD/MM/YYYY HH:mm')}</Description>
    </div>
  );
};

Date.propTypes = {
  end_date: PropTypes.string
};

export default Date;
