import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useController } from '@rest-hooks/react';
import { useParams } from 'react-router-dom';

import PromoCodeResource from 'resources/Profile/PromoCodeResource';

import StyledTextField from 'components/StyledComponents/StyledTextField';

import Button from 'ui/Button';

import { useError } from 'utils/useErrorController';

import { Alert, CircularProgress, IconButton, InputAdornment } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutline';

import { t } from 'i18n/index';

const PromoCodeValidator = ({objectId, onObjectChange, checkoutVars, onCheckoutVarsChange, onDisabledChange}) => {

  const { promoCode } = checkoutVars;

  const [result, setResult] = useState(null);
  const [open, setOpen] = useState(!!promoCode);

  const { fetch } = useController();
  const { organizationSlug } = useParams();
  const { handleError, setLoading, loading } = useError();

  const validatePromoCode = async () => {
    try {
      setLoading(true);
      const { success, product } = await fetch(PromoCodeResource.create(), {organization_slug: organizationSlug, product_id: objectId}, {promo_code: promoCode});
      onObjectChange(product)
      setResult(success);
      onDisabledChange(false);
      setLoading(false);
    } catch (error) {
      handleError(error);
      onDisabledChange(false);
    }
  };

  const handlePromoCodeChange = (event) => {
    onDisabledChange(true);
    onCheckoutVarsChange({...checkoutVars, promoCode: event.target.value });
  };

  const promoCodeResult = result === false 
                            ? t('literal:invalidPromoCode') 
                            : result === true 
                              ? t('literal:promoCodeApplied')
                              : '';

  return (
    <>
    {open ? (
      <>
        <StyledTextField
          label="Promo Code"
          size="small"
          margin='dense'
          value={promoCode}
          onChange={handlePromoCodeChange}
          onBlur={validatePromoCode}
          fullWidth
          color="warning"
          sx={{marginBottom: '16px'}}
          InputProps={{endAdornment:
            <InputAdornment position="end">
              <IconButton
                onClick={()=> validatePromoCode()}
                onMouseDown={(e) => e.preventDefault()}
                disabled={loading}
              >
                {loading ? <CircularProgress size={20} sx={{color: 'var(--primaryTextColor)'}}/> : <ArrowForwardIcon />}
              </IconButton>
            </InputAdornment>
          }}
        />
        {promoCodeResult && <Alert sx={{marginBottom: '16px'}} severity={result ? 'success' : 'error'}>{promoCodeResult}</Alert>}
      </>
    ) : (
      <div style={{margin: "25px 0px 25px"}}>
        <Button
          text
          onClick={() => setOpen(true)}
          small
          icon={AddCircleOutlineOutlinedIcon}
        >
          {t('literal:addPromoCode')}
        </Button>
      </div>
    )}
    </>
  );
};

PromoCodeValidator.propTypes = {
    objectId: PropTypes.number,
    onObjectChange: PropTypes.func,
    checkoutVars: PropTypes.object,
    onCheckoutVarsChange: PropTypes.func,
    onDisabledChange: PropTypes.func
};

export default PromoCodeValidator;