import { useState } from 'react';
import { useController } from '@rest-hooks/react';
import { useParams } from 'react-router-dom';

import ProductResource from 'resources/Profile/ProductResource';
import MembershipResource from 'resources/Profile/MembershipResource';
import TicketResource from 'resources/Profile/TicketResource';
import EventResource from 'resources/Profile/EventResource';

import { useError } from './useErrorController';
import { offerCategory } from './offersFormHelper';
import { checkLoginStatus } from './checkLoginStatus';

import { calculateProductMaxAmount } from './calculateProductMaxAmount';

import { t } from "i18n/index";

const defaultModalState = {
  authModal: false,
  redeemModal: false,
  checkoutModal: false,
};

const defaultCheckoutModalsState = {
  amountModal: false,
  paymentModal: false
};

export const usePreActionController = (organizationSlug, activeTag=null, eventSlug=null) => {
  
  const [currentAction, setCurrentAction] = useState(null);
  const [modals, setModals] = useState(defaultModalState)
  const [checkoutModals, setCheckoutModals] = useState(defaultCheckoutModalsState);

  const defaultCheckoutVars = {
    amount: currentAction?.product?.min_amount || 1,
    maxAmount: 1,
    step: 1,
    promoCode: ""
  };

  const [checkoutVars, setCheckoutVars] = useState(defaultCheckoutVars);
  
  const { fetch } = useController();
  const { handleError } = useError();
  const { ticketSlug } = useParams();

  const handleAction = (product, redemptionMethod) => {
    setCurrentAction({ product, redemptionMethod });

    //if redemption, only redirect to redeemModal if logged in
    const isLoggedIn = checkLoginStatus();
    if (redemptionMethod === 'points') {
      const targetModal = isLoggedIn ? 'redeemModal' : 'authModal';
      setModals({ ...modals, [targetModal]: true });
    
    //if purchase, regardless of the login status, redirect to checkoutModal
    } else {
      setModals({ ...modals, checkoutModal: true });
    }
  };

  const handleClose = () => {
    setCurrentAction(null);
    setModals(defaultModalState);
    setCheckoutModals(defaultCheckoutModalsState);
    setCheckoutVars(defaultCheckoutVars);
  };

  const handleOpenAuthModal = () => {
    setCheckoutModals(defaultCheckoutModalsState);
    setModals({ ...modals, authModal: true });
  };

  const handleProductChange = (product) => {
    const newAction = { ...currentAction, product };
    setCurrentAction(newAction);
  };
  
  //handleSuccessfulAuth helper functions
  const fetchProducts = async () => {
    let products;

    const {category} = currentAction.product;
    if (category === offerCategory.ticket.value) {
      
      const event = await fetchEvent();

      if (event.single) {
        products = event.tickets;
      } else {
        // Return empty array if event ended during auth
        products = event.sessions?.length > 0 
          ? event.sessions.flatMap(session => session.tickets)
          : [];
      }
    } else {
      products = await fetch(ProductResource.list(), {organization_slug: organizationSlug, tag_id: activeTag?.id});
    }

    return products;
  };

  const fetchEvent = async () => {
    // the presence of ticketSlug determines if we are in a single event page with secret ticket or without
    // both TicketResource and EventResouce return an event
    // TicketResource returns an event with the secret ticket whereas EventResource returns an event with all tickets

    const event = ticketSlug
      ? await fetch(TicketResource.detail(), {organization_slug: organizationSlug, event_slug: eventSlug, id: ticketSlug })
      : await fetch(EventResource.detail(), {organization_slug: organizationSlug, id: eventSlug});
    
    return event;
  };

  const getUpdatedCheckoutVars = (newMaxAmount, amountNeedsUpdate, promoCodeNeedsUpdate) => {
    const {amount, promoCode} = checkoutVars;

    const newAmount = amountNeedsUpdate ? newMaxAmount : amount;
    const newPromoCode = promoCodeNeedsUpdate ? "" : promoCode;
    const newMessage = getUpdateMessage(newMaxAmount, amountNeedsUpdate, promoCodeNeedsUpdate);

    return {
      ...checkoutVars,
      amount: newAmount,
      maxAmount: newMaxAmount,
      promoCode: newPromoCode,
      message: newMessage
    };
  };

  const getUpdateMessage = (newMaxAmount, amountNeedsUpdate, promoCodeNeedsUpdate) => {
    if (amountNeedsUpdate && promoCodeNeedsUpdate) return `${t('errors:reachedMaxAmount', {maxAmount: newMaxAmount})} ${t('errors:entitledToCoupon')}`;
    if (amountNeedsUpdate) return t('errors:reachedMaxAmount', {maxAmount: newMaxAmount});
    if (promoCodeNeedsUpdate) return t('errors:entitledToCoupon');
    return '';
  };

  const updateCheckoutVars = (newMaxAmount, amountNeedsUpdate, promoCodeNeedsUpdate) => {
    const newCheckoutVars = getUpdatedCheckoutVars(newMaxAmount, amountNeedsUpdate, promoCodeNeedsUpdate);
    setCheckoutVars(newCheckoutVars);
  };

  const handleModalTransition = (amountNeedsUpdate, promoCodeNeedsUpdate) => {
    const newOpenModal = currentAction.redemptionMethod === 'points' ? 'redeemModal' : 'checkoutModal';
    const newModalState = { ...modals, [newOpenModal]: true, authModal: false};
    setModals(newModalState);

    if (newOpenModal === 'checkoutModal') {
      const openModalAmountModal = amountNeedsUpdate || promoCodeNeedsUpdate;
      const newCheckoutModalsState = { amountModal: openModalAmountModal, paymentModal: !openModalAmountModal };
      setCheckoutModals(newCheckoutModalsState);
    }
  };

  const handleSuccessfulAuth = async () => {
    await fetch(MembershipResource.list(), {organization_slug: organizationSlug});

    const products = await fetchProducts();
    const product = products.find(product => product.id === currentAction.product.id);
    if (!product) {
      handleClose();
      return;
    }

    const {redeem_limit, redeemed_count, supply_limit, issued_counter, discount_price} = product;
    
    const alreadyRedeemedMax = redeem_limit && redeemed_count >= redeem_limit;
    if (alreadyRedeemedMax) {
      handleError(t('errors:redeemedMax'));
      handleClose();
      return;
    }
    
    if (discount_price) handleProductChange(product);

    const newMaxAmount = calculateProductMaxAmount(redeem_limit, supply_limit, issued_counter, redeemed_count);
    const amountNeedsUpdate = checkoutVars.amount > newMaxAmount;
    const promoCodeNeedsUpdate = !!discount_price;

    updateCheckoutVars(newMaxAmount, amountNeedsUpdate, promoCodeNeedsUpdate);
    handleModalTransition(amountNeedsUpdate, promoCodeNeedsUpdate);
  };

  return {
    checkoutModals,
    checkoutVars,
    currentAction,
    modals,
    onAction: handleAction,  
    onAuthModalOpen: handleOpenAuthModal,
    onCheckoutModalsChange: setCheckoutModals,
    onCheckoutVarsChange: setCheckoutVars,
    onClose: handleClose,
    onProductChange: handleProductChange,
    onSuccessfulAuth: handleSuccessfulAuth,
  };
};
