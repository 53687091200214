import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSuspense } from '@rest-hooks/react';

import ThemeResource from 'resources/Profile/ThemeResource';

import About from 'containers/public/Profile/Body/About';

import Button from 'ui/Button';
import Title from 'ui/text/Title';
import Description from 'ui/text/Description';
import ProfileImages from 'ui/ProfileImages';

import isMobileDevice from 'utils/mobileAndTabletCheck';
import { externalUrl } from 'utils/urls';
import { useFreeSubscription } from 'utils/freeSubscriptionController';

import logoDefault from 'assets/images/logo-membrzclub.png'
import logoInverted from 'assets/images/logo-membrzclub-inverted.png'

import { t } from "i18n/index";

import './styles.css';

const nonBoldSlugs = ['smup'];

const Sidebar = ({organization}) => {

  const {user_is_free_member, user_is_member, followers, cover_image, 
         image, title, free_pass_claim_token, has_paid_pass, slug} = organization;

  const theme = useSuspense(ThemeResource.detail(), { id: slug, organization_slug: slug });

  const history = useHistory();
  const handleRedirect = (target) => {
    const localTargets = ['memberships', 'events', 'events/next', 'store'];

    //custom cta action is a local link or function has been called directly with "memberships"
    if (localTargets.includes(target)) {
      history.push(`/${slug}/${target}`);

    //custom cta action is a link with https://
    } else if (target.startsWith("https://")) {
      window.open(target, "_blank");
    
    //custom cta action is subscribe or a link without https://
    } else {
      openFreeSubscriptionModal()
    }
  };

  const {openFreeSubscriptionModal} = useFreeSubscription();
  const isDesktop = !isMobileDevice();

  const getButtonConfig = () => {

    const inMembershipsPage = window.location.pathname.includes("memberships");
    const defaultButtonLabel = theme?.theme?.custom_cta || t('profile:sidebar:joinTheClub');
    const has_custom_cta_action = theme?.theme?.custom_cta_action;

    //show custom button
    if (has_custom_cta_action && !window.location.pathname.includes(theme.theme.custom_cta_action)) {
      
      //if the custom cta action is subscribe and we are in the memberships page, do not show the button
      if (window.location.pathname.includes("memberships") && theme.theme.custom_cta_action === "subscribe") return null;

      return {
        label: defaultButtonLabel,
        action: () => handleRedirect(theme.theme.custom_cta_action)
      };
    }

    //upgrade and join buttons should not be shown in the memberships page
    //the last condition is important otherwise the buttons will be shown in the page targetted by the custom cta action
    if (!inMembershipsPage && !has_custom_cta_action) {

      // show upgrade button (has_paid_pass refers to the organization)
      if (user_is_free_member && has_paid_pass && !inMembershipsPage && !has_custom_cta_action) {
        return {
          label: t('literal:upgradeMembership'),
          action: () => handleRedirect("memberships")
        };
      }

      // show join button
      if (!user_is_member && !inMembershipsPage && !has_custom_cta_action) {
        return {
          label: defaultButtonLabel,
          action: free_pass_claim_token 
                    ? () => openFreeSubscriptionModal() 
                    : () => handleRedirect("memberships")
        };
      }
    }
  };

  const buttonConfig = getButtonConfig();

  return (
    <div className='sidebar'>
      <>
        <ProfileImages coverImageSrc={cover_image} profileImageSrc={image} />

        <div className='content'>
          <Title bold={!nonBoldSlugs.includes(slug)}>{title}</Title>

          { followers >= 20 &&
            <Description secondary>{followers + " " + t('literal:members_sc')}</Description>
          }

          { buttonConfig &&
            <Button
              highlighted
              fullwidth
              onClick={buttonConfig.action} >
              {buttonConfig.label}
            </Button>
          }

          { isDesktop &&
            <About inSidebar={true}/>
          }
        </div>
      </>

      <div className='logo'>
        <p>Powered By</p>
        <a target='_blank'
            rel='noopener noreferrer'
            href={externalUrl}
            title="Membrz.Club"
          >
          <img src={theme?.theme?.primary_text_color === 'white' ? logoInverted : logoDefault} alt="Membrz.Club"/>
        </a>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  organization: PropTypes.object,
};

export default Sidebar;
