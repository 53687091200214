import React from 'react';
import PropTypes from 'prop-types';

import NumberTextField from 'components/NumberTextfield';

const Points = ({reward, isDisabled, onChange}) => {

  return (
    <NumberTextField
      fullWidth
      size='small'
      name="points"
      label={`Points earned per month`}
      color="warning"
      value={reward.points}
      onChange={onChange}
      disabled={isDisabled} 
    />
  );
};

Points.propTypes = {
  reward: PropTypes.object,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func
};

export default Points;


