import React, { useEffect, useState } from 'react';
import { useController } from '@rest-hooks/react';
import { useParams } from 'react-router-dom';

import StatsResource from 'resources/organization/StatsResource';

import LineChart from './LineChart';
import Overview from './Overview';
import SimpleTable from './SimpleTable';

import Subtitle from 'ui/text/Subtitle';

import { useError } from 'utils/useErrorController';

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement } from "chart.js";

import { Skeleton } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';

const StatsTabContent = () => {

  const [data, setData] = useState(null);

  const { handleError } = useError();
  const { fetch } = useController();

  const params = useParams();
  const organizationId = parseInt(params.organizationId);

  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement);

  const fetchData = async () => {
      try {
        const response = await fetch(StatsResource.detail(), {organization_id: organizationId});
        setData(response);
      } catch (error) {
        handleError(error);
      }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePageClick = (page) => {
    window.open(`${window.location.origin}${page}`, '_blank');
  };
  
  if (!data) {
    return <Skeleton variant="rectangular" width="100%" height={500} animation={false} />;
  }

  const tableSections = [
    {
      title: 'Top Pages',
      columns: [
        { label: 'Page', value: 'page' },
        { label: 'Visitors Count', value: 'visitors' }
      ],
      rows: data['event:page'],
      onClick: handlePageClick,
      Icon: <LinkIcon color="warning" />
    },
    {
      title: 'Top Sources',
      columns: [
        { label: 'Source', value: 'source' },
        { label: 'Visitors Count', value: 'visitors' }
      ],
      rows: data['visit:source']
    },
    {
      title: 'Top Devices',
      columns: [
        { label: 'Device', value: 'device' },
        { label: 'Visitors Count', value: 'visitors' }
      ],
      rows: data['visit:device']
    },
    {
      title: 'Top Countries',
      columns: [
        { label: 'Country', value: 'country' },
        { label: 'Visitors Count', value: 'visitors' }
      ],
      rows: data['visit:country']
    }
  ];

  return (
    <>
      <Overview data={data.aggregate} />
      <div className='grid'> 
        <Subtitle padded>Daily Visitors</Subtitle>
        <LineChart data={data.timeseries} labelKey="date" dataKey="visitors" />

        {tableSections.map((section, index) => (
          <React.Fragment key={index}>
            <Subtitle padded>{section.title}</Subtitle>
            <SimpleTable
              columns={section.columns}
              rows={section.rows}
              onClick={section.onClick}
              Icon={section.Icon}
            />
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default StatsTabContent;