import React from "react";
import PropTypes from "prop-types";

import Chips from "./Chips";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";

  const SelectMenu = ({
    isDisabled,
    open,
    showPrivate,
    slim,
    title,
    anchorEl,
    passes,
    reward_ids,
    onChange,
    onClose,
    onOpen
  }) => {
  
  const publicLabel = showPrivate ? "Public - Everyone" : "Public & Free Members";
  
  const tiersListStyles = { 
    maxHeight: "32px", 
    ...(showPrivate && { marginLeft: "16px" }) 
  };

  const selectInput = <OutlinedInput id="select-multiple-chip" label="Chip" />;
  const selectRenderValue = (selected) => {
    return (
      <Chips selected={selected} passes={passes} />
    )
  };

  const handleSelect = (event) => {
    const { value } = event.target;
    let newValue;

    if (value.at(-1) === "private") {
      newValue = ["private"].concat(passes.map((item) => item.id));
    } else if (value.at(-1) === "public") {
      newValue = ["public"];
    } else if (value) {
      newValue = value.filter(item => item !== "public" && item !== "private");
    } else {
      newValue = value;
    }

    onChange({target: {name: "reward_ids", value: newValue}});
  };

  const menuProps = slim ? {
    anchorEl: anchorEl,
    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
    transformOrigin: { vertical: 'top', horizontal: 'left' },
  } : {};

  return (
    <Select
      fullWidth
      multiple
      color="warning"
      size="small"
      label={title}
      disabled={isDisabled}
      onChange={handleSelect}
      value={reward_ids || []}
      input={selectInput}
      renderValue={selectRenderValue}
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      MenuProps={menuProps}
    >
      
      <MenuItem value={"public"} style={{ maxHeight: "32px" }}>
        <Checkbox checked={reward_ids?.includes("public")} />
        {publicLabel}
      </MenuItem>

      { showPrivate && <MenuItem value={"private"} style={{ maxHeight: "32px" }}>
        <Checkbox checked={reward_ids?.includes("private")} />
        Private - Members Only
      </MenuItem>}

      {passes.map(({id, title}) => (
        <MenuItem
          key={id}
          value={id}
          style={tiersListStyles}
        >
          <Checkbox checked={reward_ids?.includes(id)} />
          <ListItemText primary={title} />
        </MenuItem>
      ))}

    </Select>
  );
};

SelectMenu.propTypes = {
  isDisabled: PropTypes.bool,
  open: PropTypes.bool,
  showPrivate: PropTypes.bool,
  slim: PropTypes.bool,
  title: PropTypes.string,
  anchorEl: PropTypes.object,
  passes: PropTypes.array,
  reward_ids: PropTypes.array,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func
};

export default SelectMenu;