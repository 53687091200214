import { statuses } from "./statuses";

import { formatPrice } from "utils/rewards";
import { getDateFormat } from "utils/tables/getDateFormat";

import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import SellIcon from "@mui/icons-material/Sell";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventIcon from "@mui/icons-material/Event";
import ReceiptIcon from "@mui/icons-material/Receipt";
import HomeIcon from "@mui/icons-material/Home";
import ClassIcon from "@mui/icons-material/Class";
import StorefrontIcon from "@mui/icons-material/Storefront";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import DiscountIcon from "@mui/icons-material/Discount";
import SummarizeIcon from "@mui/icons-material/Summarize";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ContactMailIcon from '@mui/icons-material/ContactMail';

export const getListItems = (order) => [
  { label: "Order Id", value: order.id || "N/A", icon: SellIcon },
  {
    label: "Status",
    value: statuses[order.status].label,
    icon: statuses[order.status].icon,
    iconColor: statuses[order.status].iconColor,
  },
  { label: "User Name", value: order.user, icon: PersonIcon },
  { label: "User Email", value: order.email, icon: EmailIcon },
  { label: "Invoice Name", 
    value: order.invoice_name,
    icon: AccountBoxIcon,
    condition: !!order.invoice_name
  },
  { label: "Invoice Email",
    value: order.invoice_email,
    icon: ContactMailIcon,
    condition: !!order.invoice_email
  },
  {
    label: "VAT",
    value: order.vat,
    icon: ReceiptIcon,
    condition: !!order.vat,
  },
  {
    label: "User Address",
    value: order.address,
    icon: HomeIcon,
    condition: !!order.address,
  },
  { label: "Item Name", value: order.item_title, icon: ClassIcon },
  {
    label: "Item Price",
    value: formatPrice(order.item_price, order.currency),
    icon: AttachMoneyIcon,
    showLabel: true,
  },
  {
    label: "Quantity",
    value: order.amount,
    icon: ShoppingCartIcon,
    showLabel: true,
  },
  {
    label: "Net Value",
    value: formatPrice(order.net_value, order.currency),
    icon: MonetizationOnIcon,
    showLabel: true,
  },
  {
    label: "Service Fees",
    value: formatPrice(order.application_fee, order.currency),
    icon: AccountBalanceIcon,
    showLabel: true,
    condition: !!order.application_fee,
  },
  {
    label: "Total Discount",
    value: `${formatPrice(order.discount, order.currency)} (${
      order.discount_detail
    })`,
    icon: DiscountIcon,
    showLabel: true,
    condition: !!order.discount,
  },
  {
    label: "Total Value",
    value: formatPrice(order.total_value, order.currency),
    icon: SummarizeIcon,
    showLabel: true,
  },
  {
    label: "Payment Method",
    value: order.payment_method,
    icon: StorefrontIcon,
  },
  {
    label: "Completed At",
    value: getDateFormat(order.charge_succeded_at, "lll"),
    icon: EventAvailableIcon,
    condition: !!order.charge_succeded_at,
  },
  {
    label: "Created At",
    value: getDateFormat(order.created_at, "lll"),
    icon: EventIcon,
  },
];