import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSuspense } from '@rest-hooks/react';

import UserLanguageResource from 'resources/Profile/UserLanguageResource';

import Label from "ui/text/Label";
import Button from "ui/Button";

import { getNextChargeDateAndDay } from "utils/profile/claim/getNextChargeDateAndDay";
import { getTargetLanguage } from 'utils/profile/getTargetLanguage';
import { currencySymbol } from 'utils/rewards';
import { convertPriceFromCents } from "utils/rewards";
import { useError } from 'utils/useErrorController';
import { capitalize } from 'utils/capitalize';

import { t } from "i18n/index";

const getButtonLabel = (frequency, caller, processingEupago, processingBizum) => {
  if (frequency === "lifetime") {
    if (processingEupago || processingBizum) {
      return t('literal:confirmTransaction');
    } else if (caller === "mbway" || caller === "bizum") {
      return `${t('profile:memberships:claimModal:buyNowWith')} ${capitalize(caller)}`;
    } else {
      return t('literal:buyNow');
    }
  } else {
    return t('literal:joinNow');
  }
};

const Footer = ({itemPrice, object, disabled, caller, processingEupago, frequency, currency}) => {
  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    if (processingEupago) {
      setCountdown(5);

      const timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [processingEupago]);

  const { loading } = useError();

  const {user_language} = useSuspense(UserLanguageResource.detail(), {id: 1});
  const targetLanguage = getTargetLanguage(user_language);

  const {price} = object;

  const transformedPrice = itemPrice ? itemPrice : price;
  const transformedFrequency = frequency ? frequency : "lifetime";

  const {day, date} = getNextChargeDateAndDay(transformedFrequency, targetLanguage);
  const translatedFrequency = t(`profile:memberships:claimModal:renewalTimingsFrequency:${transformedFrequency}`);

  const currencySign = currencySymbol[currency];

  const itemPriceWithCurrency =  `${convertPriceFromCents(transformedPrice)} ${currencySign}`;

  return (
  <div className='footer'>

      {transformedFrequency !== "lifetime" &&
        <>
            <Label bold>{t('profile:memberships:claimModal:renewalTimings', {itemPriceWithCurrency, translatedFrequency, day, date})}</Label>
            <Label>{t('profile:memberships:claimModal:renewalTimingsSecond', {translatedFrequency})}</Label>
        </>}

      <div className='button-group'>
        <Button
          disabled={disabled || countdown > 0}
          loading={loading}
          highlighted
          fullwidth
        >
          <span>{getButtonLabel(transformedFrequency, caller, processingEupago)}</span>
          <span>{countdown ? `\u00A0(${countdown}s) ` : ''}</span>
        </Button>
      </div>
    </div>
  );
};

Footer.propTypes = {
  itemPrice: PropTypes.number,
  object: PropTypes.object,
  disabled: PropTypes.bool,
  onStepChange: PropTypes.func,
  caller: PropTypes.string,
  processingEupago: PropTypes.bool,
  frequency: PropTypes.string,
  currency: PropTypes.string
};

export default Footer;
