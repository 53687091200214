import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from "react-router-dom";
import { useSuspense } from '@rest-hooks/react';
import { useLogout } from "utils/useLogout";

import PageTop from 'components/PageTop';
import NavBar from 'components/navbar';

import OrganizationResource from 'resources/organization/OrganizationResource';

import Student from './Student';
import Teacher from './Teacher';
import Admin from './Admin';
import SwitcherMenu from './SwitcherMenu';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import isMobileDevice from 'utils/mobileAndTabletCheck';

const contextMenu = {
  'student': Student,
  'teacher': Teacher,
  'admin': Admin
};

const drawerWidth = 260;

const Layout = ({back=false, context="student", activeMenuItem=0, pageInfo, cta, children }) => {
  const history = useHistory();
  const params = useParams();
  const logout = useLogout();

  const organizationId = parseInt(params.organizationId);

  const Menu = contextMenu[context];

  const requestParams = {id: organizationId};
  if (!isNaN(organizationId)) requestParams.organization_id = organizationId;
  const organizationsList = useSuspense(OrganizationResource.list(), requestParams);

  const handleGoBack = () => {
    if (typeof back === "string") {
      history.push(back);
    } else if (back === true) {
      history.goBack();
    }
  };

  const showBackTextButton = back && isMobileDevice();

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        className='main-sidebar'
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="permanent"
          sx={{
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          <Menu organizationsList={ organizationsList } activeMenuItem={activeMenuItem} />

          <SwitcherMenu context={ context }
                        organizationsList={ organizationsList }
                        handleLogout={ logout }
          />

        </Drawer>
      </Box>

      <main>
        <div className="content">

          <NavBar 
            handleLogout={ logout } 
            activeMenuItem={activeMenuItem} 
            context={ context } 
            organizationsList={ organizationsList } >
          </NavBar>
          
          { back &&
            <div className="back-wrapper"> {/* desktop back button - hidden in mobile */}
              <IconButton aria-label="back" size="large"
                          onClick={ handleGoBack } >
                <ArrowBackIcon fontSize="inherit" />
              </IconButton>
            </div>
          }

          { pageInfo &&
            <PageTop pageInfo={ pageInfo } cta={ cta } onGoBack={ showBackTextButton ? handleGoBack : null }/>
          }

          { children }
        </div>
      </main>
    </Box>
)};

Layout.propTypes = {
    context: PropTypes.string,
    back: PropTypes.bool,
    activeMenuItem: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    pageInfo: PropTypes.object,
    cta: PropTypes.node,
    children: PropTypes.node
};

export default Layout;
