import React from 'react';
import PropTypes from 'prop-types';

import Filter from 'ui/Filter';
import DateRangeFilter from 'ui/DateRangeFilter';

const OrderFilter = ({ onChange }) => {

  const statusFilter = <Filter onChange={onChange} label="status" table={'orders'} />

  const providerFilter = <Filter onChange={onChange} label="method" table={'orders'} />

  const invoiceFilter = <Filter onChange={onChange} label="invoice" />

  const dateRangeFilter = <DateRangeFilter onChange={onChange} />

  return (
    [statusFilter, providerFilter, invoiceFilter, dateRangeFilter]
  );
};

OrderFilter.propTypes = {
  onChange: PropTypes.func,
};

export default OrderFilter;
