import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Switch } from '@mui/material';
import { detectEmbedType } from 'utils/pageElementsHelper';
import { styled } from '@mui/material/styles';

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  cursor: 'default',
  '& .MuiSwitch-root': {
    cursor: 'pointer',
  },
}));

const EmbedSwitch = ({ object, onChange }) => {
  const isEmbed = object.element_type?.includes('_embed');

  const handleSwitchChange = (event) => {
    const checked = event.target.checked;
    const embedType = detectEmbedType(object.content);
    const newElementType = checked ? `${embedType}_embed` : 'link';
    onChange({
      target: {
        name: 'element_type',
        value: newElementType
      }
    });
  };

  return (
    <StyledFormControlLabel
      control={
        <Switch
          checked={isEmbed}
          onChange={handleSwitchChange}
          color="warning"
        />
      }
      label="Show as embed"
      onClick={(e) => {
        // Only allow clicks on the switch itself
        if (!e.target.closest('.MuiSwitch-root')) {
          e.preventDefault();
        }
      }}
    />
  );
};

EmbedSwitch.propTypes = {
  object: PropTypes.object,
  onChange: PropTypes.func,
};

export default EmbedSwitch;