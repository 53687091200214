import React from 'react';
import PropTypes from 'prop-types';

import NumberOfEventsSelector from './NumberOfEventsSelector';
import ViewSelector from './ViewSelector';
import SettingSwitcher from './SettingSwitcher';

const CalendarComposer = ({settings, onChange}) => {
  return (
    <>
      <NumberOfEventsSelector
        numberOfEvents={settings?.number_of_events}
        onChange={onChange}
      />
      <ViewSelector
        view={settings?.view}
        onChange={onChange}
      />
      <SettingSwitcher
        value={settings?.hide_price}
        name="hide_price"
        label="Show Ticket Price"
        onChange={onChange}
      />
      <SettingSwitcher
        value={settings?.hide_location}
        name="hide_location"
        label="Show Location"
        onChange={onChange}
      />
    </>
  );
};

CalendarComposer.propTypes = {
  settings: PropTypes.object,
  onChange: PropTypes.func,
};

export default CalendarComposer;
