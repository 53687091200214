import React from 'react';
import PropTypes from 'prop-types';

import DateInputField from 'components/DateInputField';

const dateFields = [
    { attribute: 'start_date', label: 'First Date *', dateOnly: true, disablePast: true },
    { attribute: 'end_date', label: 'Last Date *', dateOnly: true, disablePast: true },
    { attribute: 'start_time', label: 'Start Time *', timeOnly: true },
    // { attribute: 'end_time', label: 'End Time', timeOnly: true },
];

const DateTimeFields = ({event, onEventChange, setUnsavedChanges}) => {

    const {id, universal_time = true, repeat_days} = event;

    const handleEventStartTimeChange = (value) => {
             
        const newRepeatDays = [...repeat_days];
        newRepeatDays.forEach(day => {
            const dayKey = Object.keys(day)[0];
            day[dayKey] = value.start_time;
        });

        const newEvent = { ...value, repeat_days: newRepeatDays };
        onEventChange(newEvent);
    };

  return (
    <>
        {dateFields.map((field, index) => (
            <DateInputField
                key={index}
                attribute={field.attribute}
                label={field.label}
                object={event}
                onChange={index === 2 ? handleEventStartTimeChange : onEventChange}
                setUnsavedChanges={setUnsavedChanges}
                disablePast={field.disablePast}
                dateOnly={field.dateOnly}
                timeOnly={field.timeOnly}
                disabled={!!id || (index === 2 && !universal_time)}
            />))}
    </>
  );
};

DateTimeFields.propTypes = {
    event: PropTypes.object,
    onEventChange: PropTypes.func,
    setUnsavedChanges: PropTypes.func,
};

export default DateTimeFields;