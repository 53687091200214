import React, { useMemo, useState } from 'react';
import { useController } from "@rest-hooks/react";
import PropTypes from 'prop-types';

import TicketResource from "resources/organization/TicketResource";
import EventResource from "resources/organization/EventResource";

import Title from './Title';
import Content from './Content';

import { useError } from "utils/useErrorController";
import { useToast } from "utils/context/ToastContext";


import { handleValidation } from "./handleValidation";


import Dialog from "@mui/material/Dialog";
import Actions from './Actions';

const NewTicketModal = ({open, onClose, event}) => {

  const { organization_id, single } = event;

  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const [recipients, setRecipients] = useState([{ email: "", amount: 1 }]);
  const [ticket, setTicket] = useState(null);

  const { handleError, setLoading, loading } = useError();
  const { showToast } = useToast();
  const { fetch } = useController();

  const requestParams = useMemo(() => {
    const params = { organization_id: organization_id, event_id: event.id };
    if (selectedSessionId) {
      params.session_id = selectedSessionId;
    }
    return params;
  }, [organization_id, event.id, selectedSessionId]);
  
  const handleClose = () => {
    setRecipients([{ email: "", amount: 1 }]);
    setTicket(null);
    setSelectedSessionId(null);
    onClose();
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const filteredRecipients = recipients.filter(recipient => recipient.email !== "");
      handleValidation(filteredRecipients, ticket.id);
      
      await fetch(
        TicketResource.create(),
        requestParams,
        {
          recipients: filteredRecipients,
          ticket_id: ticket.id,
        }
      );  

      // fetch event list to refresh tickets lists and available amounts
      await fetch(EventResource.list(), { organization_id: organization_id });

      showToast("success", "Tickets sent successfully");
      handleClose();
      setLoading(false);
    } catch (error) {      
      setLoading(false);
      handleError(error);
    }
  };

  const handleSessionChange = ({target: {value}}) => {
    setSelectedSessionId(value);
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="sm" className="new-ticket-modal">
      <Title single={single} selectedSessionId={selectedSessionId} onClose={handleClose} />
      <Content 
        event={event} 
        recipients={recipients}
        ticket={ticket}
        selectedSessionId={selectedSessionId} 
        onRecipientsChange={setRecipients}
        onTicketChange={setTicket}
        onSessionChange={handleSessionChange} 
      />
      <Actions ticket={ticket} onSubmit={handleSubmit} loading={loading}/>
    </Dialog>
  );
};

NewTicketModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  event: PropTypes.object,
};

export default NewTicketModal;