import React from 'react';
import PropTypes from 'prop-types';

import StatCard from 'ui/StatCard/StatCard'
import { formatPrice } from 'utils/rewards';

const OrderStats = ({ orders }) => {

  const order = orders?.[0];

  const stat1 = order ? formatPrice(order.net_value_sum, order.currency, true) : 0
  const stat2 = order ? formatPrice(order.application_fee_sum, order.currency, true) : 0
  const stat3 = order ? formatPrice(order.total_value_sum, order.currency, true) : 0

  return (
    <div className="ui-stats-grid">
      
      <StatCard 
        type='number'
        highlight={stat1}
        title='Net Value' />

      <StatCard 
        type='number'
        highlight={stat2}
        title='Service Fees' />

      <StatCard 
        type='number'
        highlight={stat3}
        title='Total Value' />

    </div>
  )
};

OrderStats.propTypes = {
  orders: PropTypes.array
};

export default OrderStats;