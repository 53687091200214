import React from "react";

import StripeSetup from "./StripeSetup";
import Invoicing from "./Invoicing";

import useOrganizationAdmin from "utils/useOrganizationAdmin";

const Stripe = () => {

  const organization = useOrganizationAdmin();

  return (
    <div>
      <StripeSetup 
        organizationId={organization.id} 
        organizationCountry={organization.country} />
      
      <Invoicing
        organization={organization} />
    </div>
  );
};
export default Stripe;
