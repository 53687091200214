import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';
import Label from 'ui/text/Label';

import UploadFileIcon from "@mui/icons-material/UploadFile";

const Images = ({images, organization, hiddenFileInputs, handleFileUpload}) => {
  return (
    <>
      {images.map ((image, index) => (
        <div key={index} >
          <Button
            fullwidth
            secondary
            icon={UploadFileIcon}
            onClick={(event) => {
              event.preventDefault()
              hiddenFileInputs[index].current.click();
            }}
            >
            {organization[`${image.type}_filename`] ? `Uploaded ${organization[`${image.type}_filename`]}` : `Upload ${image.label}`}
          </Button>
          <input ref={hiddenFileInputs[index]} type="file" accept="image/*" name={image.type} hidden onChange={handleFileUpload} style={{display:'none'}}/>
          {/* <Label secondary >{image.recommendation}</Label> */}
        </div>
      ))}
      <Label secondary >
        Recommended sizes: Profile 400x400px, Cover 1200x600px
      </Label>
    </>
  );
};

Images.propTypes = {
  images: PropTypes.array,
  organization: PropTypes.object,
  hiddenFileInputs: PropTypes.array,
  handleFileUpload: PropTypes.func,
};

export default Images;