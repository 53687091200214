import React from 'react';
import PropTypes from 'prop-types';

import OrganizationItem from './OrganizationItem';

import SearchableFilter from 'ui/SearchableFilter';

import { Box } from '@mui/material';

const OrganizationSelector = ({ organizationsList, organizationId, onChange }) => {

  if (organizationsList.length === 1) return null;
  if (organizationsList.length <= 7) return (
    <>
      {organizationsList.map((item, key) =>
        <OrganizationItem
          key={key}
          title={item.title}
          logo_url={item.logo_url}
          selected={item.id === organizationId}
          onChange={() => onChange(item.id)}
        />  
      )}
    </>
  );

  return (
    <>
      {organizationsList.map((item, key) =>
        <OrganizationItem
          key={key}
          title={item.title}
          logo_url={item.logo_url}
          selected={item.id === organizationId}
          onChange={() => onChange(item.id)}
        />    
      )}
      <Box 
        onClick={(e) => e.stopPropagation()} 
        margin={1}>
        <SearchableFilter
            fullwidth
            label={"organization"}
            onChange={(attr, id) => onChange(id)}
            customLabel="Select Organization"
          />
      </Box>
    </>
  );
};

OrganizationSelector.propTypes = {
  organizationsList: PropTypes.array,
  organizationId: PropTypes.number,
  onChange: PropTypes.func
};

export default OrganizationSelector;