import React, { Suspense, useEffect, useState} from 'react';
import { NetworkErrorBoundary } from '@rest-hooks/react';

import Routes from 'routes';

import CrowdclassFallback from 'components/CrowdclassFallback';
import NetworkError from 'components/NetworkError';

import Plausible from 'plausible-tracker'
import { wagmiChains, wagmiClient } from 'utils/wagmiClient';
import { ToastProvider } from 'utils/context/ToastContext';
import { PermissionsContext } from 'utils/context/PermissionsContext';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { WagmiConfig } from 'wagmi';
import { PreferencesContext } from 'utils/context/PreferencesContext';
import { ErrorProvider } from 'utils/useErrorController';

const theme = createTheme({
  typography: {
    fontFamily: 'var(--fontFamily)',
  },
});

const { enableAutoPageviews } = Plausible({
    domain: 'membrz.club',
    // trackLocalhost: true,
  });

const defaultPermissions = {
    "hasMinting": true,
    "hasSales": true,
    "hasProducts": true,
    "hasWhitelabel": true,
    "hasMysterybox": false
  };


function App() {
  const [preferences, setPreferences] = useState([]);

  useEffect(() => {
    const cleanup = enableAutoPageviews();
    return () => cleanup();
  }, []);

  return (
    <Suspense fallback={ <CrowdclassFallback /> }>
      <NetworkErrorBoundary fallbackComponent={NetworkError}>
          <ThemeProvider theme={theme}>
            <WagmiConfig client={wagmiClient}>
            <RainbowKitProvider chains={wagmiChains}>

              <ToastProvider>
                <PermissionsContext.Provider value={{ permissions: defaultPermissions }}>
                  <PreferencesContext.Provider value={{ preferences: preferences, setPreferences: setPreferences }}>
                    <ErrorProvider>
                      <Routes />
                    </ErrorProvider>
                  </PreferencesContext.Provider>
                </PermissionsContext.Provider>
              </ToastProvider>

            </RainbowKitProvider>
            </WagmiConfig>
          </ThemeProvider>
      </NetworkErrorBoundary>
    </Suspense>
  );
}

export default App;
