import { useState } from 'react';
import { useController } from '@rest-hooks/react';
import { useParams } from 'react-router-dom';

import MetadataScrapperResource from 'resources/organization/MetadataScrapperResource';

import { useError } from 'utils/useErrorController';

export const useMetadataFetcher = () => {
  const { fetch } = useController();
  const { setLoading, handleError } = useError();
  const { organization_id } = useParams();
  const [fetchedMetadata, setFetchedMetadata] = useState(null);

  const fetchMetadata = async (url) => {
    try {
      setLoading(true);
      const { success, metadata } = await fetch(MetadataScrapperResource.create(), { url, organization_id });
      setLoading(false);
      if (success) return metadata;
      return null;
    } catch (error) {
      handleError(error);
      return null;
    }
  };

  const updateElementWithMetadata = (element, metadata) => {
    const updatedElement = { ...element };
    if (metadata?.title) updatedElement.link_title = metadata.title;
    if (metadata?.image_url) {
      updatedElement.image_url = metadata.image_url;
      updatedElement.file = null;
      updatedElement.filename = "";
    }
    return updatedElement;
  };

  const validForMetadataFetch = (url, element_type, id) => {
    if (!url) return false;
    if (element_type !== "link") return false;
    if (id) return false;
    return true;
  };

  return {
    fetchedMetadata,
    setFetchedMetadata,
    fetchMetadata,
    updateElementWithMetadata,
    validForMetadataFetch,
  };
};