import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useController } from '@rest-hooks/react';

import EventResource from 'resources/organization/EventResource';

import { useError } from 'utils/useErrorController';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import { useParams } from 'react-router-dom';

const EventUrlSelector = ({url, onUrlChange}) => {

  const [events, setEvents] = useState([]);

  const { fetch } = useController();
  const { handleError } = useError();
  const { organizationId } = useParams();

  useEffect(() => {
const fetchEvents = async () => {
      try {
        const eventsData = await fetch(EventResource.list(), { organization_id: organizationId, upcoming: true });
        setEvents(eventsData);
      } catch (error) {
        handleError(error);
      }
    };

    fetchEvents();
  }, [organizationId, handleError]);

  const handleEventSelection = (event, newValue, reason, details) => {
      if (details?.option?.create && reason !== 'removeOption') {
      onUrlChange(details.option.name);
    } else if (reason === 'removeOption' || reason === 'clear') {
      onUrlChange("");
    } else if (newValue && !newValue.create) {
      onUrlChange(newValue.external_url || "");
    }
  };

  const filter = createFilterOptions();

  const filterOptions = (options, params) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    const isExisting = options.some((option) => inputValue === option.name);

    if (inputValue !== '' && !isExisting) {
      filtered.push({
        name: inputValue,
        label: `Use Link: "${inputValue}"`,
        create: true,
      });
    }

    return filtered;
  };

  const getOptionLabel = (option) => {
    if (typeof option === 'string') return option;
    if (option.create) return option.inputValue;
    return option.title;
  };

  const renderOption = (props, option) => <li {...props}>{option.create ? option.label : "Link to: " + option.title}</li>;
  
  const renderInput = (params) => <TextField {...params} color='warning' label="Link" />;

  return (
    <Autocomplete
      size='small'
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      freeSolo
      fullWidth
      value={url}
      options={events}
      onChange={handleEventSelection}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      renderInput={renderInput}
    />
  )
}

EventUrlSelector.propTypes = {
  url: PropTypes.object,
  onUrlChange: PropTypes.func,
  organizationId: PropTypes.number
};

export default EventUrlSelector;