import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import FormSection from 'ui/FormSection';
import MediaUploader from 'components/MediaUploader';

const Banner = ({ object, onObjectChange, loading, onError }) => {

  const hiddenFileInputs = {
    image: useRef(null),
  };

  const label = [
    "Existing, this image will be displayed at the top of the post and used as the newsletter banner.",
    <br key="br1" />,
    "If no image is uploaded here, the newsletter banner will default to your Club's cover image.",
    <br key="br2" />,
    "For best results, use 1100px x 220px PNG."
  ];
  
  return (
    <FormSection title="Banner Image">
      <MediaUploader
        hiddenFileInputs={hiddenFileInputs}
        objectType={"product"}
        object={object}
        onObjectChange={onObjectChange}
        loading={loading}
        label={label}
        onError={onError}
      />
    </FormSection>
  );
};

Banner.propTypes = {
  object: PropTypes.object,
  onObjectChange: PropTypes.func,
  loading: PropTypes.bool,
  onError: PropTypes.func,
};

export default Banner;
