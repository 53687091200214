import React, { useCallback, useState } from 'react';
import { useController, useSuspense  } from '@rest-hooks/react';
import { useHistory, useParams } from "react-router-dom";

import OrganizationResource from 'resources/organization/OrganizationResource';
import CourseResource from 'resources/organization/CourseResource';

import Layout from 'components/layouts/index';
import StatusModal from 'components/StatusModal';
import Form from './form';

import handleRoyalties from 'utils/handleRoyalties';
import { useToast } from 'utils/context/ToastContext';
import findOrganizationName from 'utils/findOrganizationName';
import CoursesList from 'utils/localStorage/CoursesList';

const NewCourse = () => {
  const { fetch } = useController();
  const history = useHistory();
  const params = useParams();
  
  const { showToast } = useToast();
  const [error, setError] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  
  const organizationId = parseInt(params.organizationId);
  const courses = CoursesList.read();
  const organizationName = findOrganizationName(organizationId, courses);

  const { permissions } = useSuspense(OrganizationResource.detail(), {id: organizationId});

  const pageInfo = {
    name: `Add new collection to ${organizationName}`
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const handleValidation = (course) => {
    const { title, category } = course;
    validateCourseTitle(title);
    validateCourseCategory(category);
  };

  const validateCourseTitle = (title) => {
    if (title === "") {
        setError("Please provide a course title.");
        handleOpenErrorModal();
        throw '';
    }
  };

  const validateCourseCategory = (category) => {
    if (category === "") {
        setError("Please select a colletion type.");
        handleOpenErrorModal();
        throw '';
    }
  };

  const createFormData = (course) => {
    const formData = new FormData();
    const fields = [
      {key: "organization_id", value: organizationId},
      {key: "title", value: course.title},
      {key: "description", value: course.description},
      {key: "category", value: course.category},
      {key: "external_link", value: course.external_link},
    ]
    if (course.category === "mysteryBox") {
      fields.push({ key: 'published', value: false });
    }
    const royalties = handleRoyalties(course.royalties);
    if (royalties){
      fields.push({key: "royalties", value: royalties})
    }
    
    fields.forEach(field => {
      formData.append(field.key, field.value);
    });
    return formData;
  };

  const handleOnSubmit = useCallback(
    async (e, course) => {
      e.preventDefault();
      handleValidation(course);
      try {
        const formData = createFormData(course);
        const { id, title, category, organization_id, organization } = await fetch(CourseResource.create(), {organization_id: organizationId}, formData);

        //success!
        if (id) {
          const coursesList = CoursesList.read();
          const newCourse = {id, title, category, organization_id, organization,};
          coursesList.push(newCourse);
          CoursesList.write(coursesList);

          if (category === "mysteryBox") {
            history.push(`/organizations/${organizationId}/collections/${id}/collectibles/new?mysteryBox=true`);
          } else {
            history.push(`/organizations/${organizationId}/collections/${id}`);
          }
          showToast('success', `New ${course.category} created`);
        }
      } catch (error) {
        handleError(error);
      }
    },
    [fetch, organizationId],
  );

  const handleError = async (error) => {
    const { errors } = await error.response.json();
    const errorMessage = errors ? errors.base[0] : error.message;
    setError(errorMessage);
    handleOpenErrorModal();
  };
  
  return (
    <Layout context='teacher'
            back={ true }
            pageInfo={ pageInfo } >

      <Form organizationId={ organizationId } onSubmit= { handleOnSubmit } permissions= { permissions } />
      { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}

    </Layout>
  )
};

export default NewCourse;