import React from 'react';
import PropTypes from 'prop-types';

import SearchableFilter from 'ui/SearchableFilter';
import DateRangeFilter from 'ui/DateRangeFilter';
import Filter from 'ui/Filter';

const EventsFilter = ({onChange, defaultStartDate, defaultEndDate}) => {
  return (
    <>
      <SearchableFilter
        label={"organization"}
        onChange={onChange}
      />
      <DateRangeFilter 
        onChange={onChange}
        defaultStartDate={defaultStartDate}
        defaultEndDate={defaultEndDate}
      />
      <Filter
        label={"ticketing"}
        onChange={onChange}
      />
    </>
  );
};

EventsFilter.propTypes = {
  onChange: PropTypes.func,
  defaultStartDate: PropTypes.string,
  defaultEndDate: PropTypes.string
};

export default EventsFilter;