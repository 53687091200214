
import React, { createElement } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import EmbeddedSpotify from 'components/MarkdownEmbeds/EmbeddedSpotify';
import EmbeddedVideo from 'components/MarkdownEmbeds/EmbeddedVideo';
import EmbeddedSoundcloud from 'components/MarkdownEmbeds/EmbeddedSoundcloud';
import CustomButton from 'components/MarkdownEmbeds/CustomButton';

const EmbeddedComponents = {
  youtube: EmbeddedVideo,
  vimeo: EmbeddedVideo,
  spotify: EmbeddedSpotify,
  soundcloud: EmbeddedSoundcloud
};

const createMarkdownEmbed = (type, id) => {
  const Component = createElement(EmbeddedComponents[type], { id, type });
  return buildComponent(Component);
};

const createCustomButton = (node) => {
  const match = node.literal.match(/label=(.*?)\s+url=(.*)/s);
  const [, label, url] = match;

  const Component = <CustomButton label={label} url={url} />;
  return buildComponent(Component);
};

const buildComponent = (Component) => {
  return [
    { type: 'openTag', tagName: 'div', outerNewLine: true },
    { type: 'html', content: renderToStaticMarkup(Component) },
    { type: 'closeTag', tagName: 'div', outerNewLine: true }
  ];
};

const nodeHandler = {
  youtube: (node) => createMarkdownEmbed('youtube', node.literal),
  vimeo: (node) => createMarkdownEmbed('vimeo', node.literal),
  spotify: (node) => createMarkdownEmbed('spotify', node.literal),
  soundcloud: (node) => createMarkdownEmbed('soundcloud', node.literal),
  button: (node) => createCustomButton(node),
};

export const markdownCustomHTML = {
  htmlBlock: {
      iframe(node) {
      return [
          { type: 'openTag', tagName: 'iframe', outerNewLine: true, attributes: node.attrs },
          { type: 'html', content: node.childrenHTML },
          { type: 'closeTag', tagName: 'iframe', outerNewLine: true },
      ];
    }}, ...nodeHandler
  }