const labels = {
  pt:{
    testUnusedKey: "Please do not translate me. I am just a test key",
    testPtOnlyKey: "Please do not translate me. I am just a test key",
    literal:{
      invalidPromoCode: "Código inválido",
      promoCodeApplied: "Código aplicado com sucesso",
      availableIn: "Disponível {{timeToStart}}",
      messageFrom: "Messagem de {{organizationTitle}}",
      becomeAMember: "Torne-se um membro",
      sending: "A enviar...",
      signUp: "Registar-se",
      and_sc: "e",
      reset: "Repor",
      signIn: "Iniciar sessão",
      name: "Nome",
      email: "Email",
      password: "Palavra-passe",
      newPassword: "Nova palavra-passe",
      confirmPassword: "Confirmar palavra-passe",
      redeem: "Resgatar",
      reveal: "Revelar",
      redeemed: "Resgatado",
      claim: "Obter",
      available: "Disponível",
      contract: "Contrato",
      seeDescription: "Ver descrição",
      redeemBy: "Resgatar até",
      continue: "Continuar",
      submitChanges: "Pedir alterações",
      requestInvoice: "Pedir Fatura",
      requestInvoiceChanges: "Pedir Nova Fatura",
      invoiceSent: "Recibo enviada",
      completed: "Concluído",
      cancelled: "Cancelado",
      createdAt: "Criado em",
      quantity: "Quantidade",
      paid: "Pago",
      seeDetails: "Ver detalhes",
      address: "Morada",
      vat: "NIF",
      seeMembershipTiers: "Ver opções de assinatura",
      privacyPolicy: "Política de privacidade",
      termsAndConditions: "Termos e condições",
      connect: "conectar",
      seeLess: "Ver menos",
      seeMore: "Ver mais",
      free: "Gratuito",
      join: "Participar",
      subscribe: "Subscrever",
      buy: "Comprar",
      price: "Preço",
      upgradingSubscription: "A atualizar a assinatura",
      profile: "Perfil",
      editProfile: "Editar perfil",
      logout: "Sair",
      limitedSupply: "Fornecimento limitado",
      expirationDate: "Data de expiração",
      description: "Descrição",
      claimYour: "Reivindique o seu",
      pricing: "Preços",
      no: "Não",
      yes: "Sim",
      showOnChain: "Mostrar na cadeia",
      claimOnChain: "Reivindicar na cadeia",
      share: "Partilhar",
      downloadImage: "Descarregar imagem",
      downloadPDF: "Descarregar PDF",
      manageSubscription: "Gerir assinatura",
      error: "Erro",
      seeCollection: "Ver coleção",
      buyNow: "Comprar agora",
      billingDetails: "Detalhes de faturação",
      recipientDetails: "Detalhes do destinatário",
      completePayment: "Concluir pagamento",
      paymentConfirmation: "Confirmação de pagamento",
      giftSent: "Presente enviado",
      next: "Seguinte", // context checkout stepper
      of_sc: "de", // context checkout stepper
      paymentMethods: "Métodos de pagamento",
      phoneNumber: "Número de telefone",
      proceed: "Prosseguir",
      city: "Cidade",
      postCode: "Código postal",
      billingAddress: "Morada de faturação",
      contactDetails: "Detalhes de contacto",
      personalDetails: "Detalhes pessoais",
      personalOrCompanyDetails: "Detalhes pessoais/empresa",
      newsletterPreferences: "Preferências de email",
      connectedAccounts: "Contas conectadas",
      clickHere: "Clique aqui",
      from: "De",
      to: "Para",
      upgradeMembership: "Atualizar assinatura",
      members_sc: "membros",
      none_sc: "nenhum",
      seeMemberships: "Ver assinaturas",
      once_sc: "uma vez",
      times_sc: "vezes",
      store: "Loja",
      after: "após",
      renewsOn: "Renova em",
      expiresOn: "Expira em",
      downgrading_sc: "downgrade",
      memberships: "Assinaturas",
      monthly: "Mensal",
      quarterly: "Trimestral",
      yearly: "Anual",
      orderSummary: "Resumo da encomenda",
      payment_sc: "pagamento",
      joinNow: "Junta-te agora",
      submit: "Enviar",
      view: "Ver",
      revealSoon: "Revelar em breve",
      claiming: "A reivindicar",
      settings: "Definições",
      firstName: "Nome",
      lastName: "Apelido",
      portuguese: "Português",
      english: "Inglês",
      update: "Atualizar",
      walletAddress: "Morada da carteira",
      deliveryDetails: "Detalhes de entrega",
      preferences: "Preferências",
      membershipPasses: "Passe de adesão",
      digitalCollectibles: "Itens digitais colecionáveis",
      congratulations: "Parabéns",
      disconnectWallet: "Desconectar carteira",
      showAll: "Mostrar todos",
      filterBy: "Filtrar por",
      organization: "Organização",
      you: "Tu",
      other_sc: "outro",
      like: "Gosto",
      comment: "Comentário",
      liked: "Gostado",
      billingHistory: "Histórico de compras",
      help: "Ajuda",
      reactivate: "Reativar",
      downgrading: "A fazer downgrade",
      toFree: "Para gratuito",
      unclaimed: "Não reclamado",
      viewNFT: "Ver NFT",
      about: "Sobre",
      links: "Links",
      newsletter: "Newsletter",
      more: "Mais",
      privateLink: "Link Privado",
      receive: "Recebe",
      points_sc: "pontos",
      country: "País",
      seeLatest: "Ver mais recentes",
      loadMore: "Mostrar mais",
      soldOut: "Esgotado",
      viewMore: "Ver Mais",
      viewAll: "Ver Tudo",
      claimWithNFT: "Subscrever com NFT",
      discount: "Desconto",
      cancel: "Cancelar",
      goTo: "Ver Clube",
      welcome: "Bem-vindo",
      buyTickets: "Comprar Bilhetes",
      save: "Guardar",
      unsubscribe: "Cancelar subscrição",
      events: "Events",
      tickets: "Bilhetes",
      upcoming: "Próximos",
      past: "Passados",
      here: "aqui",
      freeEntrance: "Entrada Livre",
      ticketDetails: "Detalhes dos bilhetes",
      location: "Localização",
      reason: "Razão",
      close: "Fechar",
      applicationFee: "Custos de operação",
      confirmTransaction: "Confirmar Pagamento",
      now: "Agora",
      confirm: "Confirmar",
      card: "Cartão",
      filter: "Filtrar",
      addPromoCode: "Adicionar Promo Code",
      tickets_sc: "bilhetes",
      products_sc: "produtos",
      ticket_sc: "bilhete",
      product_sc: "produto",
      manageClub: "Gerir Clube",
		},
    errors:{
      invalidPhoneNumber: "Por favor verifique se o número de telefone está correto e tente novamente",
			failedToFindOrderResult: "Algo correu mal, por favor entre em contacto com o administrador",
			invalidTicketEmail: "Por favor, forneça um endereço de email válido para o bilhete",
			nameMissing: "Por favor, indique o seu nome",
			emailMissing: "Por favor, forneça um endereço de email válido",
			passwordMissing: "Por favor, forneça uma senha válida",
			passwordsDontMatch: "As senhas não coincidem",
			dataAgreementMissing: "Por favor, leia e concorde com os nossos termos e condições",
			countryMissing: "Por favor, indique o seu país",
			postalCodeMissing: "Por favor, indique o seu código postal",
			notEligiblePerk: "Neste momento cumpre os critérios para resgatar esta oferta. Se acredita que isso seja um erro, entre em contato com a organização que a criou",
			default: "Algo correu mal, por favor, tente novamente. Se o problema persistir, entre em contato com o administrador",
			userExists: "Já existe uma conta com o email fornecido. Por favor, utilize um email diferente ou faça login com a conta existente.",
			emailValidationFailed: "Houve um erro na validação do seu endereço de email. Por favor, tente novamente.",
			giftEmailMissing: "Por favor, indique o endereço de email da pessoa para quem gostaria de enviar este {{rewardCategoryLabel}}.",
			giftNameMissing: "Por favor, indique o nome da pessoa para quem gostaria de enviar este {{rewardCategoryLabel}}.",
			giftSenderEmailMissing: "Por favor, indique o seu endereço de email.",
			redeemedMax: "Já resgatou esta oferta o número máximo de vezes.",
			manualSubscription: "A sua assinatura ativa só pode ser alterada manualmente pelo administrador desta conta. Entre em contato caso precise de ajuda.",
			nftSubscription: "A sua assinatura ativa só pode ser alterada manualmente pelo administrador desta conta. Entre em contato caso precise de ajuda.",
			passAlreadyIssued: "Já possui este passe.",
			unableToValidateOnchain: "O item colecionável digital associado a esta oferta foi reivindicado na cadeia como um NFT. Infelizmente, a verificação de NFT na cadeia ainda não está disponível, mas nossa equipe está trabalhando para lançá-la em breve. Entraremos em contato assim que estiver pronto, para que possa resgatar a sua oferta.",
			commentEmpty: "Os comentários não podem estar vazios",
			alreadyIssuedMax: "Já recebeu este item colecionável digital o número máximo de vezes.",
			notEligible: "A sua conta não está apta a reivindicar este item colecionável digital. Entre em contato com a organização se acreditar que isso não está correto.",
			privateClaim: "*O seu email ou conta no Membrz Club deve ser elegível para reivindicar este item colecionável digital. Entre em contato com a organização se não conseguir reivindicá-lo.",
			unavailable: "Este item colecionável digital já não está disponível.",
			notFound: "Não encontrado",
			unableToValidateCredentials: "Não foi possível validar as credenciais",
			unableToValidateSignature: "Unable to validate signature",
			noPaymentProviders: "Pagamento indisponível. Por favor, tente novamente mais tarde.",
			stripeNotConnected: "Stripe indisponível. Por favor, tente novamente mais tarde.",
			pleaseRefresh: "Por favor, atualize a página e tente novamente.",
			fieldMissing: "Campo em falta: {{field}}",
			reachedMaxAmount: "Quantidade atualizada para {{maxAmount}} por ter sido atingido o máximo permitido.",
			entitledToCoupon: "Tem direito a um desconto de membro, que foi aplicado.",
		},
		tcLabel:{
			agreement: "Concordo com os termos e condições do Membrz Club",
		},
		//containers
		account:{
			forgotPassword:{
				errorEmailMissing: "Por favor, forneça um endereço de email válido",
				requestInstructions: "Enviar instruções de redefinição",
				resetInstructions: "Foi enviado um email com instruções para o endereço de email que forneceu. Por favor, verifique o seu email.",
			},
			invite:{
				header: "{{organizationName}} convidou-o para",
				content: "Registe-se para continuar e ter acesso ao espaço de trabalho",
			},
			login:{
				pageTitle: "Faça login para entrar no seu Clube de Membros!",
				button: "Login",
			},
			resetPassword:{
				newUserPageTitle: "Redefina a sua senha",
				newUserPageDescription: "Por favor indique a sua nova senha.",
			},
			common:{
				forgotPasswordAndResetPasswordPageTitle: "Esqueceu a sua Senha?",
				forgotPasswordAndResetPasswordPageDescription: "Acontece aos melhores de nós. Vamos ajudá-lo a redefini-la e voltar ao normal.",
			}
		},
        // public: {
			buyActions:{
				frequencySwitcher:{
					monthly: "mensalmente",
					yearly: "anualmente",
					quarterly: "trimestralmente",
					lifetime: "pagamento único",
				},
				giftCheckboxlabel: "Isto constitui um presente.",
			},
			//not checkout modal being used through profile
			checkoutModal:{
				utrustAwaitingConfirmationParagraphFirst: "Aguardamos a confirmação do pagamento pelo xMoney.",
				utrustAwaitingConfirmationParagraphSecond: "Será redirecionado para o seu perfil em breve após a conclusão.",
				utrustAwaitingConfirmationParagraphThird: "Também pode fechar esta janela e retornar mais tarde para reivindicar o NFT após a confirmação do pagamento.",
				mbwayAwaitingConfirmationParagraphFirst: "Aguardamos a confirmação de que o pagamento foi bem-sucedido.",
				mbwayAwaitingConfirmationParagraphSecond: "Por favor, abra o aplicativo MB WAY e confirme o pagamento lá.",
				lookingForRewardInstance: "Vamos redireccionlá-lo para o seu perfil em breve.",
				processingOrder: "Estamos processando o pedido.",
				thankYouForPayment: "Agradecemos pelo pagamento",
				rewardInstanceNotFound: "Algo deu errado. Clique AQUI para ser redirecionado para o seu perfil e entre em contato com o administrador da coleção.",
				finalAmountHigherThanZero: "Um único usuário só pode ter {{claimLimit}} instâncias deste NFT. Portanto, o valor do pedido foi reduzido de {{amount}} para {{finalAmount}}.",
				priceUpdated: "O preço do pedido também foi atualizado para {{finalPrice}} {{currencySymbol}}.",
				orderStarted: "O pedido foi iniciado.",
				utrustIntro: "Será ou foi redirecionado para o widget de pagamento xMoney, compatível com qualquer carteira de criptomoedas importante. Siga as instruções e confirme sua compra quando todas as etapas forem concluídas.",
				utrustInstructions: "Caso não tenha sido redirecionado para o widget de pagamento xMoney, verifique se o seu navegador não está bloqueando pop-ups do Membrz Club ou ",
				changedMyMind: "Mudei de Ideias",
				paymentComplete: "Concluir Pagamento",
				proceedToUtrust: "Leve-me para o xMoney",
				pay: "Efetue o pagamento de {{finalPrice}} {{currencySymbol}}",
				paymentProviderSelectorDescription: "Escolha a sua opção de pagamento preferida:",
				googlePayFailed: "O Google Pay não está disponível. Por favor, escolha um método de pagamento diferente.",
				giftBeingSent: "Em breve enviaremos um email com os detalhes do seu pedido e a confirmação de que o presente foi enviado.",
				inStock: "Em estoque. Suprimento máximo: ",
				invoiceForm:{
					description: "Adicione os seus detalhes abaixo.",
				},
				giftModal:{
					title: "Ofereça este {{rewardCategoryLabel}} a alguém.",
					description: "Adicione uma mensagem e detalhes abaixo de quem deseja dar este {{rewardCategoryLabel}}. O {{rewardCategoryLabel}} será enviado por email.",
				},
				giftForm:{
					message: "Inclua uma mensagem especial (máximo de 400 caracteres)",
					senderName: "Seu nome",
					senderEmail: "Seu email",
					name: "Nome do destinatário",
					email: "Email do destinatário",
				},
			},
			profile:{
                sessionsCalendarCta: "Veja todas as datas",
				eventCardSessions: "{{sessionsCount}} sessões disponíveis",
                eventCardSingleSession: "{{sessionsCount}} sessão disponível",
				unsubscribe: "Caro {{user_email}}, por favor, confirme que deseja parar de receber novidades do clube {{organization_title}}, clicando no botão abaixo.",
				unsubscribeSuccess: "Preferências atualizadas",
				events:{
					pageDescription: "Veja todos os eventos disponíveis aqui",
				},
				perks:{
					noHiddenFees: "Todas as taxas incluídas",
					applicationFeesApply: "Ao valor indicado acrescem-se os custos de operação",
					productRedeemedSuccessfully: "Produto resgatado com sucesso!",
					ticketRedeemedSuccessfully: "Bilhete resgatado com sucesso!",
					productRedeemedSuccessfullyDescription: "Obrigado por resgatar este produto. Entraremos em contato em breve.", 
                    ticketRedeemedSuccessfullyDescription: "Obrigado por resgatar este bilhete. Entraremos em contato em breve.",
					insuficientPointsMessage: "É necessário {{points}} créditos para reclamar este produto. Pode ganhar uma quantidade diferente de créditos mensalmente, dependendo da sua assinatura.",
					exclusivePerkMessage: "Exclusivo para membros {{tierTitles}}.",
					discountPerkMessage: "Desconto até {{higher_discount_percentage}}% para membros.",
					memberDiscountPerkMessage: "{{discount_percentage}}% de desconto aplicado por ser membro.",
					privatePerkMessage: "Aberto a todos os membros.",
					redeemedCountMessage: "Já comprou este item ",
					redeemModal:{
						rsvpStatementOfAction: "Está prestes a confirmar a sua presença em {{eventTitle}}.",
						statementOfAction: "Está prestes a resgatar {{title}}.",
						confirmationMessage: "Gostaria de continuar?",
						reasonLabel: "Por favor diga-nos porque está a resgatar esta oferta.",
						criteria:{
							points: "Comprar esta oferta custará {{points}} créditos. Atualmente você possui {{userPointsLabel}}.",
							private: "Este produto é exclusivo para membros.",
							exclusive: "Este produto é exclusivo para membros com o{{suffix}} passe{{suffix}}: {{tierTitles}}.",
							date: "Este produto expira {{formatedEndDate}}.",
							supply: "Apenas {{available_amount}} restantes.",
						},
					},
					pageDescription: "A loja virtual dá-lhe acesso a ofertas exclusivas apenas para membros. Consulta em cada oferta os créditos necessários para o resgate.",
				},
				memberships:{
					memberPageDescription: "Atualize o seu plano selecionando outra opção de assinatura",
					pageDescription: "Selecione a opção de assinatura mais adequada para si",
					paymentFrequency:{
                        monthly: "mês",
                        yearly: "ano",
                        quarterly: "trimestre",
                        lifetime: "pagamento único",
                    },
					claimModal:{
						billingDetailsCTA: "Adicionar detalhes de faturação",
						singleTicketDescription: "Irá receber o seu bilhete em formato digital no e-mail associado à sua conta.\nNa data do envento, o QR Code será necessário para entrar.\n\nSe não receber o bilhete dentro dos próximos minutos, por favor verifique a pasta de Spam.\n\nCaso se verifique que realmente não recebeu o bilhete, por favor entre em contacto para o e-mail hello@membrz.club para que possamos verificar a sua situação",
						singleTicketAtTheDoorDescription:"Também vai receber o seu bilhete em formato digital no e-mail associado à sua conta",
						atTheDoorModalTitle: "O{{suffix}} seu{{suffix}} bilhete{{suffix}}!",
                        atTheDoorModalDescription: "Obrigado pela sua compra. Por favor use o{{suffix}} QR Code{{suffix}} acima para entrar.",
						ticketDescription: "Informacão importante sobre o{{suffix}} bilhete{{suffix}}:", 
						multipleTicketDescription: "Irá receber os bilhetes em formato digital no e-mail associado à sua conta.\nSe desejar que os bilhetes sejam enviados para outros endereços por favor clique no botão abaixo, onde poderá adicionar esses detalhes.\n\nNa data do evento, os QR Codes serão necessários para entrar.\n\nSe algum dos bilhetes não for recebido dentro dos próximos minutos, por favor verifique a pasta de Spam.\nCaso se verifique que o bilhete realmente não foi recebido, por favor entre em contacto para o e-mail hello@membrz.club para que possamos verificar a sua situação.\n\nPara preencher os detalhes de faturação sem associar outros emails a esta compra por favor clique ",
						multipleAtTheDoorTicketDescription: "Também vai receber os bilhetes em formato digital no e-mail associado à sua conta.\nSe desejar que os bilhetes sejam enviados para outros endereços por favor clique no botão abaixo, onde poderá adicionar esses detalhes.\n\nPara preencher os detalhes de faturação sem associar outros emails a esta compra por favor clique ",
						ticketDetailsCTA: "Adicionar informação dos bilhetes",
						upgradeSuccessfulMessage: "A sua assinatura foi atualizada",
						upgradeConfirmationMessage: "Após confirmar que deseja prosseguir com a atualização da sua assinatura, vamos tentar cobrá-la no cartão de crédito associado. Gostaria de continuar?",
						thankYouForPayment: "Obrigado pelo seu pagamento!",
						ticketsSent: "Obrigado. Os bilhetes foram enviados para os email fornecidos.",
						welcomeToTheClub: "Bem vindo ao clube!",
						back: "Voltar",
						totalDue: "Total devido hoje",
						renewalTimingsFrequency: {
							monthly: "mensalmente",
							yearly: "anualmente",
							quarterly: "trimestralmente",
						},
						renewalTimings: "Pagará {{itemPriceWithCurrency}} hoje e depois {{translatedFrequency}} no {{day}}. A próxima cobrança será em {{date}}.",
						renewalTimingsSecond: "Esta assinatura renova-se automaticamente {{translatedFrequency}}, e será notificado antecipadamente se o valor a pagar aumentar. Pode cancelar a assinatura a qualquer momento.",
						invoiceFormDescription: "Adicione os seus detalhes abaixo.",
						invoiceCreated: "O seu recibo foi criado. Em breve, receberá uma copia no seu email. Pode ver todos os seus recibos ",
						summary: {
              monthly: "Pagamento mensal",
              yearly: "Pagamento anual",
              quarterly: "Pagamento trimestral",
              lifetime: "Pagamento único",
            },
						buyNowWith: "Comprar agora com",
						processingMbWay_part1: "Valide a transação na app do MBWay ou do seu Banco",
						processingMbWay_part2: "Clique confirmar para obter os",
						processingBizum_part1: "Complete o pagamento na aba de pagamento do Bizum",
						processingBizum_part2: "Se a aba de pagamento não se abriu, por favor clique no link abaixo",
						processingBizum_part3: "Clique confirmar para obter os",
					},
				},
				sidebar:{
                    joinTheClub: "Junta-te ao clube!",
                },
				about:{
                    privateElement: "Este Link é privado para membros do clube.",
                    exclusiveElement: "Este Link é privado para assinantes do seguinte nível: {{pass}}.",
					brandingButton: "Crie um Membrz.Club Grátis",
                },
                connectModal:{
                    loggedInContent: "Connect your wallet and claim your membership pass.",
                    loggedOutContent: "Connect your wallet to create a MembrzClub account and claim your membership pass.",
					step2Contnet: "Your MembrzClub account has been created. Please provide a valid email address to conclude your subscription process.",
				},
				freeSubscriptionModal:{
                    promptToSubscribe: "Gosta do que está a ler? Não perca nenhuma novidade. Juntar-se ao clube é tão simples quanto adicionar o seu email abaixo.",
                    emailPrompt: "Escreva o seu email...",
					passwordPrompt: "Escreva a sua password...",
					downgradingToFree: "Para fazer o downgrade para o nível gratuito, só precisa cancelar a sua assinatura atual. Pode desfrutar de todos os benefícios até à data de vencimento. Uma vez que a assinatura cancelada expire, movê-lo-emos para o nível selecionado. Até lá, pode reativar a sua assinatura.",
					agreement: "Ao continuar, está a aceitar os nossos",
				},
        repeatPurchaseModal:{
            title: "Espere um momento ⏳",
            descriptionFirst:{
              part1: "Parece que já comprou este {{category}}",
              part2: "e que a sua última compra foi há menos de uma hora.",
            },
            descriptionSecond:{
              ticket: "Por vezes, os emails de confirmação de compra e bilhetes podem demorar alguns minutos a chegar, especialmente durante períodos de alto tráfego.",
              product: "Por vezes, os emails de confirmação de compra podem demorar alguns minutos para chegar, especialmente durante períodos de alto tráfego.",
            },
            descriptionThird: "Confirma que deseja prosseguir com uma nova compra?",
            descriptionFourth:{
              ticket: "Se não, por favor, verifique se a confirmação de compra ou o seu bilhete está no seu email (ou na pasta de spam) ou envie-nos uma mensagem para ",
              product: "Se não, por favor, verifique se a confirmação de compra está no seu email (ou na pasta de spam) ou envie-nos uma mensagem para ",
            },
        },
			},
			publicReward:{
				// Duplicated label
				shareMessage: "Partilhe com o mundo",
				addThisRewardMessage: "Adicione este {{rewardCategoryLabel}} único à sua coleção. Leva menos de 1 minuto.",
			},
		//},
        student:{
			tickets:{
				pageTitle: "Bilhetes",
				pageDescription: "Aqui pode ver e gerir todos os seus bilhetes",
				noneFoundMessage: "Nenhum bilhete encontrado",
        sendTicket: "Enviar bilhete",
        detailsFormDescription: "Por favor, indique o nome e o email da pessoa a quem gostaria de enviar o bilhete.",
        warning: "Ao prosseguir, o bilhete será enviado para o endereço de e-mail fornecido. Depois de enviar o bilhete, irá perder o acesso ao mesmo.",
        sentSuccessfully: "Bilhete enviado com sucesso",
      },
			merch:{
				detailsUpdated: "Detalhes da queda de merchandise atualizados",
				incomplete_address: "Por favor, indique o seu endereço completo",
				shirtSize: "Tamanho da camisola *",
			},
			profile:{
				shareModalMessage: "É o novo proprietário de",
			},
			onChainModal:{
				title: "Quer o Passe de Membro como NFT?",
				contentFirst: "Se tiver uma Carteira Web3 (como o Metamask), pode resgatar o seu passe de membro como um NFT na blockchain.",
				contentSecond: "Isso vai permitir que o guarde na sua própria carteira e tenha acesso a outras aplicações Token-Gated e Web3.",
				contentThird: "Se não tiver uma carteira agora, não se preocupe! Pode sempre voltar e associar uma Carteira Web3 posteriormente.",
				laterButtonLabel: "Não, farei isso mais tarde!",
			},
			orders:{
				pageTitle: "Encomendas",
				pageDescription: "Aqui pode ver e gerir todas as suas encomendas",
				noneFoundMessage: "Nenhuma encomenda encontrada.",
			},
			offers:{
				pageTitle: "Ofertas",
				pageDescription: "Aqui pode ver todos as ofertas disponíveis para si",
				noOffers: "Não há ofertas disponíveis neste momento",
				noOffersRedeemed: "Ainda não resgatou nenhuma oferta",
				redeemedSuccessfullyMessage: "Resgatou esta oferta com sucesso.",
				redeemModal:{
					firstLabel: 'Está prestes a resgatar uma oferta para {{title}} de {{course}}.',
					firstLabelExternal: 'Está prestes a resgatar uma oferta para {{title}}.',
					secondLabel: 'Ao resgatá-la, receberá um email com instruções sobre como ativá-la no seu endereço de contato associado ao Membrz Club.',
					thirdLabel: 'Confirma que deseja resgatar a oferta?',
					redeemButtomCancel: 'Não. Quero pensar mais um pouco.',

				},
			},
			preferredLanguage: "Por favor escolha o seu idioma",
			connectAccountModal:{
                title:{
                    telegram: 'Conectar a conta do Telegram',
                    whatsapp: 'Conectar a conta do WhatsApp',
                },
                description:{
                    telegram: 'Por razões de segurança, apenas guardamos o seu nome de usuário do Telegram. Se ainda não tiver um, abra a aplicação do Telegram e adicione-o nas suas Definições.',
                    whatsapp: 'Participe em grupos e comunidades exclusivas no WhatsApp depois de conectar a sua conta do WhatsApp. Para o fazer basta adicionar o seu número de telefone abaixo.',
                },
                inputLabel:{
                    telegram: 'Nome de usuário',
                    whatsapp: 'Número de telefone',
                },
                showJoin: 'Para aderir a este Grupo no Telegram, precisa de conectar a sua conta do Telegram à sua conta no Membrz Club.'
            }
		},
		ui:{
			postCard:{
				commentPlaceholder: "Adicione um novo comentário",
				exclusive: "Este conteúdo está disponível apenas para assinantes premium dos seguintes níveis: {{tierTitles}}",
				privateOrFree: "Este conteúdo está disponível apenas para membros do clube",
				becomeAMember: "Torne-se um membro",
				becomeAFreeMember: "Subscreva Grátis",
				becomeAPremiumMember: "Torne-se Premium",
				becomeAMemberDescriptionFirst: "Para interagir com a comunidade, é necessário ser um membro.",
				becomeAMemberDescriptionSecond: "Gostaria de se tornar um agora?",
			},
		},
		useSubscriptionController:{
			cancelSubscription: "Cancelar Assinatura",
			reactivateSubscription: "Reativar Assinatura",
			cancelScheduledChange: "Cancelar Mudança Agendada",
			updatePaymentDetails: "Atualizar Detalhes de Pagamento",
			confirmModalMessage:{
				mustReactivate: "Pediu-nos para cancelar a sua assinatura. Se deseja alterar o seu nível de associado, por favor, reative a sua assinatura primeiro. Gostaria de o fazer agora?",
				mustCancelDowngrade: "Vamos fazer downgrade da sua assinatura para {{futureRewardTitle}}. Se deseja mudar o nível de membro para o qual está a fazer downgrade, por favor, cancele a mudança programada primeiro. Gostaria de o fazer agora?",
				downgradetoFreeInstructions: "Para fazer o downgrade para o nível gratuito, só precisa cancelar a sua assinatura atual. Pode desfrutar de todos os benefícios até à data de vencimento. Uma vez que a assinatura cancelada expire, movê-lo-emos para o nível selecionado. Até lá, pode reativar a sua assinatura. Gostaria de continuar?",
				downgradeInstructions: "Ao fazer downgrade para este nível, cancelará a sua assinatura atual. Pode desfrutar de todos os benefícios até à data de vencimento. Uma vez que a assinatura cancelada expire, movê-lo-emos para o nível selecionado. Até lá, pode reativar a sua assinatura. Gostaria de continuar?",
			},
			expiredSubscription: "Novas tentativas de cobrança serão automaticamente efetuadas.",
		},
		//___________________________________________________________________________________________________________________________________________________________________________________
        //components
        authForms:{
			loginForm:{
				forgotPasswordQuestion: "Esqueceu-se da sua senha?",
				forgotPasswordLink: "Redefina-a aqui.",
				newToMembrzQuestion: "Novo no Membrz Club? ",
                signUpCall: "Registe-se agora!",
			},
			resetForm:{
				successMessage: "Redefiniu a sua senha com sucesso. 🎉",
				claimingMessage: "Reivindicando o seu distintivo agora.",
				redirectMessage: "Redirecionando para o seu perfil.",
				requestInstructions: "Definir Senha e Reivindicar",
			},
			signUpForm:{
				alreadyRegisteredQuestion: "Se já tem uma conta no Membrz Club, por favor faça ",
				signInCall: 'login aqui',
				defaultInstructions: "Por favor indique o seu email para proceder.",
				storeInstructions: "Por favor indique o seu email para proceder.",
				eventInstructions: "Use um email válido para receber o seu bilhete.",
			},
			authModal:{
				loginTitle: "Bem-vindo de volta!",
				defaultSignUpTitle: "Informações Necessárias",
				storeSignUpTitle: "Informações Necessárias",
				eventSignUpTitle: "Informações Necessárias",
			},
			common:{
				agreementDeclaration: "Concordo com os Termos e Condições e a Política de Privacidade do Membrz Club",
			},
		},
        // not ui component. currently not being used? duplicated for easier deletion with component with not required
		offerCard:{
			externalEligibilityLabel: "Para resgatar este benefício, precisa possuir um {{rewardCategoryLabel}} com os seguintes atributos",
			internalElegilibityLabelReward: "Para resgatar este benefício, precisa possuir o seguinte {{rewardCategoryLabel}}",
			internalElegilibityLabelCourse: "Para resgatar este benefício, precisa possuir um {{rewardCategoryLabel}} da seguinte organização:",
			offerRedeemedWith: "Resgatou este benefício com:",
			validatingRequestMessage: "Por favor, dê-nos um momento para validar a sua solicitação",
			awaitingConfirmationMessage: "Será contactado em breve",
		},
		orderCard:{
			referenceNotFound: "Referência não encontrada",
		},
        // not ui component. currently not being used? duplicated for easier deletion with component if not required
		postCard:{
			lockedPost: "Este conteúdo está disponível apenas para assinantes dos seguintes níveis:",
		},
        // not claim model in use through profile. currently not being used? duplicated for easier deletion with component if not required
		claimModal:{
			upgradeSuccessfulMessage: "A sua assinatura foi atualizada",
			upgradeConfirmationMessage: "Após confirmar que deseja prosseguir com a atualização da sua assinatura, tentaremos cobrar o seu cartão guardado. Deseja continuar?",
		},
		layouts:{
			public:{
				pagePrimaryDescription: "O Membrz Club ajuda marcas, organizações e criadores a conectarem-se com o seu público usando colecionáveis digitais e NFTs.",
				pageSecondaryDescription: "Quer saber mais?",
				here: "Verifique aqui"
			},
		},
		rewardPreview:{
			metadata:{
				activityTitle: "Título da Coleção",
				activityType: "Tipo de Coleção",
				claimedAt: "Reivindicado em",
				issuedAt: "Emitido em",
				issuerName: "Nome do Emissor",
				tokenType: "Tipo de Token",
			},
			onboarding:{
				onboardingSubtitleFirst: "Colecionáveis Membrz Club",
				onboardingTextFirst: "Os colecionáveis Membrz Club são presentes digitais exclusivos emitidos como NFTs, celebrando momentos únicos da vida, experiências e conquistas.",
				onboardingSubtitleSecond: "Nunca ouviu falar sobre NFTs?",
				onboardingTextSecond: "NFTs (Tokens Não Fungíveis) são tokens digitais únicos e verificáveis na blockchain. Eles podem representar diferentes ativos digitais, são totalmente de sua propriedade e não podem ser falsificados. O processo para reivindicar um NFT com o Membrz Club é simples e não requer carteira ou experiência web3.",
			},
		},
		feedBackUser:{
			currentlyLoggedInAs: "Está atualmente logado como",
		},
		shareRewardModal:{
			defaultButton: "Veja e partilhe o seu {{rewardCategoryLabel}} aqui!",
			certificateButton: "Ver e partilhar!",
			//duplicated label
			shareMessage: "Partilhe com o mundo",
		},
		utils:{
			copyToClipboard:{
				success: "Copiado para a área de transferência!",
				failure: "Falha na cópia! {{error}}",
			}
		},
		rewardType:{
			behaviour: "NFT",
			achievement: "Certificado",
			collectible: "Colecionável",
			tier: "Assinatura"
		},
		customFields:{
			"asa-perafita":{
				matricula: "Matrícula"
			},
			unicornfactorylisboa:{
				startup: "Startup"
			}
		}
  },
};

export { labels };
