import React from 'react';
import PropTypes from 'prop-types';

import ImageUploadChip from './ImageUploadChip';

import { TextField} from '@mui/material';

const LinkComposer = ({ object, onObjectChange, onChange, loading, onAttachmentDelete }) => {

  return ( 
    <>
      <TextField
        size='small'
        name="link_title"
        label="Link Title"
        value={object.link_title}
        onChange={onChange}
        fullWidth
        color="warning"
        disabled={loading}
      />

      <ImageUploadChip 
        object={object}
        onObjectChange={onObjectChange}
        onAttachmentDelete={onAttachmentDelete}
      />
    </>
  );
};

LinkComposer.propTypes = {
    object: PropTypes.object,
    onObjectChange: PropTypes.func,
    link_title: PropTypes.string,
    onChange: PropTypes.func,
    loading: PropTypes.bool,
    onAttachmentDelete: PropTypes.func,
};

export default LinkComposer;