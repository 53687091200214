import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from 'ui/Button';
import Description from 'ui/text/Description';
import EditorModal from './EditorModal';

const EmailEditor = ({object, onObjectChange, onEmailSendRequest, onEmailCancel, onError, selectedRows=null, autoSelect=false, event=null, onSessionSelect=null}) => {

  const [open, setOpen] = useState(autoSelect ? true : false);

  const handleOpenModal = () => {
    if (selectedRows.length === 0) {
      onError("Please select at least one user to send an email.");
    } else {
      setOpen(true);
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
    onEmailCancel();
    if (onSessionSelect) onSessionSelect(null);
  };

  return (
    <div className='email-editor'>
        <Description>Use the checkboxes and filters in the table below to select the users you want to send an email to.</Description>

        <Button minwidth onClick={handleOpenModal} >Next</Button>
      
        <EditorModal 
          object={object}
          onObjectChange={onObjectChange}
          open={open}
          done={onEmailSendRequest}
          onClose={handleCloseModal}
          event={event}
          onSessionSelect={onSessionSelect}
        />

    </div>
  );
};

EmailEditor.propTypes = {
  object: PropTypes.object,
  onObjectChange: PropTypes.func,
  onEmailSendRequest: PropTypes.func,
  onEmailCancel: PropTypes.func,
  onError: PropTypes.func,
  selectedRows: PropTypes.array,
  autoSelect: PropTypes.bool,
  event: PropTypes.object,
  onSessionSelect: PropTypes.func
};

export default EmailEditor;