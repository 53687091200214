import React from "react";
import PropTypes from "prop-types";

import MarkdownEditor from "components/MarkdownEditor";

import { capitalize } from "utils/capitalize";
import { embedTypes } from "utils/pageElementsHelper";

import { InputAdornment, TextField } from "@mui/material";

const Content = ({ object, onObjectChange, onChange, onBlur, loading }) => {

  const { element_type, content } = object;
  
  const label = {
    title: "Header",
    embed: "Link",
    default: capitalize(element_type).replace("_embed", " Url"),
  };


  const embedElementIcon = element_type
    ? embedTypes.find((type) => type.value === element_type)?.logo
    : null;

  const inputProps = embedElementIcon
    ? {
        startAdornment: (
          <InputAdornment position="start">
            {
              <div style={{ width: "24px", height: "24px" }}>
                <img src={embedElementIcon} />
              </div>
            }
          </InputAdornment>
        ),
      }
    : {};

  return (
    element_type === "text" ? (
      <MarkdownEditor
        object={object}
        setObject={onObjectChange}
        attribute={"content"}
        placeholder={label.default}
        height={"200px"} />
    ) : (
      <TextField
        size="small"
        name="content"
        multiline={element_type === "text"}
        rows={element_type === "text" ? 4 : 1}
        label={element_type ? label[element_type] || label.default : "Link"}
        value={content}
        onChange={onChange}
        fullWidth
        color="warning"
        onBlur={onBlur}
        InputProps={inputProps}
        disabled={loading}
      />
    )
  );
};

Content.propTypes = {
  object: PropTypes.object,
  onObjectChange: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  loading: PropTypes.bool,
};

export default Content;
