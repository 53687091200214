import React from "react";
import PropTypes from "prop-types";

import NumberTextField from "components/NumberTextfield";

import Button from "ui/Button";

import { TextField } from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

const EmailsEditor = ({ recipients, ticket, onEmailChange, onAmountChange, onAddEmail }) => {
  
  return (
    <>
      {recipients.map((recipient, index) => (
        <div className="row" key={index}>
          <TextField
            label="Recipient Email"
            name="email"
            onChange={(e) => onEmailChange(e, index)}
            fullWidth
            size="small"
            color="warning"
            disabled={!ticket}
          />
          <NumberTextField
            label="Ticket Quantity"
            name="amount"
            onChange={(e) => onAmountChange(e, index)}
            size="small"
            color="warning"
            disabled={!ticket}
            value={recipients[index].amount}
          />
        </div>
      ))}

      <Button
        text
        small
        icon={AddCircleOutlineOutlinedIcon}
        onClick={onAddEmail}
        disabled={!ticket}
      >
        Add Email
      </Button>
    </>
  );
};

EmailsEditor.propTypes = {
  recipients: PropTypes.array,
  ticket: PropTypes.object,
  onEmailChange: PropTypes.func,
  onAmountChange: PropTypes.func,
  onAddEmail: PropTypes.func,
};

export default EmailsEditor;
