import React from 'react';
import PropTypes from 'prop-types';

import { getFilterOptions, getUserFriendlyIdentifier } from 'utils/filtersHelpers';

import { Autocomplete, TextField } from '@mui/material';

const Filter = ({ onChange, label, table }) => {

  const handleSelection = (event, newValue) => {
    onChange(label, newValue?.identifier);
  };

  const renderOption = (props, option) => <li {...props}>{getUserFriendlyIdentifier(label, table, option.identifier)}</li>;

  const getLabelText = (label) => {
    const labelMap = {
      invoice: "billing details",
      ticketing: "event type"
    };
    
    return labelMap[label] || label;
  };

  const renderInput = (params) => (
    <TextField 
      {...params} 
      label={`Filter by ${getLabelText(label)}`} 
      color="warning" 
    />
  );

  const defaultValue = table === "orders" && label === "status" 
                          ? {identifier: 'completed', id: 1} 
                          : table === "organizations" && label === "status"
                            ? {identifier: 'live', id: 1}
                            : undefined;

  return (
    <Autocomplete
      size="small"
      selectOnFocus
      blurOnSelect
      clearOnBlur
      clearOnEscape	
      handleHomeEndKeys
      sx={{ width: 275 }}
      options={getFilterOptions(label, table)}
      onChange={handleSelection}
      renderOption={renderOption}
      renderInput={renderInput}
      getOptionLabel={(option) => getUserFriendlyIdentifier(label, table, option.identifier)}
      defaultValue={defaultValue}
    />
  );
};

Filter.propTypes = {
    onChange: PropTypes.func,
    label: PropTypes.string,
    table: PropTypes.string
};

export default Filter;
