import React from 'react';
import PropTypes from 'prop-types';
import Description from 'ui/text/Description';

import { t } from 'i18n/index';

import Tags from 'components/Tags';
import {StyledDivider} from 'components/StyledComponents/StyledDivider';

const Footer = ({ location, organizationTags, tag_ids }) => {

  if(!location) return null;

  const mapUrl = `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=${encodeURIComponent(location)}`;

  return (
    <>  
      <Tags tag_ids={tag_ids} organizationTags={organizationTags}/>
      <Description>{t('literal:location')}</Description>
      <StyledDivider />
      <div style={{ height: '200px', width: '100%', borderRadius: '8px', overflow: 'hidden'}}>
        <iframe
          width="100%"
          height="100%"
          style={{ border: 0 }}
          src={mapUrl}
          allowFullScreen
        ></iframe>
      </div>
    </>
  );
};

Footer.propTypes = {
  location: PropTypes.string,
  organizationTags: PropTypes.array,
  tag_ids: PropTypes.array
};

export default Footer;