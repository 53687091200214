import React from 'react';

import CustomSnackbar from './CustomSnackbar';
import ImageToast from './ImageToast';

const Toast = () => {

  return (
    <>
      <CustomSnackbar />
      <ImageToast />
    </>
  );
};

export default Toast; 