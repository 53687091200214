import React from "react";
import PropTypes from "prop-types";

import ActionButtons from "./ActionButtons";

import { useNoteController } from "utils/useNoteController";

import { TextField } from "@mui/material";

const NoteEditor = ({ notable, notableType }) => {
  
  const {
    note,
    handleChangeNote,
    handleSaveNote,
    handleDeleteNote,
  } = useNoteController(notable, notableType);

  return (
    <TextField
        label="Note"
        multiline
        rows={3}
        value={note?.content || ""}
        onChange={handleChangeNote}
        fullWidth
        size="small"
        color="warning"
        margin="dense"
        InputProps={{
          endAdornment: (
            <ActionButtons 
              note={note} 
              onSave={handleSaveNote} 
              onDelete={handleDeleteNote} />
          ),
        }}
      />
  );
};

NoteEditor.propTypes = {
  notable: PropTypes.object,
  notableType: PropTypes.string
};

export default NoteEditor;