import React from "react";
import PropTypes from "prop-types";

import Button from "ui/Button";

import { DialogActions } from "@mui/material";

const Actions = ({ticket, onSubmit, loading}) => {
  return (
    <DialogActions>
        <Button highlighted onClick={onSubmit} disabled={!ticket} loading={loading}>Send Tickets</Button>
    </DialogActions>
  )
}

Actions.propTypes = {
  ticket: PropTypes.object,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};

export default Actions;