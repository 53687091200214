import React from 'react';
import PropTypes from 'prop-types';

import InvoiceResource from "resources/Profile/InvoiceResource";

import InvoiceForm from 'components/InvoiceForm';

import Subtitle from 'ui/text/Subtitle';

import { t } from 'i18n/index';

const InvoiceDetails = ({ setStep, senderName, senderEmail, paymentIntentId}) => {

  return (
    <div>
      <Subtitle>{t('literal:billingDetails')}</Subtitle>
      <p>{t('profile:memberships:claimModal:invoiceFormDescription')}</p>

      <InvoiceForm 
        record={ {name: senderName, email: senderEmail} }
        InvoiceResource={ InvoiceResource }
        paymentIntentId={ paymentIntentId }
        onDone={setStep}
      />   
    </div>
  );
};

InvoiceDetails.propTypes = {
  setStep: PropTypes.func,
  senderName: PropTypes.string,
  senderEmail: PropTypes.string,
  paymentIntentId: PropTypes.string
};

export default InvoiceDetails;