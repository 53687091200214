import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useController } from '@rest-hooks/react';

import StripePaymentForm from './StripePaymentForm';
import EupagoPaymentForm from './EupagoPaymentForm';
import PaypalPaymentForm from './PaypalPaymentForm';
import { handleValidation } from './handleValidation';
import LoadingComponent from '../LoadingComponent';

import { useError } from 'utils/useErrorController';
import usePaymentIntent from 'utils/usePaymentIntent';
import useStripe from 'utils/useStripe';
import useEupago from 'utils/useEupago';
import { findOrderResult } from 'utils/profile/claim/findOrderInstance';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { t } from 'i18n/index';

const orderPropertiesDefaultState = {
    itemPrice: '',
    quantity: '',
    percentOff: '',
};

const PaymentCollection = ({ 
  object, 
  pricingId,
  requestedAmount,
  userPhone, 
  onUserPhoneChange,
  stripeState, 
  onStripeStateChange, 
  orderState, 
  onOrderChange,
  onStepChange,
  onContentChange, 
  isPass,
  onShowTickets,
  promoCode
}) => {
  
  const { setLoading, loading } = useError();
  const { fetch } = useController();

  const {selectedProvider, providers} = orderState;
  
  const [orderPropertiesState, setOrderPropertiesState] = useState(orderPropertiesDefaultState);

  const showTabs = providers.length > 1 && !isPass;

  const handleProviderChange = (provider) => {
    const newOrderState = {selectedProvider: provider, paymentIntentId: ''};
    onOrderChange((previousValue) => ({...previousValue, ...newOrderState}));
  };

  const { 
    stripe, 
    handleStripeCardPaymentIntent, 
  } = useStripe({
    onStripeStateChange, 
    onOrderChange, 
    setOrderPropertiesState
  })

  const { 
    processingEupago,
    handleEupagoPaymentIntent,
    handleErrorFindingOrderResult 
  } = useEupago({
    onOrderChange,
    onUserPhoneChange
  });

  const handlePaypalPaymentIntent = (id) => {
    onOrderChange((previousValue) => ({...previousValue, paymentIntentId: id}));
  };

  const { 
    createPaymentIntent 
  } = usePaymentIntent({
    handleValidation, 
    userPhone, 
    object, 
    pricingId, 
    requestedAmount, 
    promoCode, 
    handleStripeCardPaymentIntent, 
    handleEupagoPaymentIntent,
    handlePaypalPaymentIntent
  });

  const handleEupagoFormSubmission = async (e, payment_method) => {
    e.preventDefault();
    e.stopPropagation();

    if (processingEupago) {
      setLoading(true);
      findOrderResult( processingEupago, fetch, onStepChange, handleErrorFindingOrderResult, setLoading, onContentChange, onShowTickets);
    } else {
      createPaymentIntent("eupago", payment_method);
    }  
  };

  useEffect(() => {
    if (selectedProvider === "stripe") createPaymentIntent("stripe");
  }, [selectedProvider]);

  const isBizumOrganization = object?.organization?.id === 1;
 
  const tabLabels = {
    "stripe": t('literal:card'), 
    "eupago": isBizumOrganization ? "Bizum" : "MBWay",
    "paypal": "Paypal"
  };

  return (
    <>
      {showTabs && 
        <Box className="tabpanel-menu slim" sx={{ borderBottom: 1, borderColor: 'var(--borderColor)' }} >
          <Tabs value={providers.indexOf(selectedProvider)}>
            { providers.map( (provider, index) => (
                <Tab key={ index } label={ tabLabels[provider] } disabled={loading} onClick={() => handleProviderChange(provider)} />
              ))
            }
          </Tabs>
        </Box>
      }

      {!selectedProvider &&
        <LoadingComponent />
      }

      {selectedProvider === "stripe" && (
        <StripePaymentForm
          object={ object }
          orderPropertiesState={ orderPropertiesState }
          onStepChange={ onStepChange }
          onContentChange={ onContentChange }
          stripeState={ stripeState }
          onShowTickets={ onShowTickets }
          pricingId={ pricingId }
          stripe={ stripe }
        />
      )}

      {selectedProvider === "paypal" && (
        <PaypalPaymentForm
          object={ object } 
          pricingId={ pricingId }
          requestedAmount={ requestedAmount }
          createOrder={ () => createPaymentIntent("paypal") }
          onShowTicket={ onShowTickets }
          onStepChange={ onStepChange }
          onContentChange={ onContentChange }
        />
      )}

      {selectedProvider === "eupago" && (
        <EupagoPaymentForm
          object={ object } 
          orderPropertiesState={ orderPropertiesState }
          pricingId={ pricingId }
          userPhone={ userPhone }
          onUserPhoneChange={ onUserPhoneChange }
          requestedAmount={ requestedAmount }
          onSubmit={ handleEupagoFormSubmission }
          processingEupago={ processingEupago }
          redirectUrl={ orderState.redirectUrl }
          isBizumOrganization={ isBizumOrganization }
        />
      )}
    </> 
  );
};

PaymentCollection.propTypes = {
  object: PropTypes.object,
  pricingId: PropTypes.number,
  requestedAmount: PropTypes.number,
  userPhone: PropTypes.string,
  selectedProvider: PropTypes.string,
  onStepChange: PropTypes.func,
  onContentChange: PropTypes.func,
  onOrderChange: PropTypes.func,
  onUserPhoneChange: PropTypes.func,
  onBack: PropTypes.func,
  stripeState: PropTypes.object,
  onStripeStateChange: PropTypes.func,
  orderState: PropTypes.object,
  isPass: PropTypes.bool,
  onShowTickets: PropTypes.func,
  promoCode: PropTypes.string,
};

export default PaymentCollection;