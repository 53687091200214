import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import OrganizationResource from 'resources/admin/OrganizationResource';
import Form from './Form';
import { useToast } from 'utils/context/ToastContext';
import { useError } from 'utils/useErrorController';

import Dialog from '@mui/material/Dialog';
import { useController } from '@rest-hooks/react';
import { createFormData } from './createFormData';
import { handleValidation } from './handleValidation';

const EditOrganizationModal = ({ open, onClose, activeOrg, handleActiveOrg }) => {

  const { fetch } = useController();
  const { showToast } = useToast();
  const { handleError, validate, setLoading, loading } = useError();

  const handleChange = (event) => {
    const { name, checked, value } = event.target;
    if (name === "title" || name === "status") {
      handleActiveOrg({ ...activeOrg, [name]: value })
    } else {
      if (checked) {
        handleActiveOrg({ ...activeOrg, [name]: true });
      } else {
        handleActiveOrg({ ...activeOrg, [name]: false });
      }
    }
  };

  const handleSuccessfulResponse = () => {
    setLoading(false);
    showToast('success', 'Organization updated');
    onClose();
  };
  
  const handleEditOrganization = useCallback(
    async (e, org) => {
      try {
        validate(org, handleValidation);
        const formData = createFormData(org);
        await fetch(OrganizationResource.update(), {id: org.id}, formData);
        handleSuccessfulResponse();
      }  catch (error) {
        handleError(error)
      }
    },
    [fetch],
  );  

  return (
    <Dialog open={ open } 
            onClose={ onClose }
            fullWidth
            maxWidth='md' >

      <Form activeOrg={ activeOrg } 
            onSubmit={ handleEditOrganization } 
            onChange={ handleChange }  
            onClose={ onClose }
            loading={ loading } />

    </Dialog>
  );
}

EditOrganizationModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  activeOrg: PropTypes.object,
  handleActiveOrg: PropTypes.func,
};

export default EditOrganizationModal;