import React from 'react';

import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import OpenInNew from '@mui/icons-material/OpenInNew';

const handleClick = (organization, handleActiveOrg, handleOpenEditModal) => {
  handleActiveOrg(organization);
  handleOpenEditModal(true);
};

/* eslint-disable react/prop-types */
const EditButton = ({ organization, handleActiveOrg, handleOpenEditModal }) => (
  <IconButton color='warning' onClick={() => handleClick(organization, handleActiveOrg, handleOpenEditModal)}>
    <EditIcon fontSize='small'/>
  </IconButton>
);

/* eslint-disable react/prop-types */
const RedirectButton = ({ id }) => (
  <IconButton color='warning' onClick={() => window.location.href = '/organizations/' + id }>
    <OpenInNew fontSize='small'/>
  </IconButton>
);

export const organizationColumns = (handleActiveOrg, handleOpenEditModal) => [
  {
    name: 'ID',
    selector: (row) => row.id,
    sortable: true,
    width: '75px'
  },
  {
    name: 'Title',
    selector : (row) => row.title,
    sortable: true,
    width: '150px',
    editable: true
  },
  {
    name: 'Status',
    selector : (row) => row.status,
    sortable: true,
    width: '150px',
    editable: true
  },
  {
    name: 'Users',
    selector : (row) => row.students_count,
    sortable: true,
    width: '100px'
  },
  {
    name: 'Net Value',
    selector : (row) => row.net_value,
    sortable: true,
    width: '150px'
  },
  {
    name: 'Service Fees',
    selector : (row) => row.service_fees,
    sortable: true,
    width: '150px'
  },
  {
    name: 'Total Value',
    selector : (row) => row.total_value,
    sortable: true,
    width: '150px'
  },
  {
    name: 'Currency',
    selector : (row) => row.currency,
    sortable: true,
    width: '150px'
  },
  {
    selector : (row) => <RedirectButton id={row.id} />,
    sortable: false,
    width: '75px'
  },
  {
    selector : (row) => <EditButton organization={row} handleActiveOrg={handleActiveOrg} handleOpenEditModal={handleOpenEditModal} />,
    sortable: false,
    width: '75px'
  }
];
