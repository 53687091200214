import React from "react";
import PropTypes from "prop-types";

import ApplicationFeeResource from 'resources/organization/ApplicationFeeResource';

import NumberTextField from "components/NumberTextfield";

import { labels } from "utils/pricingsConsts";
import { convertPriceFromCents, convertPriceToCents } from 'utils/rewards';

import { InputAdornment } from "@mui/material";
import { useParams } from "react-router-dom";
import { useController } from "@rest-hooks/react";

const PriceField = ({errors, frequency, price, isDisabled, onPriceChange, onChange, icon, showApplicationFee}) => {

  const {fetch} = useController();
  const params  = useParams();
  const organizationId = parseInt(params.organizationId);

  const label =
      errors[ frequency ] 
        ? frequency === 'lifetime' 
          ? labels["remove"]
          : labels["format"] 
        : labels[frequency];

  const handleBlur = async () => {
    if (!showApplicationFee) return;

    const { application_fee } = await fetch(ApplicationFeeResource.list(), {organization_id: organizationId, price: convertPriceToCents(price)});
    onChange({target: {name: 'application_fee', value: convertPriceFromCents(application_fee)}});
  };

  return (
    <NumberTextField 
        error={frequency !== 'lifetime' && errors[ frequency ]}
        name={frequency}
        label={label}
        color="warning"
        size="small"
        fullWidth
        value={price || ''}
        onChange={onPriceChange}
        onPaste={event => event.preventDefault()}
        disabled={isDisabled}
        InputProps={{
          endAdornment: <InputAdornment position="end">{icon}</InputAdornment>,
        }}
        onBlur={handleBlur} />
  );
};

PriceField.propTypes = {
  errors: PropTypes.object,
  frequency: PropTypes.string,
  price: PropTypes.string,
  label: PropTypes.string,
  isDisabled: PropTypes.bool,
  onPriceChange: PropTypes.func,
  onChange: PropTypes.func,
  icon: PropTypes.object,
  showApplicationFee: PropTypes.bool
};

export default PriceField;