import React from "react";
import PropTypes from "prop-types";

import TicketSelector from "./TicketSelector";
import EmailsEditor from "./EmailsEditor";

import SessionSelector from "components/SessionSelector";

import FormSection from "ui/FormSection";
import Description from "ui/text/Description";

import { Divider } from "@mui/material";

const Content = ({event, recipients, ticket, selectedSessionId, onRecipientsChange, onSessionChange, onTicketChange}) => {

  const { sessions, single, tickets } = event;

  const session = !single && sessions.find(session => session.id === +selectedSessionId);
  const selectableTickets = session ? session.tickets : tickets;
  const filteredTickets = selectableTickets.filter(ticket => ticket.available);

  const handleTicketSelection = (event, ticket) => {
    onTicketChange(ticket);
  };

  const handleEmailChange = (e, index) => {
    const newRecipients = [...recipients];
    newRecipients[index] = {...recipients[index], email: e.target.value};
    onRecipientsChange(newRecipients);
  };
  
  const handleAmountChange = (e, index) => {
    const newRecipients = [...recipients];
    newRecipients[index] = {...recipients[index], amount: e.target.value};
    onRecipientsChange(newRecipients);
  };

  const handleAddEmail = () => {
    onRecipientsChange([...recipients, {
      email: "",
      amount: 1,
    }]);
  };

  return (
    <div className="content">
      {single || selectedSessionId ? (
        <FormSection slim>
          <Description>
            After selecting a ticket, add an email below to send a new ticket.
          </Description>
          
          <TicketSelector 
            ticket={ticket} 
            tickets={tickets} 
            filteredTickets={filteredTickets} 
            onSelect={handleTicketSelection} 
          />

          <Divider />

          <EmailsEditor
            recipients={recipients}
            ticket={ticket}
            onEmailChange={handleEmailChange}
            onAmountChange={handleAmountChange}
            onAddEmail={handleAddEmail}
          />

        </FormSection>
      ) : (
        <SessionSelector options={event.sessions} onChange={onSessionChange} />
      )}
    </div>
  );
};

Content.propTypes = {
  event: PropTypes.object,
  recipients: PropTypes.array,
  ticket: PropTypes.object,
  selectedSessionId: PropTypes.number,
  onRecipientsChange: PropTypes.func,
  onSessionChange: PropTypes.func,
  onTicketChange: PropTypes.func,
};

export default Content;