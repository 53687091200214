import { validateFileCount, validateFileSize } from "./fileValidation";
import VideocamIcon from '@mui/icons-material/Videocam';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

export const hasAttachments = (object, type, multiple) => !!object[`${type}_filename${multiple ? 's' : ''}`] && object[`${type}_filename${multiple ? 's' : ''}`].length > 0;

export const handleFileUpload = (event, object, onObjectChange, objectType, onLoadingChange, onError, multiple, setUnsavedChanges ) => {
    event.preventDefault();
    if (multiple) onLoadingChange(true);

    if (!event.target.files || event.target.files.length === 0) return;

    const files = Array.from(event.target.files);
    const type = event.target.name;

    let newObject = { ...object };

    if (multiple && validateFileCount(object, files, type, onError, objectType)) {

      if (!newObject[`${type}_files`]) {
        newObject[`${type}_files`] = [];
      }

      if (!newObject[`${type}_filenames`]) {
        newObject[`${type}_filenames`] = [];
      }

      files.forEach((file) => {
        if (validateFileSize(file, type, onError)) {
          newObject[`${type}_files`].unshift(file);
          newObject[`${type}_filenames`].unshift(file.name)
        }
      });
    } else {
      const file = files[0]
      if (validateFileSize(file, type, onError)) {
        newObject[`${type}_file`] = file;
        newObject[`${type}_filename`] = file.name;
      }
    }

    if (setUnsavedChanges) setUnsavedChanges(true);

    onObjectChange(newObject);

    if (multiple) onLoadingChange(false);
  };

  export const handleRemoveUpload = (type, index, object, onObjectChange, hiddenFileInputs, multiple, setUnsavedChanges) => () => {
    hiddenFileInputs[type].current.value = null;
    let newObject = { ...object };
    if (multiple) {
      newObject[`${type}_files`] = newObject[`${type}_files`].filter((_, i) => i !== index);
      newObject[`${type}_filenames`] = newObject[`${type}_filenames`].filter((_, i) => i !== index);
    } else {
      newObject[`${type}_file`] = null;
      newObject[`${type}_filename`] = null;
    }

    if (setUnsavedChanges) setUnsavedChanges(true);

    onObjectChange(newObject);
  };

  export const getAttachmentTypes = (image_filenames, video_filenames=null) =>
  {
    const attachmentTypes = [{
      type: "image",
      icon: PhotoCameraIcon,
      label: "Image",
      accept: "image/jpeg, image/png, image/gif, image/bmp, image/webp, image/svg+xml",
      filenames: image_filenames,
      condition: true,
    }];

    if (video_filenames) {
      attachmentTypes.push({
        type: "video",
        icon: VideocamIcon,
        label: "Video",
        accept: "video/*",
        filenames: video_filenames,
        condition: true,
      });
    }

    return (attachmentTypes);
  };
