import React, { useRef } from "react";
import PropTypes from "prop-types";

import FormSection from "ui/FormSection";
import MediaUploader from "components/MediaUploader";

const Attachments = ({ object, onObjectChange, loading, setLoading, onError }) => {

  const hiddenFileInputs = {
    image: useRef(null),
    video: useRef(null),
  };

  const label = [
    "To ensure a smoother user experience the images must be smaller than 4 MB and the video must be smaller than 20 MB.",
    <br key="br1" />,
    "You can upload up to 20 images and 1 video per post."
  ];

  return (
    <FormSection title={"Media Gallery"}>
      <MediaUploader
        hiddenFileInputs={hiddenFileInputs}
        multiple
        objectType={"post"}
        object={object}
        onObjectChange={onObjectChange}
        loading={loading}
        onLoadingChange={setLoading}
        onError={onError}
        label={label}
      />
    </FormSection>
  );
};

Attachments.propTypes = {
  object: PropTypes.object,
  onObjectChange: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  onError: PropTypes.func,
};

export default Attachments;
