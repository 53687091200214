import React from "react";
import PropTypes from "prop-types";

import FullwidthCard from "../FullwidthCard";

import Image from "./Image";
import Header from "./Header";
import Content from "./Content";

const TicketCard = ({ item, upcoming }) => {

  const {title, description, uuid, event_title, start_date} = item;

  return (
    <FullwidthCard image={<Image reference={uuid} />}
                   header={<Header event_title={event_title} start_date={start_date} title={title} description={ description } />}
                   content={upcoming ? <Content uuid={uuid} /> : null} />
  );
};

TicketCard.propTypes = {
  item: PropTypes.object,
  upcoming: PropTypes.bool,
};

export default TicketCard;
