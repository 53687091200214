import React from 'react';
import PropTypes from 'prop-types';

import Filter from 'ui/Filter';

const OrganizationFilter = ({ onChange }) => {

  const statusFilter = <Filter onChange={onChange} label="status" table={'organizations'} />

  return (
    [statusFilter]
  );
};

OrganizationFilter.propTypes = {
  onChange: PropTypes.func,
};

export default OrganizationFilter;
