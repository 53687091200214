import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    backgroundColor: 'var(--secondaryBackgroundColor)',
    color: 'var(--primaryTextColor)',
  },
  '& .MuiInputLabel-root': {
    color: 'var(--primaryTextColor)',
    '&.Mui-focused': {
      color: 'var(--highlightColor)',
    }
  },
  '& .MuiInputBase-input:-webkit-autofill': {
    '-webkit-box-shadow': '0 0 0 100px var(--secondaryBackgroundColor) inset',
    '-webkit-text-fill-color': 'var(--primaryTextColor)',
    'caretColor': 'var(--primaryTextColor)',
  },
  '& .MuiInputBase-input::placeholder': {
    color: 'var(--primaryTextColor)',
    opacity: 0.7,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'var(--borderColor)',
    },
    '&:hover fieldset': {
      borderColor: 'var(--borderColor)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'var(--highlightColor)',
    },
  },
  '& .MuiIconButton-root': {
    color: 'var(--primaryTextColor)',
  },
  '& .MuiFormHelperText-root': {
    color: 'var(--primaryTextColor)',
  },
});

export default StyledTextField;
