import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import StatCard from 'ui/StatCard/StatCard';

import fetchStats from 'utils/fetchStats';
import { useError } from 'utils/useErrorController';

import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";

const defaultChartSettings = {
  labels: [],
  datasets: [{
    data: [],
    backgroundColor: [
      'rgb(255, 99, 132)',
      'rgb(54, 162, 235)',
      'rgb(255, 205, 86)'
    ],
    hoverOffset: 4
  }]
};

ChartJS.register(ArcElement, Tooltip);

const Stats = () => {
  const [primaryCount, setPrimaryCount] = useState(0);
  const [chartData, setChartData] = useState(defaultChartSettings);

  const { handleError } = useError();

  const params = useParams();
  const organizationId = parseInt(params.organizationId);

  const fetchAndSetStats = async () => {
    try {
      const {primary_count, primary_chart_data} = await fetchStats(`organizations/${organizationId}/reward_instances`);

      setPrimaryCount(primary_count);

      setChartData({
        labels: primary_chart_data?.labels || [],
        datasets: [
          {
            ...defaultChartSettings.datasets[0],
            data: primary_chart_data?.values || []
          }
        ]
      });
      
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    fetchAndSetStats();
  }, [organizationId]);

  return (
    <div className="ui-stats-grid">
      <StatCard type='number'
                highlight={ primaryCount }
                title='Active Memberships' />

      <StatCard type='chart'
                highlight={<Doughnut data={ chartData } />}
                title='Memberships by Tier' />
    </div>
)};

export default Stats;
