import React from "react";
import PropTypes from "prop-types";

import { Alert, AlertTitle, Checkbox, FormControlLabel } from "@mui/material";
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';

const CheckInAlert = ({ orderAmount, checkInAll, onCheckInAllChange }) => {
  
  return (
    <Alert 
      severity="warning"
      variant="outlined"
      icon={<ConfirmationNumberIcon />}
      sx={{ '& .MuiAlert-icon': { order: 1, ml: 'auto', mr: 0 }}}
    >
      <AlertTitle sx={{ fontWeight: 400, color: 'black' }}>This order includes</AlertTitle> 
      <AlertTitle sx={{ fontSize: '1.5rem', fontWeight: 600, mb: 2, color: 'black' }}>{orderAmount} Tickets</AlertTitle>
      <FormControlLabel
        sx={{ '& .MuiFormControlLabel-label': { fontSize: '1rem', color: 'black' }}}  
        control={
          <Checkbox 
            sx={{ '& .MuiSvgIcon-root': { fontSize: '1.2rem' }}}
            color="warning"
            checked={checkInAll}
            onChange={(e) => onCheckInAllChange(e.target.checked)}
          />
        }
        label={`Check in all tickets in this order`}
      />
    </Alert>
  );
};

CheckInAlert.propTypes = {
  orderAmount: PropTypes.number,
  checkInAll: PropTypes.bool,
  onCheckInAllChange: PropTypes.func,
};

export default CheckInAlert; 