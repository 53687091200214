import React, { useState } from "react";
import { useController } from "@rest-hooks/react";
import PropTypes from "prop-types";

import OrganizationResource from "resources/organization/OrganizationResource";

import FormSection from "ui/FormSection";
import Button from "ui/Button";

import createFormData from "./createFormData";

import NotificationList from "components/NotificationList";
 
import { useToast } from 'utils/context/ToastContext';
import { useError } from "utils/useErrorController";

import { Checkbox, FormControlLabel } from "@mui/material";

const Invoicing = ({ organization }) => {

  const { fetch } = useController();
  const { loading, setLoading, handleError } = useError();
  const { showToast } = useToast();

  const { id, invoice_notification_list, preferences } = organization;

  //useSuspense uses organization data from OrganizationResource.list() called in Teacher.js to mount the component before waiting for data from detail()
  //OrganizationResource.list doesn't contain invoice_notification_list nor preferences resulting in misleading initial values on the form
  if (!preferences) return null;
  
  const [showList, setShowList] = useState(invoice_notification_list?.length > 0);
  const [notificationList, setNotificationList] = useState(invoice_notification_list);
  const [invoicing, setInvoicing] = useState(preferences?.['allow_invoicing']);

  const handleChange = ({target: {value}}) => {
    if (!value) {
      setShowList(false);
      setInvoicing(false);
    }
    setNotificationList(value);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const formData = createFormData(notificationList, invoicing, preferences);

      await fetch(OrganizationResource.update(), {id: id}, formData);
      await fetch(OrganizationResource.detail(), { id: id });
      
      showToast('success', 'Changes Saved');
      setLoading(false);
      
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <FormSection title="Billing">
        { showList ? (
          <>
            <NotificationList
              notification_list={notificationList}
              onChange={handleChange}
            />
          </>
        ) : (
          <FormControlLabel
            control={
              <Checkbox
                checked={showList}
                onChange={() => setShowList(!showList)}
              />
            }
            label="Enable Billing Notifications"
          />
        )}
        <FormControlLabel
          control={
            <Checkbox
              checked={invoicing}
              onChange={() => setInvoicing(!invoicing) }
              disabled={!showList || notificationList?.length === 0}
            />
          }
          label="Allow users to request invoices"
        />
      </FormSection>
      <div className={ `new-form-field-footer` }>
        <Button loading={loading} minwidth onClick={handleSubmit}>
          Update
        </Button>
      </div>
    </>
  );
};

Invoicing.propTypes = {
  organization: PropTypes.object,
};

export default Invoicing;
