import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import StatCard from 'ui/StatCard/StatCard';
import { useController } from '@rest-hooks/react';

const Stats = ({TicketResource, ticketResourceParams, stats}) => {

    const {checked_in_count, not_checked_in_count, total_count} = stats[0];

    const {fetch} = useController();
    
    const refreshStatsData = async () => {
        await fetch(TicketResource.list(), {...ticketResourceParams, stats: true});
    };

    useEffect(() => {
        refreshStatsData()
    }, []);

    return (
        <div className="ui-stats-grid">
            <StatCard type='number'
                highlight={ checked_in_count }
                title='Checked In' />

            <StatCard type='number'
                highlight={ not_checked_in_count }
                title='Not Checked In' />

            <StatCard type='number'
                  highlight={ total_count }
                  title='Issued Tickets' />
        </div>
    )};

Stats.propTypes = {
    TicketResource: PropTypes.object,
    ticketResourceParams: PropTypes.object,
    stats: PropTypes.array,
};

export default Stats;