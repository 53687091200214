import React from 'react';
import PropTypes from 'prop-types';

import { getListItems } from './getListItems';

import { Tooltip } from '@mui/material';

const OrderDetailsItems = ({ order }) => {

  const items = getListItems(order);

  return (
    <>
      {items.map(
        (item) =>
          (item.condition === undefined || item.condition) && (
            <div key={item.label}>
              <Tooltip title={item.label}>
                <item.icon color={item.iconColor || "warning"} />
              </Tooltip>
              <p key={item.label}>
                {item.showLabel && `${item.label}: `} {item.value}
              </p>
            </div>
          )
      )}
    </>
  );
};

OrderDetailsItems.propTypes = {
  order: PropTypes.object
};

export default OrderDetailsItems;