import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';

import { DialogActions } from '@mui/material';

const Actions = ({onClose, onSubmit}) => {
  return (
    <DialogActions>
      <Button minwidth secondary onClick={onClose}>Cancel</Button>
      <Button minwidth primary onClick={onSubmit}>Insert Button</Button>
    </DialogActions>
  );
};

Actions.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};

export default Actions;