import React from 'react';
import PropTypes from 'prop-types';

import NumberTextField from 'components/NumberTextfield';

import Label from 'ui/text/Label';

const SupplyLimit = ({ supply_limit, onChange, published, mysteryBoxReward, mysteryBox }) => {
  return (
    <>
      <NumberTextField
        fullWidth
        size='small'
        name="supply_limit"
        label={`Total ${mysteryBoxReward ? "collection" : ""} stock${mysteryBox ? " *" : ""}`}
        color="warning"
        value={supply_limit}
        onChange={onChange}
        disabled={published}
        />
      <Label secondary padded>Leave empty for unlimited</Label>
    </>
    
  );
};

SupplyLimit.propTypes = {
  supply_limit: PropTypes.number,
  onChange: PropTypes.func,
  published: PropTypes.bool,
  mysteryBoxReward: PropTypes.bool,
  mysteryBox: PropTypes.bool,
};

export default SupplyLimit;