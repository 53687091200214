import React, { useState } from 'react';

import OrderDetailsModalContent from './OrderDetailsModalContent';
import CustomModal from 'components/CustomModal';

const useOrderDetailsModal = () => {
  const [customModalContent, setCustomModalContent] = useState(null);

  const handleOpenOrderDetails = (order) => {
    setCustomModalContent({
      title: 'Order Details',
      content: <OrderDetailsModalContent order={order} />,
      fullScreen: true
    })
  };

  const OrderDetailsModalComponent = () => {
    return (
      customModalContent && (
        <CustomModal
          handleClose={() => setCustomModalContent(null)}
          customModalContent={customModalContent}
        />
      )
    );
  };

  return {
    handleOpenOrderDetails,
    OrderDetailsModalComponent
  };
};

export default useOrderDetailsModal;