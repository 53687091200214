import React from "react";
import PropTypes from "prop-types";

import CustomModal from 'components/CustomModal';

import Button from 'ui/Button';

import { TextField } from "@mui/material";

const SendTestEmailModal = ({ open, onClose, destinationEmails, onDestinationEmailsChange, onClick, loading }) => {
  
  if (!open) return null;

  const content = (
    <TextField
      fullWidth
      size="small"
      color="warning"
      name="emails"
      value={destinationEmails}
      margin="normal"
      onChange={onDestinationEmailsChange}
      label="Email Addresses"
      helperText="To send a test email, enter one or more valid email addresses, separated by commas."
    />
  );

  const customModalContent = { 
    title: "Send Test Email",
    content: content,
    button: <Button highlighted minwidth onClick={onClick} loading={loading}>Send</Button>,
  };

  return (
    <CustomModal handleClose={onClose} customModalContent={customModalContent} dividers={false} />
  );
};

SendTestEmailModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  destinationEmails: PropTypes.string,
  onDestinationEmailsChange: PropTypes.func,
  onClick: PropTypes.func,
  loading: PropTypes.bool
};

export default SendTestEmailModal;