import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";

export const statuses = {
  completed: {
    label: "Completed",
    icon: CheckCircleOutlineIcon,
    iconColor: "success",
  },
  pending: {
    label: "Pending",
    icon: PendingOutlinedIcon,
    iconColor: "warning",
  },
  refund: {
    label: "Refund",
    icon: BackspaceOutlinedIcon,
    iconColor: "error",
  },
  refunded: {
    label: "Refunded",
    icon: BackspaceOutlinedIcon,
    iconColor: "success",
  },
  failed: {
    label: "Failed",
    icon: CancelOutlinedIcon,
    iconColor: "error",
  },
};