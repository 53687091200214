import React from 'react';
import PropTypes from 'prop-types';

import Title from './Title';
import Content from './Content';
import Actions from './Actions';

import { Dialog } from '@mui/material';

const ElementModal = ({ onSubmit, pageElement, setPageElement, loading, passes, open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth 
      maxWidth='sm'
    > 

      <Title pageElementId={pageElement.id} pageElementType={pageElement.element_type} />

      <Content pageElement={pageElement} setPageElement={setPageElement} passes={passes} />

      <Actions onSubmit={onSubmit} loading={loading} pageElementId={pageElement.id}/>

    </Dialog>
  );
};

ElementModal.propTypes = {
  onSubmit: PropTypes.func,
  onUpdate: PropTypes.func,
  onChange: PropTypes.func,
  pageElement: PropTypes.object,
  setPageElement: PropTypes.func,
  loading: PropTypes.bool,
  passes: PropTypes.array,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ElementModal;