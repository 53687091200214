import React from 'react';
import PropTypes from 'prop-types';
import { useController } from '@rest-hooks/react';
import { useParams } from 'react-router-dom';

import PaypalResource from 'resources/Profile/PaypalResource';
import MembershipResource from "resources/Profile/MembershipResource";
import ProfileResource from "resources/Profile/ProfileResource";
import ProductResource from "resources/Profile/ProductResource";

import OrderSummary from "components/OrderSummary";

import { useError } from 'utils/useErrorController';

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import { t } from "i18n/index";

const style = {
  layout: "vertical",
  color: "gold",
  label: "buynow",
  disableMaxWidth: true
};

const PaypalPaymentForm = ({
  object,
  pricingId,
  requestedAmount,
  createOrder,
  onShowTicket,
  onStepChange,
  onContentChange
}) => {

  const { setLoading, loading, handleError } = useError();
  const { organizationSlug } = useParams();
  const { fetch } = useController();

  const { title, pricings, image_url, organization, application_fee, discount_price, net_discount, session_start_date } = object;
  const { organization_title, show_application_fee, currency } = organization;

  const price = pricings.find((pricing) => pricing.id === pricingId).price;

  const initialOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    currency: currency,
    intent: "capture",
  };

  const handlePaypalPaymentApproval = async (data) => {
    try {
      const response = await fetch(PaypalResource.detail(), { organization_slug: organizationSlug, id: data.orderID });

      const {order_id_main, uuids, order_id, content_text} = response;

      if (order_id === order_id_main) {
      
        if (uuids) onShowTicket(uuids);
  
        await fetch(MembershipResource.list(), {
          organization_slug: organizationSlug,
        });
        await fetch(ProfileResource.detail(), {
          id: organizationSlug,
        });
        await fetch(ProductResource.list(), {
          organization_slug: organizationSlug,
        })

        onStepChange(2);
        onContentChange(content_text);
        setLoading(false);
  
      } else {
        handleError(t("errors:failedToFindOrderResult"));
      }
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className="stripe-payment-form">
      <OrderSummary
        currency={currency}
        unit_price={price}
        discount_price={discount_price}
        net_discount={net_discount}
        quantity={requestedAmount}
        frequency={"lifetime"}
        imageUrl={image_url}
        organizationTitle={organization_title}
        title={title}
        applicationFee={application_fee}
        session_start_date={session_start_date}
        showApplicationFee={show_application_fee}
      />

      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          style={style}
          createOrder={createOrder}
          onApprove={handlePaypalPaymentApproval}
          fundingSource="paypal"
          disabled={loading}
        />
      </PayPalScriptProvider>
    </div>
    
  )
};

PaypalPaymentForm.propTypes = {
  object: PropTypes.object,
  pricingId: PropTypes.number,
  requestedAmount: PropTypes.number,
  createOrder: PropTypes.func,
  onShowTicket: PropTypes.func,
  onStepChange: PropTypes.func,
  onContentChange: PropTypes.func,
};

export default PaypalPaymentForm;