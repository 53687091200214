import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';

import { MenuItem } from '@mui/material';
import StyledMenu from 'components/StyledComponents/StyledMenu';

const MenuButton = ({ icon, menuItems }) => {

    if (menuItems.filter(item => item.condition).length === 0) return null;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
  
    const handleClick = (event) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleSelection = (item) => {
      item.onClick();
      setAnchorEl(null);
    };

  return (
    <div>
        <Button 
            onClick={handleClick}
            text >
            {icon}
        </Button>
        <StyledMenu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            {menuItems.map((item, index) => (
                item.condition && (
                <MenuItem key={index} 
                          onClick={() => handleSelection(item)}
                          disableRipple>
                    {item.label}
                </MenuItem>
            )))}
        </StyledMenu>
    </div>
  );
};

MenuButton.propTypes = {
    icon: PropTypes.node,
    menuItems: PropTypes.array
};

export default MenuButton;