import React from 'react';
import PropTypes from 'prop-types';

import StyledChip from './StyledComponents/StyledChip';

const Tags = ({tag_ids, organizationTags}) => {

    const renderTags = 
        tag_ids && tag_ids.length > 0 ? 
            tag_ids.map(tag_id => {
                const tag = organizationTags.find(tag => tag.id === tag_id)
                return (
                    <StyledChip key={tag_id} label={tag?.name} backgroundVariant='secondary'/>
                )
        }) : null

    return (
        <div className='tags-container'>  
            {renderTags}
        </div>
    );
};

Tags.propTypes = {
  tag_ids: PropTypes.array,
  organizationTags: PropTypes.array
};

export default Tags;