import React from 'react';
import PropTypes from 'prop-types';

import TicketCard from 'components/Cards/TicketCard';

const TicketsGrid = ({ data, upcoming }) => {
    
    return (
        <div className='tickets-grid'>
            {data.map((item, index) => {
                return <TicketCard item={ item } key={ index } upcoming={ upcoming } />
            })}
        </div>
    );
};

TicketsGrid.propTypes = {
    data:PropTypes.array,
    upcoming: PropTypes.bool,
};

export default TicketsGrid;
