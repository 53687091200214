import React from "react";
import PropTypes from "prop-types";

import MarkdownEditor from 'components/MarkdownEditor';

import Link from "./Link";

import FormSection from "ui/FormSection";
import Label from 'ui/text/Label';

const SuccessMessage = ({expandable, product, onProductChange, onChange}) => {

  const {content_link} = product

  return (
    <FormSection title={"Success Message"} expandable={expandable}>
      <MarkdownEditor
        object={product}
        setObject={onProductChange}
        attribute={"content_text"}
        placeholder={"Success Message (max 1000 characters)"}
        height={"200px"}
      />

      <Label secondary padded>
        This message will be displayed to the member upon purchasing this product. This is where you can:<br />
        - Outline the next steps the member should take.<br />
        - Specify the expected timeframe for any feedback or follow-up.<br />
        - If applicable, share private discount codes or unique instructions.<br />
      </Label>

      <Link
        content_link={content_link}
        onChange={ onChange } />

    </FormSection>
  );
};

SuccessMessage.propTypes = {
  expandable: PropTypes.bool,
  product: PropTypes.object,
  onProductChange: PropTypes.func,
  onChange: PropTypes.func
};

export default SuccessMessage;
