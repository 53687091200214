const statuses = {
  tickets : {
    "initiated": "Not Checked In",
    "completed": "Checked In",
  },
  orders : {
    "pending": "Pending",
    "completed": "Completed",
    "refund": "To be Refunded",
    "refunded": "Refunded",
    "failed": "Failed",
  },
  subscriptions: {
    "active": "Active",
    "expired": "Expired",
    "expiring": "Expiring",
  },
  organizations: {
    "live": "Live",
    "draft": "Draft",
    "suspended": "Suspended",
  }
};

const methods = {
  "stripe": "Card",
  "mbway": "MBWay",
  "bizum": "Bizum",
  "utrust": "Crypto",
  "cash": "Cash",
  "paypal": "PayPal",
};

const invoiceOptions = {
  true: "Has billing details",
  false: "No billing details"
};

const ticketingOptions = {
  true: "Has Ticketing",
  false: "No Ticketing",
};

export const getFilterOptions = (label, table) => {
  switch (label) {
    case "status":
      return getStatusFilterOptions(table);
    case "method":
      return getMethodFilterOptions();
    case "invoice":
      return getInvoiceFilterOptions();
    case "ticketing":
      return getTicketingFilterOptions();
    default:
      return [];
  }
};

export const getUserFriendlyIdentifier = (label, table, identifier) => {
  switch (label) {
    case "status":
      return getStatusUserFriendlyIdentifier(table, identifier);
    case "method":
      return getMethodUserFriendlyIdentifier(identifier);
    case "invoice":
      return invoiceOptions[identifier];
    case "ticketing":
      return ticketingOptions[identifier];
    default:
      return identifier;
  }
};

const getStatusFilterOptions = (table) => { 
  return Object.keys(statuses[table]).map((status, index) => ({ identifier: status, id: index })); 
};

const getStatusUserFriendlyIdentifier = (table, identifier) => {
  return statuses[table][identifier];
};

const getMethodFilterOptions = () => { 
  return Object.keys(methods).map((method, index) => ({ identifier: method, id: index })); 
};

const getMethodUserFriendlyIdentifier = (method) => {
  return methods[method];
};

const getInvoiceFilterOptions = () => { 
  return Object.keys(invoiceOptions).map((option, index) => ({ identifier: option, id: index })); 
};

const getTicketingFilterOptions = () => {
  return Object.keys(ticketingOptions).map((option, index) => ({ identifier: option, id: index }));
};