import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";

import OrganizationResource from "resources/admin/OrganizationResource";

import Form from "./form";
import { handleValidation } from "./handleValidation";
import createFormData from "./createFormData";

import { useToast } from "utils/context/ToastContext";
import { useError } from "utils/useErrorController";

import Dialog from "@mui/material/Dialog";
import { useController } from "@rest-hooks/react";

const CreateOrganizationModal = ({ open, onClose }) => {
  const { fetch } = useController();
  const { showToast } = useToast();
  const { validate, handleError } = useError();

  const emptyOrganization = {
    title: "",
    slug: "",
    admin_name: "",
    admin_email: "",
    currency: "EUR",
    country: null,
  };

  const [organization, setOrganization] = useState(emptyOrganization);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const newOrganization = { ...organization };
    newOrganization[name] = value;
    setOrganization(newOrganization);
  };

  const handleCreateOrganization = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        validate(organization, handleValidation);
        const formData = createFormData(organization);
        const { id, title } = await fetch(
          OrganizationResource.create(),
          formData
        );

        // success!
        if (id) {
          showToast(
            "success",
            `Organization ${title} created`,
          );
          handleClose();
        }
      } catch (error) {
        handleError(error);
      }
    },
    [fetch, organization]
  );

  const handleClose = () => {
    onClose();
    setOrganization(emptyOrganization);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <Form
        onSubmit={handleCreateOrganization}
        onClose={handleClose}
        onChange={handleChange}
        organization={organization}
        onOrganizationChange={setOrganization}
      />
    </Dialog>
  );
};

CreateOrganizationModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CreateOrganizationModal;
