import { Menu } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledMenu = styled(Menu)({
  '& .MuiMenuItem-root': {
    color: 'var(--primaryTextColor)',
    '& svg': {
      paddingRight: '15px',
      color: 'var(--primaryTextColor)',
      fontSize: '36px',
    },
  },
  '& .MuiPaper-root': {
    backgroundColor: 'var(--primaryBackgroundColor)',
    borderColor: 'var(--borderColor)',
    maxHeight: '60vh',
  },
});

export default StyledMenu;