import React from 'react';
import PropTypes from 'prop-types';

import Subject from './Subject';
import Body from './Body';

import Label from 'ui/text/Label';
import Button from 'ui/Button';
import FormSection from 'ui/FormSection';

import SendIcon from '@mui/icons-material/Send';
import { Alert } from '@mui/material';

const WelcomeEmail = ({ newsletterSettings, onChange, onUnsavedChanges, onEmailReset, onSendTestEmail, openAlert}) => {

  const { welcome_email } = newsletterSettings;
  
  const handleSubjectChange = ({target: {name, value}}) => {
    onUnsavedChanges(true);
    const newWelcomeEmail = { ...welcome_email, [name]: value };
    const newSettings = { ...newsletterSettings, welcome_email: newWelcomeEmail };
    onChange(newSettings);
  };

  const handleBodyChange = (newWelcomeEmail) => {
    onUnsavedChanges(true);
    const newSettings = { ...newsletterSettings, welcome_email: newWelcomeEmail };
    onChange(newSettings);
  };

  return (
    <FormSection slim>
      <Subject subject={welcome_email.subject} onSubjectChange={handleSubjectChange} />

      <Label secondary>
        Change your default Welcome Email. <br />
        This will be sent when a new user joins your club or when you add members manually if you select the option  &apos;Yes, Send Default Email &apos; <br/>
      </Label>

      <Body email={welcome_email} onChange={handleBodyChange} onEmailReset={onEmailReset} />

      {openAlert && (
        <Alert severity="success">
          {`Test email sent successfully to ${openAlert} address${openAlert > 1 ? 's' : ''}. Do not forget to save your changes.`}
        </Alert>
      )}

      <Button smallPadded secondary small onClick={onSendTestEmail} icon={SendIcon} >Send Test Email</Button>

    </FormSection>
  );
};

WelcomeEmail.propTypes = {
  newsletterSettings: PropTypes.object,
  onChange: PropTypes.func,
  onUnsavedChanges: PropTypes.func,
  onEmailReset: PropTypes.func,
  onSendTestEmail: PropTypes.func,
  openAlert: PropTypes.bool,
};

export default WelcomeEmail;
