import React from 'react';
import PropTypes from 'prop-types';

import NumberTextField from 'components/NumberTextfield';

const RedeemLimit = ({redeem_limit, onChange}) => {

  const label = 'Max amount per user. Leave empty for unlimited'
  return (
        <NumberTextField
            fullWidth
            size='small'
            color="warning"
            name='redeem_limit'
            value={redeem_limit || ''}
            onChange={onChange}
            label={label} />
  );
};

RedeemLimit.propTypes = {
  redeem_limit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};

export default RedeemLimit;