import React from "react";
import PropTypes from "prop-types";

import SubscribeButtonActionEditor from "./SubscribeButtonActionEditor";

import { TextField } from "@mui/material";

const ActionButton = ({ custom_cta, custom_cta_action, handleChange }) => {
  return (
    <>
      <TextField
        fullWidth
        size="small"
        name="custom_cta"
        label="Action Button Label"
        color="warning"
        value={custom_cta}
        defaultValue="Join the club"
        onChange={handleChange}
      />

      <SubscribeButtonActionEditor
        value={custom_cta_action}
        onChange={handleChange}
      />
    </>
  );
};

ActionButton.propTypes = {
  custom_cta: PropTypes.string,
  custom_cta_action: PropTypes.string,
  handleChange: PropTypes.func,
};

export default ActionButton;
