import { t } from 'i18n/index';
import { validateEmail } from 'utils/validations/validateEmail';
import { validateStringField } from 'utils/validations/validateStringField';

export const handleValidation = (invoiceData) => {
  const {name, email, country, post_code, vat} = invoiceData;

  validateStringField(name, 'name', t('errors:nameMissing'));
  validateEmail(email, null, t('errors:emailMissing'));
  if (vat) {
    validateStringField(country, 'country', t('errors:countryMissing'));
  }
  if (country === "US" || country === "CA") {
    validateStringField(post_code, 'post code', t('errors:postalCodeMissing'));
  }
};