import React from 'react';
import PropTypes from 'prop-types';

const CustomButton = ({label, url}) => {

  const containerStyle = { 
    display: "flex", 
    width: "100%", 
    justifyContent: "center"
  };
  
  const linkStyle = {
    padding: "12px 16px",
    border: "1px solid var(--highlightColor)",
    borderRadius: "8px",
    borderColor: "var(--highlightColor)",
    backgroundColor: "var(--highlightColor)",
    color: "#FFFFFF",
    textDecoration: "none",
    cursor: "pointer",
    margin: "8px 0",
    fontFamily: "inherit",
    fontSize: "16px",
  };

  const transformedUrl = url.replace(/\\/g, '');

  return (
    <div style={containerStyle}>
      <a href={transformedUrl} 
          target="_blank" 
          rel="noopener noreferrer"
          style={linkStyle}
        >
        {label}
      </a>
    </div>
  );
};

CustomButton.propTypes = {
  label: PropTypes.string,
  url: PropTypes.string
};

export default CustomButton;