import { useEffect, useState } from "react";
import { useController } from "@rest-hooks/react";

import NoteResource from "resources/organization/NoteResource";

import { useError } from "utils/useErrorController";

export const useNoteController = (notable, notableType) => {

  const { fetch } = useController();
  const { handleError } = useError();
  
  const [note, setNote] = useState("");

  const organizationId = notable.organization_id;
  const notableId = notable.id;

  const fetchData = async () => {
    try {
      const note = await fetch(NoteResource.detail(), {
        organization_id: organizationId,
        notable_type: notableType,
        notable_id: notableId
      });
      setNote(note);

    } catch (error) {
      if (error.status === 404) {
        return;
      } else {
        handleError(error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [notable, notableType]);

  const handleChangeNote = (e) => {
    const newNote = { ...note, content: e.target.value };
    setNote(newNote);
  };

  const handleSaveNote = async () => {
    try {
      const resource = note.id ? "update" : "create";
      const newNote = await fetch(
        NoteResource[resource](),
        { 
          organization_id: organizationId,
          notable_type: notableType, 
          notable_id: notableId,
          id: note.id
        },
        { 
          content: note.content
        }
      );
      
      setNote(newNote);
    } catch (error) {
      handleError(error);
    }
  };

  const handleDeleteNote = async () => {
    try {
      await fetch(
        NoteResource.delete(), 
        { 
          organization_id: organizationId,
          notable_type: notableType,
          notable_id: notableId,
          id: note.id
        }
      );

      setNote("");
    } catch (error) {
      handleError(error);
    }
  };

  return {
    note,
    handleChangeNote,
    handleSaveNote,
    handleDeleteNote,
  };
};