import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';

import Description from 'ui/text/Description';
import StyledChip from 'components/StyledComponents/StyledChip';
import formattedDate from 'utils/formattedDate';
import { getMinPrice, handleEventRedirect } from 'utils/events';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// import EventRepeatIcon from '@mui/icons-material/EventRepeat';

// import { t } from 'i18n/index';
import './styles.css';

const Extended = ({event, hidePrice=false, hideLocation=false}) => {
  const {title, image_url, start_date, location, ticketing, ticket_price, tickets, single, sessions} = event;
  const shownStartDate = sessions?.length > 0 ? sessions[0].start_date : start_date;
  let price = ticketing && single ? getMinPrice(tickets) : ticket_price;

  const date = formattedDate(shownStartDate, 'date_short');

  const history = useHistory();
  const {organizationSlug} = useParams();

  const handleRedirect = () => {
    handleEventRedirect(event, organizationSlug, history);
  };

  return (
    <div className='ui-event-extended' onClick={handleRedirect}>
      <div className='event-image'>
        <img src={image_url} alt={title} />
      </div>

      <div className='details'>
        <div className='date-container'>
          <StyledChip icon={<CalendarMonthIcon />} label={date} backgroundVariant="secondary" size="small" />
        </div>
        <Description bold>{title}</Description>
        {!hideLocation && location && <Description secondary>{location}</Description>}
        {!hidePrice && price && <Description className='price'>{price}</Description>}
      </div>
    </div>
  )
};

Extended.propTypes = {
    event: PropTypes.object,
    hidePrice: PropTypes.bool,
    hideLocation: PropTypes.bool,
};

export default Extended;
