import React from "react";
import PropTypes from "prop-types";

import StatCard from "ui/StatCard/StatCard";
import { formatPrice } from "utils/rewards";

const Stats = ({ products }) => {
  const product = products?.[0];

  const stat1 = product ?  formatPrice(product.total_revenue, product.currency) : 0;
  
  const stat2 = product ? product.total_count : 0;

  return (
    <div className="ui-stats-grid">

      <StatCard type="number"
        highlight={ stat1 }
        title="Total Revenue" />

      <StatCard type="number"
        highlight={ stat2 }
        title="Issued Products Count" />

    </div>
)};

Stats.propTypes = {
  products: PropTypes.array
};

export default Stats;