import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@mui/material';

const numberInputStyles = {
  '& input[type=number]': {
    MozAppearance: 'textfield',
    WebkitAppearance: 'textfield',
    appearance: 'textfield',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      appearance: 'none',
      margin: 0
    }
  }
};

const NumberTextField = (props) => {
  return (
    <TextField
      {...props}
      type="number"
      inputProps={{
        ...props.inputProps,
        inputMode: 'numeric'
      }}
      onWheel={(e) => e.target.blur()}
      sx={numberInputStyles}
    />
  );
};

NumberTextField.propTypes = {
  inputProps: PropTypes.object
};

export default NumberTextField;