import { useState } from "react";

import { useError } from "./useErrorController";

import { loadStripe } from "@stripe/stripe-js";

const useStripe = ({
  onStripeStateChange,
  onOrderChange,
  setOrderPropertiesState
}) => {

  const [stripe, setStripe] = useState(null);
  const { setLoading } = useError();

  const getStripeInstance = async (stripePublishableKey, stripeAccountId) => {
    if (stripePublishableKey && stripeAccountId) {
      const stripeInstance = await loadStripe(stripePublishableKey, { stripeAccount: stripeAccountId, locale: 'auto' });
      return stripeInstance;
    }
  };

  const handleStripeCardPaymentIntent = async (clientSecret, stripePublishableKey, stripeAccountId, id, itemPrice, amount) => {

    const newStripeState = {clientSecret: clientSecret, stripePublishableKey: stripePublishableKey, stripeAccountId: stripeAccountId};
    onStripeStateChange((previousValue) => ({...previousValue, ...newStripeState}));

    const newOrderState = {paymentIntentId: id};
    onOrderChange((previousValue) => ({...previousValue, ...newOrderState}));

    const newOrderPropertiesState = {itemPrice: itemPrice, quantity: amount};
    setOrderPropertiesState((previousValue) => ({...previousValue, ...newOrderPropertiesState}));

    const stripeInstance = await getStripeInstance(stripePublishableKey, stripeAccountId);
    setStripe(stripeInstance);

    setLoading(false);  
  };

  return {     
    stripe,
    handleStripeCardPaymentIntent, 
  };
};

export default useStripe;