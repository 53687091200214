import { capitalize } from 'utils/capitalize';
import { t } from "i18n/index";

export const parseErrorMessages = async (error) => {
    const response = await error.response.json();
    const errorMessages = [];
    if (response.status === 500) {
      // similarly to what we did inside the network error boundary, 
      // I chose not to specify the exception ${response.exception}.
      // will display: "Internal Server Error" instead of ""
      errorMessages.push(`${response.error}`);
    } else {
      if (response.errors) {
        Object.keys(response.errors).forEach((field) => {
          if (field === 'translate') {
            errorMessages.push(t(response.errors[field]));
            return
          }
          errorMessages.push(`${capitalize(field)}: ${response.errors[field].join(', ')}. `)
        });
      }
    }
    
    return errorMessages;
  };