import React from "react";
import PropTypes from "prop-types";

import Coupons from "./Coupons";
import PromoCodes from "./PromoCodes";

import PricingAndApplicationFee from "components/PricingAndApplicationFeeFields";

import FormSection from "ui/FormSection";
import Description from "ui/text/Description";

import { priceRegex } from "utils/pricingsConsts";

import { FormControlLabel, Stack, Typography, Switch } from "@mui/material";

const Price = ({
  errors,
  onErrorsChange,
  offer,
  onOfferChange,
  onChange,
  currency,
  application_fee_enabled,
}) => {

  const { unit_price, application_fee, coupons, new_promo_codes, existing_promo_codes, free, redeem_limit, redeemed } = offer;

  const handlePriceChange = ({ target: { name, value } }) => {
    if (value === "") {
      onErrorsChange({ ...errors, [name]: false });
    } else if (!priceRegex.test(value)) {
      onErrorsChange({ ...errors, [name]: true });
      return;
    }
    onErrorsChange({ ...errors, [name]: false });
    onChange({ target: { name: "unit_price", value: value } });
  };

  const handleFreeChange = (e, value) => {
    let newOffer;
    if (value) {
      newOffer = {
        ...offer,
        unit_price: "",
        redeem_limit: redeem_limit || 1,
        coupons: [], application_fee: 0,
        min_amount: null,
        new_promo_codes: [],
        existing_promo_codes: [],
        free: true};
    } else {
      newOffer = { ...offer, free: false };
    }
    onOfferChange(newOffer);
  };

  return (
    <FormSection>
      <Stack direction="row" spacing={0.5} alignItems="center">
        <Description bold large>
          Pricings & Discounts
        </Description>
        <>
          <FormControlLabel
            control={
              <Switch
                color="warning"
                checked={free}
                onChange={handleFreeChange}
              />
            }
          />
          <Typography>Free</Typography>
        </>
      </Stack>

      <PricingAndApplicationFee
        defaultFrequency={"lifetime"}
        price={unit_price}
        isDisabled={free || redeemed > 0}
        errors={errors}
        onPriceChange={handlePriceChange}
        onChange={onChange}
        currency={currency}
        applicationFee={application_fee}
        showApplicationFee={application_fee_enabled} />

      <Coupons
        price={unit_price}
        currency={currency}
        coupons={coupons}
        onChange={onChange}
        disabled={free} />

      <PromoCodes
        price={unit_price}
        currency={currency}
        new_promo_codes={new_promo_codes}
        existing_promo_codes={existing_promo_codes}
        onChange={onChange}
        disabled={free} />

    </FormSection>
  );
};

Price.propTypes = {
  errors: PropTypes.object,
  onErrorsChange: PropTypes.func,
  offer: PropTypes.object,
  onOfferChange: PropTypes.func,
  onChange: PropTypes.func,
  currency: PropTypes.string,
  application_fee_enabled: PropTypes.bool,
};

export default Price;
