const setCustomTheme =(theme) => {

  const { font_family,
          highlight_color,
          primary_text_color,
          secondary_text_color,
          primary_button_color,
          secondary_button_color,
          button_text_color,
          primary_background_color,
          secondary_background_color,
          border_color,
          stripe_theme } = theme;

  // Global properties
  document.documentElement.style.setProperty('--fontFamily', `'${font_family}', sans-serif`);
  document.documentElement.style.setProperty('--highlightColor', highlight_color);
  document.documentElement.style.setProperty('--primaryTextColor', primary_text_color);
  document.documentElement.style.setProperty('--secondaryTextColor', secondary_text_color);

  // Button colors
  document.documentElement.style.setProperty('--primaryButtonColor', primary_button_color);
  document.documentElement.style.setProperty('--secondaryButtonColor', secondary_button_color);
  document.documentElement.style.setProperty('--buttonTextColor', button_text_color);

  // Background/Cards colors
  document.documentElement.style.setProperty('--primaryBackgroundColor', primary_background_color);
  document.documentElement.style.setProperty('--secondaryBackgroundColor', secondary_background_color);
  document.documentElement.style.setProperty('--borderColor', border_color);

  // Stripe theme
  document.documentElement.style.setProperty('--stripeTheme', stripe_theme);
}

export default setCustomTheme
