import AuthToken from 'utils/localStorage/AuthToken';

export default function sendEmail(endpoint, params) {
    const url = new URL(`${process.env.REACT_APP_API_HOST}${endpoint}`);
    const authToken = AuthToken.read();

    // Return the fetch Promise
    return fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json', // Changed to JSON
            'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify(params), // Stringify the params object
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    });
}
