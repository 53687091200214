import React from 'react';
import PropTypes from 'prop-types';

import ShortNewsletter from './Notifications';
import WelcomeEmail from './WelcomeEmail';
import Banner from './Banner';

import FormSection from 'ui/FormSection';

const Preferences = ({newsletterSettings, onCheckboxChange, onChange, preview, onUnsavedChanges, onEmailReset, onSendTestEmail, loading, openAlert}) => {

  const { notifications_enabled, hide_banner} = newsletterSettings;

  return (
    <>
      <FormSection title={'Newsletter Emails'}>
        <ShortNewsletter
          notifications_enabled={notifications_enabled}
          onChange={onCheckboxChange}
        />
        <Banner
          hide_banner={hide_banner}
          onChange={onCheckboxChange}
        />
      </FormSection>

      {/* Forcing re-rendering with loading to refresh initialValue */}
      <FormSection title={'Welcome Email'} expandable={!loading} > 
        <WelcomeEmail
          newsletterSettings={newsletterSettings}
          onChange={onChange}
          onUnsavedChanges={onUnsavedChanges}
          preview={preview}
          onEmailReset={onEmailReset}
          onSendTestEmail={onSendTestEmail}
          openAlert={openAlert}
        />
      </FormSection>
    </>
  );
};

Preferences.propTypes = {
  newsletterSettings: PropTypes.object,
  onCheckboxChange: PropTypes.func,
  onChange: PropTypes.func,
  preview: PropTypes.bool,
  onUnsavedChanges: PropTypes.func,
  onEmailReset: PropTypes.func,
  onSendTestEmail: PropTypes.func,
  loading: PropTypes.bool,
  openAlert: PropTypes.bool,
};

export default Preferences;