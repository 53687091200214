import React from 'react';
import PropTypes from 'prop-types';

import NumberTextField from 'components/NumberTextfield';

const ClaimLimit = ({claim_limit, onChange, published}) => {
  return (
      <NumberTextField
          fullWidth
          size='small'
          name="claim_limit"
          label={`Max amount per user. Leave empty for 1`}
          color="warning"
          value={claim_limit}
          onChange={onChange}
          disabled={published} />
  );
};

ClaimLimit.propTypes = {
  claim_limit: PropTypes.object,
  onChange: PropTypes.func,
  published: PropTypes.bool,
};

export default ClaimLimit;