import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useController } from '@rest-hooks/react';

import TagResource from 'resources/organization/TagResource';

import { useError } from 'utils/useErrorController';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";

const TagSelector = ({object, onObjectChange, objectType, organizationId}) => {

  const [tags, setTags] = useState([]);
  const [selected, setSelected] = useState([]);

  const { fetch } = useController();
  const { handleError } = useError();
  const filter = createFilterOptions();

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const tagsData = await fetch(TagResource.list(), { organization_id: organizationId, tag_type: objectType });
        setTags(tagsData);
      } catch (error) {
        handleError(error);
      }
    };

    fetchTags();
  }, [organizationId, handleError]);

  useEffect(() => {
    const initializeSelectedTags = () => {
      if (Array.isArray(object.tag_ids)) {
        const selectedTags = tags.filter(tag => object.tag_ids.includes(tag.id))
          .map(tag => ({ id: tag.id, name: tag.name, create: false }));
        setSelected(selectedTags);
      }
    };

    initializeSelectedTags();
  }, [object.tag_ids, tags]);

  const createNewTag = async (name) => {
    try {

      const regex = /^[a-z0-9-]{2,48}$/;

      if (name.trim() === "") {
        throw "Tag name must not be empty";
      
      } else if (!regex.test(name.trim())) {
        throw "Tag name must be 2-48 characters long, lowercase and contain only letters, numbers, and hyphens that you can use to separate words.";
      
      } else {
        const {id} = await fetch(TagResource.create(), {organization_id: organizationId}, {name: name, tag_type: objectType});
        setSelected([...selected, {id: id, name: name, create: true}]);
        onObjectChange({ ...object, tag_ids: [...object.tag_ids, id] });
        const updatedTags = await fetch(TagResource.list(), {organization_id: organizationId, tag_type: objectType});
        setTags(updatedTags);
      }

    } catch (error) {
      handleError(error);
    }
  };

  const removeTag = (tagId, newValue) => {
    const updatedTagIds = object.tag_ids.filter((id) => id !== tagId);
    onObjectChange({ ...object, tag_ids: updatedTagIds });
    setSelected(newValue);
  };

  const removeAllTags = () => {
    onObjectChange({ ...object, tag_ids: [] });
    setSelected([]);
  };

  const updateTags = (newValue) => {
    onObjectChange({ ...object, tag_ids: newValue.map((item) => item.id) });
    setSelected(newValue);
  };

  const handleTagSelection = (event, newValue, reason, details) => {
    if (details && details.option.create && reason !== 'removeOption') {
      createNewTag(details.option.name);

    } else if (reason === 'removeOption') {
      removeTag(details.option.id, newValue);

    } else if (reason === 'clear') {
      removeAllTags();

    } else {
      updateTags(newValue);

    }
  };

  const filterOptions = (options, params) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    const isExisting = options.some((option) => inputValue === option.name);

    if (inputValue !== '' && !isExisting) {
      filtered.push({
        name: inputValue,
        label: `Add "${inputValue}"`,
        create: true,
      });
    }

    return filtered;
  };

  const getOptionLabel = (option) => {
    if (typeof option === 'string') {
      return option;
    }

    if (option.label) {
      return option.name;
    }

    return option.name;
  };

  const renderOption = (props, option) => <li {...props}>{option.create ? option.label : option.name}</li>;
  
  const renderInput = (params) => <TextField {...params} color='warning' label="Tags" />;

  const options = tags.filter((tag) => !selected.some((selectedTag) => selectedTag.id === tag.id));

  return (
    <Autocomplete
          size='small'
          multiple
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          freeSolo
          fullWidth
          value={selected}
          options={options}
          onChange={handleTagSelection}
          filterOptions={filterOptions}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          renderInput={renderInput}
      />
  )
}

TagSelector.propTypes = {
  object: PropTypes.object,
  onObjectChange: PropTypes.func,
  objectType: PropTypes.string,
  organizationId: PropTypes.number
};

export default TagSelector;