import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from "react-router-dom";
import { useSuspense } from "@rest-hooks/react";

import RewardResource from "resources/organization/RewardResource";

import TierSelector from './TierSelector';

import NumberTextField from 'components/NumberTextfield';

import Button from 'ui/Button';

import { emptyCoupon, getNewNetDiscount } from "utils/offersFormHelper";
import { currencies } from "utils/pricingsConsts";

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutline';
import { InputAdornment } from '@mui/material';

export const Coupons = ({price, currency, coupons, onChange, disabled}) => {

  const params = useParams();
  const organizationId = parseInt(params.organizationId);

  const rewards = useSuspense(RewardResource.list(), { organization_id: organizationId});
  const tiers   = rewards.map(({ id, title }) => ({ id, title }));

  const handleCouponChange = (event, index) => {
    const newValue = event.target.value >= 100 ? 99 : event.target.value;
    let data = [...coupons];
    data[index].percent_off = newValue;
    data[index].net_discount = newValue ? getNewNetDiscount(price, newValue) : "";

    onChange({target: {name: "coupons", value: data }});
  };

  const handleTierSelection = (event, value, reason, index) => {

    let data = [...coupons];

    data[index].tier_id = value?.id || "";
    data[index].tier_title = value?.title || "";

    if (reason === "clear") {
      data.splice(index, 1);
    }

    onChange({ target: { name: "coupons", value: data } });
  };

  const addCoupon = (e) => {
    e.preventDefault();
    let data = coupons ? [...coupons] : [];
    data.push({ ...emptyCoupon });
    onChange({target: {name: "coupons", value: data }});
  };

  return <>

    {coupons && coupons.map((coupon, index) => {
      return (
          <div className="row" key={`coupon-${index}`} >
            <TierSelector
              tier_title={coupon.tier_title}
              tiers={tiers}
              coupons={coupons}
              index={index}
              onTierChange={(e, newValue, reason) => handleTierSelection(e, newValue, reason, index)} />

            <NumberTextField
              fullWidth
              size='small'
              name="percent_off"
              label="Percentage Off"
              color="warning"
              value={coupon.percent_off}
              onChange={event => handleCouponChange(event, index)} 
              InputProps={{
                  endAdornment: <>
                    {
                      coupon.net_discount 
                        ? <InputAdornment position="end">{coupon.net_discount} {currencies[currency].symbol}</InputAdornment> 
                        : null
                    }
                  </>,
                }}
              />
          </div>
      );
    })}

    <Button text small icon={AddCircleOutlineOutlinedIcon} onClick={addCoupon} disabled={disabled}>Add Members Discount</Button>
  </>;
};

Coupons.propTypes = {
  price: PropTypes.number,
  currency: PropTypes.string,
  coupons: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default Coupons;
