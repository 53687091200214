import { convertPriceFromCents, currencySymbol } from 'utils/rewards';
import { t } from 'i18n/index';

export const getMinPrice = (tickets) => {
  if (tickets.length === 0) return t("literal:freeEntrance");

  let min = tickets[0].display_price;
  let currency = tickets[0].currency;
  tickets.forEach((ticket) => {
    if (ticket.display_price < min) {
      min      = ticket.display_price;
      currency = ticket.currency;
    }
  });

  const currencySign = currencySymbol[currency];

  const shownPrice = min && currencySign ? `${convertPriceFromCents(min)} ${currencySign}` : t("literal:freeEntrance");

  return shownPrice;
};

export const handleEventRedirect = (event, organizationSlug, history) => {
  const { external_url, slug } = event;
  const isInternal = external_url.startsWith(`${window.location.origin}/${organizationSlug}`);

  isInternal
    ? history.push(`/${organizationSlug}/events/${slug}`)
    : window.open(external_url, "_blank");
};
