import React, { useState } from "react";
import { useController } from "@rest-hooks/react";
import PropTypes from "prop-types";

import TicketResource from "resources/user/TicketResource";

import handleValidation from "./handleValidation";

import Button from "ui/Button";
import FormSection from "ui/FormSection";
import Description from "ui/text/Description";

import { useError } from "utils/useErrorController";
import { useToast } from "utils/context/ToastContext";

import { Alert, TextField } from "@mui/material";

import { t } from "i18n";

const TicketDetailsForm = ({ uuid, onDone }) => {

  const [newUser, setNewUser] = useState({name: '', email: ''});

  const {loading, setLoading, handleError, validate} = useError();
  const {fetch} = useController();
  const { showToast } = useToast();

  const handleSubmit = async () => {
    setLoading(true);
    try {
      validate(newUser, handleValidation);
      await fetch(TicketResource.update(), {id: uuid}, {...newUser});
      await fetch(TicketResource.list(), {upcoming: true});
      showToast("success", t("student:tickets:sentSuccessfully"));
      setLoading(false);
      onDone();
    } catch (error) {
      handleError(error);
    }
  };

  const handleChange = ({target: {name, value}}) => {
    setNewUser(prevUser => ({...prevUser, [name]: value}));
  };

  return (
    <FormSection slim>
      <Description>{t("student:tickets:detailsFormDescription")}</Description>
      <TextField
        label={t("literal:name")}
        value={newUser.name}
        name="name"
        onChange={handleChange}
        color="warning"
        small
      />
      <TextField
        label={t("literal:email")}
        value={newUser.email}
        name="email"
        onChange={handleChange}
        color="warning"
        small
      />
      <Alert severity="warning">{t("student:tickets:warning")}</Alert>
      <Button fullwidth highlighted onClick={handleSubmit} loading={loading}>{t("literal:save")}</Button>
    </FormSection>
  );
};

TicketDetailsForm.propTypes = {
  uuid: PropTypes.string,
  onDone: PropTypes.func,
};

export default TicketDetailsForm;
