import React from "react";
import PropTypes from "prop-types";

import Button from "ui/Button";

import { Menu, MenuItem } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TitleIcon from "@mui/icons-material/Title";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';

const Header = ({ orgHasCalendar, handleCreateRequest, loading }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const options = [
    {
      label: "Add Header",
      onClick: () => handleCreateRequest("title"),
      icon: TitleIcon,
      condition: true,
    },
    {
      label: "Add Text",
      onClick: () => handleCreateRequest("text"),
      icon: ViewHeadlineIcon,
      condition: true,
    },
    {
      label: "Add Calendar",
      onClick: () => handleCreateRequest("calendar"),
      icon: CalendarMonthIcon,
      condition: !orgHasCalendar,
    },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (onClick) => {
    handleClose();
    onClick();
  };

  return (
    <div className="button-group">
      <Button
        icon={AddCircleOutlineIcon}
        highlighted
        fullwidth
        onClick={() => handleCreateRequest("link")}
        loading={loading}
      >
        Add Link
      </Button>

      <Button secondary fullwidth onClick={handleClick} loading={loading}>
        Add More Elements
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: anchorEl ? anchorEl.offsetWidth : null,
          },
        }}
      >
        {options.map((option) => (
          option.condition && (
            <MenuItem
              key={option.label}
              onClick={() => handleMenuItemClick(option.onClick)}
            >
              <option.icon sx={{ mr: 2 }} />
              {option.label}
            </MenuItem>
          )
        ))}
      </Menu>
    </div>
  );
};

Header.propTypes = {
  orgHasCalendar: PropTypes.bool.isRequired,
  handleCreateRequest: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default Header;
