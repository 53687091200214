import React, { useEffect, useState } from 'react';
import { useController } from '@rest-hooks/react';
import PropTypes from 'prop-types';

import OrderResource from 'resources/user/OrderResource';

import AmountModal from './AmountModal';
import PaymentModal from './PaymentModal';

import isMobileDevice from 'utils/mobileAndTabletCheck';
import { useError } from 'utils/useErrorController';
import { checkLoginStatus } from 'utils/checkLoginStatus';
import { calculateProductMaxAmount } from 'utils/calculateProductMaxAmount';
import RepeatPurchaseModal from './RepeatPurchaseModal';

const CheckoutModal = ({ 
  checkoutModals,
  checkoutVars,
  product,
  onAuthModalOpen,
  onCheckoutModalsChange,
  onCheckoutVarsChange,
  onClose,
  onProductChange,
 }) => {

  const [openRepeatPurchaseModal, setOpenRepeatPurchaseModal] = useState(false);

  const setStep = (step) => {
    onCheckoutVarsChange({ ...checkoutVars, step });
  };

  const { amountModal, paymentModal } = checkoutModals;
    
  const openPaymentModal = () => {
    setOpenRepeatPurchaseModal(false);
    onCheckoutModalsChange({ ...checkoutModals, amountModal: false, paymentModal: true });
  };

  const closeRepeatPurchaseModal = () => {
    setOpenRepeatPurchaseModal(false);
    onClose();
  };

  const { fetch } = useController();
  const { handleError } = useError();

  const handlePurchase = async () => {
    //check login status here and redirect to authModal if not logged in
    const isLoggedIn = checkLoginStatus();
    if (isLoggedIn) {
      try {
        const orders = await fetch(OrderResource.list(), {last_hour: true, object_id: product.id});
        if (orders.length > 0) {
          setOpenRepeatPurchaseModal(true);
        } else {
          openPaymentModal();
        }
      } catch (error) {
        handleError(error);
      }

    } else {
      onAuthModalOpen();
    }
  };

  const handlePurchaseRequest = () => {
    const { redeem_limit, redeemed_count, issued_counter, supply_limit } = product;
        
    const newMaxAmount = calculateProductMaxAmount(redeem_limit, supply_limit, issued_counter, redeemed_count);

    onCheckoutVarsChange({ ...checkoutVars, maxAmount: newMaxAmount });
    onCheckoutModalsChange({ ...checkoutModals, amountModal: true });
  };
    
  useEffect(() => {
    handlePurchaseRequest();
  }, []);
    
  const isMobile = isMobileDevice();
  const showLargeModal = !isMobile && checkoutVars.step === 1;
  const modalWidth = showLargeModal ? "md" : "sm";

  return (
    <>
      <AmountModal
        open={ amountModal }
        onClose={ onClose }
        modalWidth={ modalWidth }
        object={ product }
        onObjectChange={ onProductChange }
        checkoutVars={ checkoutVars }
        onCheckoutVarsChange={ onCheckoutVarsChange }
        done={ handlePurchase } />

      <PaymentModal
        pricingId={ product?.pricing_id }
        object={ product }
        open={ paymentModal }
        step={ checkoutVars.step }
        promoCode={ checkoutVars.promoCode }
        setStep={ setStep }
        handleModalsClose={ onClose }
        stripe_purchase={ true }
        modalWidth={ modalWidth }
        amount={ checkoutVars.amount } />

      <RepeatPurchaseModal
        open={ openRepeatPurchaseModal }
        onConfirm={ openPaymentModal }
        onClose={ closeRepeatPurchaseModal }
        objectCategory={ product.category }
        objectRedeemedCount={ product.redeemed_count } />
     
    </>
  );
};

CheckoutModal.propTypes = {
  checkoutModals: PropTypes.object,
  checkoutVars: PropTypes.object,
  product: PropTypes.object,
  onAuthModalOpen: PropTypes.func,
  onCheckoutModalsChange: PropTypes.func,
  onCheckoutVarsChange: PropTypes.func,
  onClose: PropTypes.func,
  onProductChange: PropTypes.func,
};

export default CheckoutModal;