import React, { useEffect } from 'react';
import { useController } from '@rest-hooks/react';

import OrderResource from 'resources/admin/OrderResource';
import OrderDownloadResource from 'resources/admin/OrderDownloadResource';

import TabPanels from 'components/TabPanels';
import Layout from 'components/layouts/index';
import DataSelectionTable from 'components/DataTable/DataSelectionTable';
import OrderStats from 'components/OrderStats';

import OrderFilter from './OrderFilter';

import { adminOrdersColumns } from 'utils/tables/columns/admin/ordersColumns';
import usePaginationAndFilteringController from 'utils/usePaginationAndFilteringController';
import { downloadCSV } from 'utils/downloadFile';
import { useError } from 'utils/useErrorController';
import useOrderDetailsModal from 'utils/useOrderDetailsModal';

const pageInfo = {
  label: "Admin",
  name: "Orders",
};

const AdminOrders = () => {

  const {handleError} = useError();
  const {handleOpenOrderDetails, OrderDetailsModalComponent} = useOrderDetailsModal();

  const {
    objectState, 
    fetchData,
    handlePageChange,
    handleFilterChange
  } = usePaginationAndFilteringController({
      fetchDataResource: OrderResource,
      fetchDataParams: {},
  });

  useEffect(() => {
    fetchData(1, {status: "completed"});
  }, []);

  const {fetch} = useController();

  const handleDownload = async () => {
    try {
      const response = await fetch(OrderDownloadResource.list(), objectState.filteringParams);
      downloadCSV(response, 'admin_orders.csv', 'text/csv');
    } catch (error) {
      handleError(error.message);
    }
  };

  return (
    <Layout context='admin'
            pageInfo={ pageInfo }
            activeMenuItem='orders' >

      <TabPanels labels={[ "Orders" ]} >
            <div>
              <OrderStats orders={objectState.filteredObjects} />
              <DataSelectionTable records={objectState.filteredObjects}
                                  columns={adminOrdersColumns(handleOpenOrderDetails)}
                                  serverPaginationProps={{
                                    paginationServer: true,
                                    paginationTotalRows: objectState.totalCount,
                                    page: objectState.page,
                                    onChangePage: handlePageChange
                                  }}
                                  onClick={handleDownload}
                                  filter={
                                    <OrderFilter
                                      onChange={handleFilterChange}
                                    />
                                  }
              />
            </div>
      </TabPanels>
      
      <OrderDetailsModalComponent />

    </Layout>
)};

export default AdminOrders;
