import { useState } from "react";

import { useError } from "./useErrorController";

const useEupago = ({
  onOrderChange,
  onUserPhoneChange
}) => {
  const [processingEupago, setProcessingEupago] = useState(false);

  const { setLoading, handleError } = useError();

  const handleEupagoPaymentIntent = async (paymentIntentId, redirectUrl) => {
    const newOrderState = {paymentIntentId: paymentIntentId, redirectUrl: redirectUrl};
    onOrderChange((previousValue) => ({...previousValue, ...newOrderState}));

    setProcessingEupago(paymentIntentId);
    setLoading(false);

    if (redirectUrl) window.open(redirectUrl, '_blank');
  };

  const handleErrorFindingOrderResult = (error) => {
    setProcessingEupago(false);
    onUserPhoneChange('');
    handleError(error);
  };

  return {
    processingEupago,
    setProcessingEupago,
    handleEupagoPaymentIntent,
    handleErrorFindingOrderResult
  };
};

export default useEupago;