import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';

import ViewMore from './ViewMore';
import RedeemButton from './RedeemButton';
import BuyButton from './BuyButton';

import Label from 'ui/text/Label';

import { useError } from 'utils/useErrorController';
import { convertPriceFromCents, currencySymbol } from 'utils/rewards';
import { offerCategory } from 'utils/offersFormHelper';
import formattedDate from 'utils/formattedDate';

import { t } from "i18n/index";
import moment from 'moment';
import { Link } from '@mui/material';

const ButtonGroup = ({ product, onAction, onClick}) => {

  const { organizationSlug } = useParams();
  const { points = 0, available, start_date, redeemed_count, display_price, currency, display_discount_price, redeem_limit, category, higher_discount_percentage, discount_percentage} = product;

  const showDescription = redeemed_count > 0;
  const showRedeemButton = !display_price || points > 0;
  const redeemedMax = redeem_limit && redeemed_count >= redeem_limit
  const disableRedeemButton = redeemedMax || !available;
  const disableBuyButton = redeemedMax || !available;
  const showBuyButton = display_price && currency;
  const isTicket = category === offerCategory.ticket.value;
  const showHigherPercentage = higher_discount_percentage && !discount_percentage;

  const convertedPrice = convertPriceFromCents(display_price);
  const priceWithCurrency = `${parseFloat(convertedPrice)} ${currencySymbol[currency]}`;

  const convertedDiscountPrice = display_discount_price && convertPriceFromCents(display_discount_price);
  const discountPriceWithCurrency = display_discount_price && `${parseFloat(convertedDiscountPrice)} ${currencySymbol[currency]}`;

  const upcoming = start_date && moment(start_date).isAfter(moment());
  const timeToStart = upcoming && formattedDate(start_date);

  const {loading} = useError();

  const history = useHistory();

  return (
    <div className='button-group'>
      {onClick && <ViewMore handleRedirect={onClick}/>}

      {showRedeemButton &&
        <RedeemButton
          onAction={() => onAction(product, 'points')}
          loading={loading}
          disableRedeemButton={disableRedeemButton}
          points={points}
          available={available}
          timeToStart={timeToStart}
          isTicket={isTicket}
        />
      }

      {showBuyButton &&
        <BuyButton
          onAction={() => onAction(product, priceWithCurrency)}
          loading={loading}
          disableBuyButton={disableBuyButton}
          priceWithCurrency={priceWithCurrency}
          discountPriceWithCurrency={discountPriceWithCurrency}
          available={available}
          timeToStart={timeToStart}
          isTicket={isTicket}
        />
      }

      {showHigherPercentage &&
        <>
          <Label secondary small={isTicket}>
            {t('profile:perks:discountPerkMessage', {higher_discount_percentage})}
          </Label>
          <Label secondary small={isTicket}>
            <Link component="button" onClick={() => history.push(`/${organizationSlug}/memberships`)} underline="hover" color="inherit" >{t('literal:becomeAMember')} {t('literal:here')}.</Link>
          </Label>
        </>
      }

      {discount_percentage ?
        <Label secondary small={isTicket}>
            {t('profile:perks:memberDiscountPerkMessage', {discount_percentage})}
        </Label> : ""
      }

      {showDescription &&
        <Label secondary small={isTicket} >
          {redeemedMax ? t('errors:redeemedMax') : `${t('profile:perks:redeemedCountMessage')} ${redeemed_count > 1 ? redeemed_count + " " + t('literal:times_sc') : t('literal:once_sc')}`}
        </Label>
      }
    </div>
  );
};

ButtonGroup.propTypes = {
    product: PropTypes.object,
    onAction: PropTypes.func,
    onClick: PropTypes.func,
};

export default ButtonGroup;
