import React from 'react';
import PropTypes from 'prop-types';
import Form from './form';

import { Elements } from "@stripe/react-stripe-js";
import LoadingComponent from 'components/CheckoutModal/PaymentModal/LoadingComponent';


/** COMPONENT **/
const StripePaymentForm = ({
    object,
    orderPropertiesState,
    onStepChange,
    onContentChange,
    stripeState,
    onShowTickets,
    pricingId,
    stripe
  }) => {

  const { clientSecret, stripePublishableKey } = stripeState;

  const appearance = {
    theme: getComputedStyle(document.documentElement).getPropertyValue('--stripeTheme'),
    variables: { 
      colorPrimary: getComputedStyle(document.documentElement).getPropertyValue('--highlightColor')
    },
  };
  
  if (!clientSecret || !stripePublishableKey || !stripe) return (
    <LoadingComponent />
  );

  const options = { clientSecret, appearance, loader: 'never' };

  return (
    <Elements options={options} stripe={stripe}>
      <Form
        object={object}
        orderPropertiesState={orderPropertiesState}
        onStepChange={onStepChange}
        onContentChange={onContentChange}
        onShowTickets={onShowTickets}
        pricingId={pricingId}
      />
    </Elements>
  );
};

StripePaymentForm.propTypes = {
  object: PropTypes.object,
  orderPropertiesState: PropTypes.object,
  onStepChange: PropTypes.func,
  onContentChange: PropTypes.func,
  stripeState: PropTypes.object,
  onShowTickets: PropTypes.func,
  pricingId: PropTypes.number,
  stripe: PropTypes.object
};

export default StripePaymentForm;
