import React from "react";
import PropTypes from "prop-types";

import { Stack, Typography, Switch } from "@mui/material";

const SettingSwitcher = ({value, onChange, label, name}) => {
  const handleChange = ({target: {checked}}) => {
    onChange({target: {name, value: !checked }});
  };

  return(
    <Stack direction="row" spacing={0.5} alignItems="center">
      <Switch color="warning"
          checked={!value}
          onChange={handleChange} />
      <Typography>{label}</Typography>
    </Stack>
  );
};

SettingSwitcher.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default SettingSwitcher;
