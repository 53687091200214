import React from "react";
import PropTypes from "prop-types";

import { DialogTitle } from "@mui/material";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Title = ({ onClose }) => {
  return (
    <DialogTitle>
      <IconButton onClick={onClose} aria-label="close">
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};

Title.propTypes = {
  onClose: PropTypes.func,
};

export default Title;
