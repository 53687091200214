import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';

import TollIcon from '@mui/icons-material/Toll';

import { t } from "i18n/index";
import Label from 'ui/text/Label';

const RedeemButton = ({ onAction, loading, disableRedeemButton, points, available, timeToStart, isTicket}) => {

  const showPoints = points > 0 && !isTicket;

  return (
    <>
      <Button onClick={ onAction } smallPadded={isTicket} fullwidth loading={loading} disabled={disableRedeemButton} highlighted>
        {available 
          ? <div className={`button-content ${!showPoints ? 'centered' : ''}`} >

              {showPoints && <div><TollIcon color='warning'/> {points}</div>}

              {isTicket 
                ? <span>RSVP {t('literal:now')}</span> 
                : <span>{t('literal:redeem')}</span>}
                
            </div>
          : timeToStart
            ? <span>{t('literal:availableIn', {timeToStart})}</span>
            : <span>{t('literal:soldOut')}</span>}
      </Button>
      {isTicket && <Label secondary small>{t('literal:freeEntrance')}</Label>}
    </>
  );
};

RedeemButton.propTypes = {
  onAction: PropTypes.func,
  loading: PropTypes.bool,
  disableRedeemButton: PropTypes.bool,
  points: PropTypes.number,
  available: PropTypes.bool,
  timeToStart: PropTypes.string,
  isTicket: PropTypes.bool
};

export default RedeemButton;