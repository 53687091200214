import React from 'react';
import { useParams } from 'react-router-dom';
import { useSuspense } from '@rest-hooks/react';

import ProductResource from 'resources/organization/ProductResource';

import Layout from 'components/layouts';

import Form from './form'
import { offerCategory } from 'utils/offersFormHelper';

const pageInfo = {
  name: "Edit product"
};

const UpdateOffer = () => {

  const params = useParams();
  const organizationId = parseInt(params.organizationId);
  const offerId = parseInt(params.offerId);
  
  const offer = useSuspense(ProductResource.detail(), { id: offerId, organization_id: organizationId });

  const isTicket = offer.category === offerCategory.ticket.value;
  pageInfo.name = `Edit ${isTicket ? "ticket" : "product"}`

  return (
    <Layout context='teacher'
            back={ true }
            pageInfo={ pageInfo }
            activeMenuItem={isTicket ? "events" : "perks"}>

      <Form record={ offer } />

    </Layout>
  );
};

export default UpdateOffer;
