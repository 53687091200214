import React from "react";
import PropTypes from "prop-types";

import Label from "ui/text/Label";

import { TextField } from "@mui/material";

const NotificationList = ({ notification_list, onChange, context }) => {
  
  const label = "Notification List";
  const helperText = 
    `Enter one or more valid email addresses, separated by commas${
      context === "productForm"
        ? " and we will notify them when a product is purchased."
        : ". We will use these to send all billing related notifications."
    }`;

  return (
    <>
      <TextField
        fullWidth
        size="small"
        color="warning"
        name="notification_list"
        value={notification_list}
        onChange={onChange}
        label={label}
      />

      <Label secondary padded>
        {helperText}
      </Label>
    </>
  );
};

NotificationList.propTypes = {
  notification_list: PropTypes.string,
  onChange: PropTypes.func,
  context: PropTypes.string,
};

export default NotificationList;
