import React from "react";
import PropTypes from "prop-types";

import StatCard from "ui/StatCard/StatCard";
import { formatPrice } from "utils/rewards";

const Stats = ({ events }) => {
  const event = events?.[0];

  const stat1 = event ? formatPrice(event.total_revenue, event.currency) : 0;

  const stat2 = event ? event.total_tickets_count : 0;

  const stat3 = event ? event.total_count : 0;

  return ( 
    <div className="ui-stats-grid">
      <StatCard
        type="number"
        highlight={stat1}
        title={`Total Revenue`}
      />
      <StatCard
        type="number"
        highlight={stat2}
        title="Issued Tickets"
      />
      <StatCard
        type="number"
        highlight={stat3}
        title="Events Count"
      />
    </div>
  );
};

Stats.propTypes = {
  events: PropTypes.array,
};

export default Stats;
