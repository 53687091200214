import React, { useEffect, useState } from 'react';

import OrganizationResource from 'resources/admin/OrganizationResource';

import CreateOrganizationModal from './create/CreateOrganizationModal';
import EditOrganizationModal from './edit/EditOrganizationModal';
import OrganizationFilter from './OrganizationFilter';
import OrganizationStats from './OrganizationStats';

import Layout from 'components/layouts/index';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TabPanels from 'components/TabPanels';
import DataSelectionTable from 'components/DataTable/DataSelectionTable';

import Button from 'ui/Button';

import { organizationColumns } from 'utils/tables/columns/admin/organizationsColumns';
import usePaginationAndFilteringController from 'utils/usePaginationAndFilteringController';
import downloadCSV from 'utils/downloadCSV';

const pageInfo = {
  label: "Admin",
  name: "Organizations",
  action: true
};

const AdminUsers = () => {

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [activeOrg, setActiveOrg] = useState({name:"", id: "", modules: []});

  const {
    objectState, 
    fetchData,
    handlePageChange,
    handleFilterChange,
    handleQueryChange,
  } = usePaginationAndFilteringController({
      fetchDataResource: OrganizationResource,
      fetchDataParams: {},
  });

  useEffect(() => {
    fetchData(1, {status: "live"});
  }, []);
  
  const handleOpenCreateModal = () => setOpenCreateModal(true);

  const handleOpenEditModal = () => setOpenEditModal(true);

  const handleCloseModals = () => {
    setOpenCreateModal(false);
    setOpenEditModal(false);
    fetchData();
  };

  const handleDownload = async () => {
    await downloadCSV('/admin/organizations', objectState.filteringParams);
  };

  return (
    <Layout context='admin'
            activeMenuItem={'organizations'}
            pageInfo={ pageInfo }
            cta={
              <Button highlighted icon={AddCircleOutlineIcon} onClick={handleOpenCreateModal}>
                Add Organization
              </Button>
            } >

      <CreateOrganizationModal open={ openCreateModal }
                          onClose={ handleCloseModals }
                          />

      <EditOrganizationModal open={ openEditModal }
                          onClose={ handleCloseModals }
                          activeOrg={ activeOrg } 
                          handleActiveOrg ={ setActiveOrg }
                          /> 
    
      <TabPanels labels={[ "Organizations" ]} >
      <>
        <OrganizationStats total_count={objectState.totalCount} status={objectState?.filteringParams?.status} />
        <DataSelectionTable records={objectState.filteredObjects}
                            columns={organizationColumns(setActiveOrg, handleOpenEditModal)}
                            onClick={handleDownload}
                            onQueryChange={handleQueryChange}
                            searchLabel="Search Organization"
                            queryString={objectState.query}
                            serverPaginationProps={{
                                paginationServer: true,
                                paginationTotalRows: objectState.totalCount,
                                page: objectState.page,
                                onChangePage: handlePageChange
                              }}
                            filter={<OrganizationFilter onChange={handleFilterChange} />}
        />
      </>
      </TabPanels>

    </Layout>
)};

export default AdminUsers;