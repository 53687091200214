import React, { useState }  from 'react';
import PropTypes from 'prop-types';
import { useController } from '@rest-hooks/react';

import OrderResource from 'resources/user/OrderResource';
import InvoiceResource from 'resources/user/InvoiceResource';

import CustomModal from 'components/CustomModal';
import InvoiceForm from 'components/InvoiceForm';

import Button from 'ui/Button';

import formattedDate from 'utils/formattedDate';
import { convertPriceFromCents, currencySymbol } from 'utils/rewards';
import { useError } from 'utils/useErrorController';

import { t } from 'i18n/index';

const OrderCardContent = ({item}) => {

  const [customModalContent, setCustomModalContent] = useState(null);

  const {invoice, created_at, amount, total_value, currency, reference, invoice_allowed} = item;
  const {loading, handleError} = useError();
  const {fetch} = useController();

  const fetchData = async () => {
    try {
      await fetch(OrderResource.list());
      setCustomModalContent(null);
    } catch (error) {
      handleError(error);
    }
  };

  const handleOpenInvoiceModal = () => {
    setCustomModalContent({
      title: t('literal:billingDetails'),
      content: (
        <>
          <p>{t('profile:memberships:claimModal:invoiceFormDescription')}</p>
          <InvoiceForm 
            record={ invoice }
            InvoiceResource={ InvoiceResource }
            paymentIntentId={ reference }
            onDone={fetchData}
          />
        </>
      ),
    });
  };

  return (
    <>
      <div className='fullwidth-card-content'>
        <>
          <p>{`${t('literal:createdAt')}: ${formattedDate(created_at, "datetime")}`}</p>
          <p>{`${t('literal:quantity')}: ${amount}`}</p>
          <p>{`${t('literal:paid')}: ${convertPriceFromCents(total_value)} ${currencySymbol[currency]}`}</p>
        </>
        {invoice_allowed &&
          <Button
            loading={loading}
            secondary
            fullwidth
            onClick={ handleOpenInvoiceModal }
            >
            {invoice.id 
              ? t('literal:requestInvoiceChanges') 
              : t('literal:requestInvoice') }
          </Button>}
      </div>
      {customModalContent && (
        <CustomModal
          handleClose={() => setCustomModalContent(null)}
          customModalContent={customModalContent}
        />
      )}
    </>
  );
};

OrderCardContent.propTypes = {
    item: PropTypes.object,
};

export default OrderCardContent;
