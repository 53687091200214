import React from 'react';

import Alert from 'ui/Alert';

const AccountingAlert = () => {
  return (
    <Alert primaryText={
      <span>
        <span className="bold">Important:</span> For accounting information, including orders, fees, and billing, visit the Orders tab.
      </span>
    } />
  );
};

export default AccountingAlert;