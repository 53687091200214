import React from "react";
import PropTypes from "prop-types";

import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import CloseIcon from "@mui/icons-material/Close";

import { t } from "i18n/index";
import StyledDialog from "./StyledComponents/StyledDialog";

const ErrorModal = ({ open, message, onClose, button }) => {
  const renderMessage = () => {
    if (message.length > 1) {
      return (
        <ul>
          {message.map((msg, index) => (
            <li key={index}>{msg}</li>
          ))}
        </ul>
      );
    }
    return message;
  };

  return (
    <StyledDialog open={open} fullWidth={true} maxWidth="sm">
      <DialogTitle>
        <ErrorIcon
          sx={{
            color: "red",
            verticalAlign: "middle",
            paddingRight: "2%",
            width: "36px",
          }}
        />
        {t("literal:error")}
        {onClose && (
          <IconButton aria-label="close" onClick={onClose} er>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent dividers>
        {message && renderMessage()}
        {button}
      </DialogContent>
    </StyledDialog>
  );
};

ErrorModal.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.array,
  onClose: PropTypes.func,
  button: PropTypes.node,
};

export default ErrorModal;
