import { validateEmail } from "utils/validations/validateEmail";
import { validateStringField } from "utils/validations/validateStringField";

const handleValidation = (newUser) => {
    const {email, name} = newUser;

    validateStringField(name, "a valid name");
    validateEmail(email, "email");
};

export default handleValidation;