import React from "react";
import PropTypes from "prop-types";

import Button from "ui/Button";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import UndoIcon from "@mui/icons-material/Undo";
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';

const CheckInButton = ({ 
  uuid, 
  isCheckedIn, 
  orderAmount,
  checkInAll,
  allowCheckout, 
  onCheckIn 
}) => {

  const buttonTypes = {
    checkIn: {
      label: "Check In",
      icon: CheckCircleOutlineIcon,
      action: () => onCheckIn(uuid, false)
    },
    checkOut: {
      label: "Check Out",
      icon: UndoIcon,
      action: () => onCheckIn(uuid, false, "check_out")
    },
    checkInGroup: {
      label: `Check In ${orderAmount} Tickets`,
      icon: GroupIcon,
      action: () => onCheckIn(uuid, false, "check_in", orderAmount)
    },
    checkInIndividual: {
      label: "Check In 1 Ticket",
      icon: PersonIcon,
      action: () => onCheckIn(uuid, false)
    }
  };

  const getButtonConfig = () => {
    if (isCheckedIn && allowCheckout) return buttonTypes.checkOut;
    if (orderAmount > 1 && !isCheckedIn) {
      return checkInAll ? buttonTypes.checkInGroup : buttonTypes.checkInIndividual;
    }    
    return buttonTypes.checkIn;
  };

  const config = getButtonConfig();

  return (
    <Button
      highlighted
      icon={config.icon}
      onClick={config.action}
      fullwidth
      disabled={isCheckedIn && !allowCheckout}
    >
      {config.label}
    </Button>
  );
};

CheckInButton.propTypes = {
  uuid: PropTypes.string,
  isCheckedIn: PropTypes.bool,
  orderAmount: PropTypes.number,
  checkInAll: PropTypes.bool,
  allowCheckout: PropTypes.bool,
  onCheckIn: PropTypes.func,
};

export default CheckInButton; 