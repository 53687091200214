import { darkTheme, defaultTheme } from "utils/themes";

const createThemeFormData = (data) => {
  const formData = new FormData();

  const {
    // font_family,
    // primary_text_color,
    // secondary_text_color,
    highlight_color = '#F75201', 
    // primary_button_color,
    // secondary_button_color,
    // button_text_color,
    // primary_background_color,
    // secondary_background_color,
    // border_color,
    // stripe_theme,
    custom_cta, 
    custom_cta_action,
    theme
  } = data;

  const selectedTheme = theme === 'dark' ? darkTheme : defaultTheme;
  
  const { 
    font_family,
    primary_text_color, 
    secondary_text_color,
    primary_button_color,
    secondary_button_color,
    button_text_color,
    primary_background_color,
    secondary_background_color,
    border_color,
    stripe_theme 
  } = selectedTheme;

  const fields = [
    { key: "font_family", value: font_family },
    { key: "primary_text_color", value: primary_text_color },
    { key: "secondary_text_color", value: secondary_text_color },
    { key: "highlight_color", value: highlight_color },
    { key: "primary_button_color", value: primary_button_color },
    { key: "secondary_button_color", value: secondary_button_color },
    { key: "button_text_color", value: button_text_color },
    { key: "primary_background_color", value: primary_background_color },
    { key: "secondary_background_color", value: secondary_background_color },
    { key: "border_color", value: border_color },
    { key: "stripe_theme", value: stripe_theme },
  ];

  if (custom_cta) {
    fields.push({ key: "custom_cta", value: custom_cta });
  }

  if (custom_cta_action) {
    fields.push({ key: "custom_cta_action", value: custom_cta_action });
  }

  fields.forEach((field) => {
    formData.append(field.key, field.value);
  });
  return formData;
};

export default createThemeFormData;
