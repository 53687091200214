import React from 'react';

import { useToast } from 'utils/context/ToastContext';

import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomSnackbar = () => {

  const { showSnackbar, severity, message, hideToast } = useToast();

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'right'}} open={showSnackbar} autoHideDuration={6000} onClose={hideToast}>
        <Alert severity={severity} sx={{ width: '100%' }} onClose={hideToast} onClick={hideToast}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default CustomSnackbar;