import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const Image = ({ image, rounded, border, height, className, primaryBackgroundColor }) => {

  const classes = ['ui-image']
  if (rounded) classes.push('rounded')
  if (border) classes.push('border')
  if (className) classes.push(className)

  const style = {
    backgroundImage: `url(${image})`,
    height: height,
    width: rounded ? height : "100%",
  };

  if (primaryBackgroundColor) {
    style.borderColor = primaryBackgroundColor;
  }

  return (
    <div className={classes.join(' ')}
         style={style}
    />
  );
};

Image.propTypes = {
    image: PropTypes.string,
    rounded: PropTypes.bool,
    border: PropTypes.bool,
    height: PropTypes.string,
    className: PropTypes.string,
    primaryBackgroundColor: PropTypes.string
};

export default Image;