
import { ordersColumns } from 'utils/tables/columns/ordersColumns';

export const adminOrdersColumns = (onClick) => {
  
  const columns = ordersColumns(onClick);

  return (
    [
      ...columns.slice(0, 2),
      {
        field: "organization_title",
        name: "Organization",
        selector: (row) => row.organization_title,
        sortable: true,
        width: "15%",
      },
      ...columns.slice(2, columns.length)
    ]
  );
};
