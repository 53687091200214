import React from 'react';
import PropTypes from 'prop-types';

import StatCard from 'ui/StatCard/StatCard';
import { formatPrice } from 'utils/rewards';

const Stats = ({ tickets, events_count }) => {
    const ticket = tickets?.[0];
    
    const stat1 = ticket ? ticket.total_count : 0;

    const stat2 = ticket ? ticket.sold_count : 0;

    const stat3 = ticket ? formatPrice(ticket.total_revenue, ticket.currency) : 0;

    return (
        <div className="ui-stats-grid">

            <StatCard type='number'
                highlight={ stat1 }
                title='Issued Tickets'  />

            <StatCard type='number'
                highlight={ stat2 }
                title='Sold Tickets'  />

            <StatCard type='number'
                highlight={stat3}
                title='Total Revenue' />

            <StatCard type='number'
                highlight={ events_count }
                title='Total Events' />

        </div>
    )};

Stats.propTypes = {
    tickets: PropTypes.array,
    events_count: PropTypes.number
};

export default Stats;
