import React, { useState } from "react";
import PropTypes from "prop-types";

import SelectMenu from "./SelectMenu";
import SlimInput from "./SlimInput";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

const LockSelector = ({ 
    passes, 
    title="Visible to:", 
    reward_ids, 
    onChange, 
    isDisabled=false, 
    showPrivate=true,
    slim=false
  }) => {

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  
  return (
    <>
      {slim && <SlimInput title={title} reward_ids={reward_ids} onClick={handleClick} />}
      <FormControl sx={{ width: "100%", display: slim ? "none" : "block"}}>
        <InputLabel color="warning">{title}</InputLabel>
        <SelectMenu 
          isDisabled={isDisabled}
          open={open}
          showPrivate={showPrivate}
          slim={slim}
          title={title}
          anchorEl={anchorEl}
          passes={passes}
          reward_ids={reward_ids}
          onChange={onChange}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
        />
      </FormControl>
    </>
  );
};

LockSelector.propTypes = {
  passes: PropTypes.array,
  title: PropTypes.string,
  reward_ids: PropTypes.array,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  showPrivate: PropTypes.bool,
  slim: PropTypes.bool
};

export default LockSelector;
