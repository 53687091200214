import { validateEmail } from "utils/validations/validateEmail";

export const handleValidation = (recipients, ticketId) => {
  validateRecipients(recipients);
  validateTicket(ticketId);
};

const validateRecipients = (recipients) => {
  if (recipients.length === 0) throw "Please add at least one email";

  const seen = new Set();
  recipients.forEach(recipient => {
    const { email } = recipient;
    validateEmail(email, "email")
    if (seen.has(email)) throw `Detected duplicated email: ${email}`;
    seen.add(email);
  });
};

const validateTicket = (ticketId) => {
  if (!ticketId) throw "Please select a ticket";
};