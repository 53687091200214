import React from "react";
import PropTypes from "prop-types";

import Footer from "../Footer";
import EupagoPending from "./EupagoPending";

import OrderSummary from "components/OrderSummary";
import StyledTextField from "components/StyledComponents/StyledTextField";

import mbwayIconDefault from "assets/images/mbwayIcon.png";
import mbwayIconInverted from "assets/images/mbwayIcon-inverted.png";

import { t } from "i18n/index";
import BizumPending from "./BizumPending";

const EupagoPaymentForm = ({
  object,
  orderPropertiesState,
  pricingId,
  userPhone,
  onUserPhoneChange,
  requestedAmount,
  onSubmit,
  processingEupago,
  isBizumOrganization,
  redirectUrl
}) => {

  const { title, pricings, image_url, organization, application_fee, discount_price, net_discount, session_start_date, category } = object;
  const { organization_title, show_application_fee, currency } = organization;

  const primaryTextColor = getComputedStyle(document.documentElement).getPropertyValue('--primaryTextColor');
  const darkTheme = primaryTextColor === 'white';

  const price = pricings.find((pricing) => pricing.id === pricingId).price;

  const payment_method = isBizumOrganization ? "bizum" : "mbway";

  return (
      <form onSubmit={(e) => onSubmit(e, payment_method)} className="stripe-payment-form">
        { !processingEupago ? (
          <>
            {!isBizumOrganization && 
              <StyledTextField
                label={t("literal:phoneNumber")}
                name="userPhone"
                value={userPhone || ""}
                onChange={(event) => onUserPhoneChange(event.target.value)}
                fullWidth
                margin="normal"
                color="warning"
                autoFocus
                inputProps={{
                  maxLength: 9,
                  inputMode: "numeric",
                }}
                InputProps={{
                  endAdornment: <img src={darkTheme ? mbwayIconInverted : mbwayIconDefault} className="payment-method-button-icon" />,
                }}>  
              </StyledTextField>}

            <OrderSummary
              currency={currency}
              unit_price={price}
              discount_price={discount_price}
              net_discount={net_discount}
              quantity={requestedAmount}
              frequency={"lifetime"}
              imageUrl={image_url}
              organizationTitle={organization_title}
              title={title}
              applicationFee={application_fee}
              session_start_date={session_start_date}
              showApplicationFee={show_application_fee}
            />
          </> 
          
          ) : (
            isBizumOrganization ? (
              <BizumPending redirectUrl={redirectUrl} category={category} />
            ) : (
              <EupagoPending category={category} />
            )
          )
        }

        <Footer
          itemPrice={orderPropertiesState.itemPrice}
          object={object}
          caller={payment_method}
          processingEupago={processingEupago}
        />
      </form>
  );
};

EupagoPaymentForm.propTypes = {
  object: PropTypes.object,
  showAlternativeProviderButton: PropTypes.bool,
  orderPropertiesState: PropTypes.object,
  onProviderChange: PropTypes.func,
  pricingId: PropTypes.number,
  userPhone: PropTypes.string,
  onUserPhoneChange: PropTypes.func,
  requestedAmount: PropTypes.number,
  onSubmit: PropTypes.func,
  processingEupago: PropTypes.bool,
  isBizumOrganization: PropTypes.bool,
  redirectUrl: PropTypes.string,
};

export default EupagoPaymentForm;
