import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useController } from '@rest-hooks/react';

import UnsubscribeResource from 'resources/public/UnsubscribeResource';

import ErrorPage from 'components/NetworkError/ErrorPage';

import { useError } from 'utils/useErrorController';
import { useToast } from 'utils/context/ToastContext';

import { t } from 'i18next';


const Unsubscribe = () => {

    const [description, setDescription] = useState('');

    const history = useHistory();

    const { fetch } = useController();
    const { handleError } = useError();
    const { uuid, organization_id } = useParams();
    const { showToast } = useToast();

    useEffect(() => {
      fetchData();
    }, [])

    const fetchData = async () => {
      try {
        const { user_email, organization_title } = await fetch(UnsubscribeResource.detail(), {uuid: uuid, id: organization_id})
        setDescription(t('profile:unsubscribe', {user_email, organization_title}))

      } catch (error) {
          handleError(error);
      }
    };

    const handleClick = async () => {
      try {
          await fetch(UnsubscribeResource.update(), {uuid: uuid, id: organization_id});
          showToast('success', t('profile:unsubscribeSuccess'));
          setTimeout(() => history.push("/"), 2000);
          
      } catch (error) {
          handleError(error);
      }
    };

    const page = {
      title: t('literal:unsubscribe'),
      description: description,
      primaryButtonLabel: t('literal:cancel'),
      secondaryButtonLabel: t('literal:proceed'),
      secondaryButtonAction: handleClick,
    };

    return (
      <ErrorPage page={page} />
  )
};

export default Unsubscribe;