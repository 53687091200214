import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';
import SplitButton from 'ui/SplitButton';

import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import StorefrontIcon from '@mui/icons-material/Storefront';
import EventBusyIcon from '@mui/icons-material/EventBusy';

const Header = ({ onCheckIn, onSellTickets, openEndSalesModal, allowCheckout }) => {

  const buttonOptions = [
    {label: "End Ticket Sales", onClick: () => openEndSalesModal(), icon: <EventBusyIcon />},
  ];

  const MainButton = () =>
    <Button icon={StorefrontIcon} secondary fullwidth onClick={onSellTickets} >
        Register Cash Sale
    </Button>;

  return (
    <div className="tickets-tab-header">
      <Button highlighted onClick={onCheckIn} fullwidth icon={QrCodeScannerIcon}>
        {allowCheckout ? "Scan QR Code" : "Check In"}
      </Button>
      <SplitButton MainButton={MainButton} options={buttonOptions} secondary fullwidth={false}/>
    </div>
  );
};

Header.propTypes = {
  onCheckIn: PropTypes.func,
  onSellTickets: PropTypes.func,
  openEndSalesModal: PropTypes.func,
  allowCheckout: PropTypes.bool,
};

export default Header;
