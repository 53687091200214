import React from "react";
import PropTypes from "prop-types";

import { Tooltip, IconButton } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";

const ActionButtons = ({ note, onSave, onDelete }) => {
  
  const actionButtons = [
    {
      title: "Save",
      onClick: onSave,
      disabled: !note,
      icon: SaveIcon,
      iconColor: !note ? "gray" : "warning",
    },
    {
      title: "Delete",
      onClick: onDelete,
      disabled: !note?.id,
      icon: DeleteIcon,
      iconColor: !note?.id ? "gray" : "warning",
    },
  ];

  return (
    <>
      {actionButtons.map(
        ({ title, onClick, disabled, icon: Icon, iconColor }) => (
          <Tooltip key={title} title={`${title} note`}>
            <span>
              <IconButton onClick={onClick} size="small" disabled={disabled}>
                <Icon color={iconColor} />
              </IconButton>
            </span>
          </Tooltip>
        )
      )}
    </>
  );
};

ActionButtons.propTypes = {
  note: PropTypes.object,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
};

export default ActionButtons;
