import React from "react";
import PropTypes from "prop-types";

import { DialogTitle } from "@mui/material";
import { isEmbeddedElement, isSocialElement } from "utils/pageElementsHelper";
import { capitalize } from "utils/capitalize";

const ELEMENT_TYPE_LABELS = {
  embedded: "Embedded Media",
  social: "Social Media Link",
  title: "Header",
};

const Title = ({ pageElementId, pageElementType }) => {
  const action = pageElementId ? "Edit" : "Add";

  const getLabel = (elementType) => {
    if (isEmbeddedElement(elementType)) return ELEMENT_TYPE_LABELS.embedded;
    if (isSocialElement(elementType) || elementType === "social") return ELEMENT_TYPE_LABELS.social;
    if (elementType === "title") return ELEMENT_TYPE_LABELS.title;

    return elementType ? capitalize(elementType) : "Link or Embedded Media";
  };

  return <DialogTitle>{`${action} ${getLabel(pageElementType)}`}</DialogTitle>;
};

Title.propTypes = {
  pageElementId: PropTypes.number,
  pageElementType: PropTypes.string,
};

export default Title;
