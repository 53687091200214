import React from "react";
import PropTypes from "prop-types";

import OrderDetailsItems from "./OrderDetailsItems";

import NoteEditor from "components/NoteEditor";

const OrderDetailsModalContent = ({ order }) => {
  
  return (
    <div className="order-details-modal-content">
      <OrderDetailsItems order={order} />
      <NoteEditor notable={order} notableType="orders" />
    </div>
  );
};

OrderDetailsModalContent.propTypes = {
  order: PropTypes.object,
};

export default OrderDetailsModalContent;