import { validateEmail } from "./validateEmail";

const validateEmailsArray = (emailsArray) => {
    if (emailsArray === undefined || emailsArray === null || emailsArray.length === 0) {
        throw errorMessage;
    } else {
        emailsArray.forEach((email) => {
            validateEmail(email, null, errorMessage);
        });
    }
};

const errorMessage = "Please enter one or more valid email addresses, separated by commas.";

export default validateEmailsArray;
