import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Title from './Title';
import Content from './Content';
import Actions from './Actions';

import { useError } from 'utils/useErrorController';
import { validateStringField } from 'utils/validations/validateStringField';

import Dialog from '@mui/material/Dialog';

const CustomButtonModal = ({open, onClose, onSubmit}) => {
  const [label, setLabel] = useState('');
  const [url, setUrl] = useState('');

  const {handleError} = useError();

  const validateInputs = () => {
    validateStringField(label, 'label', "Please set a label");
    validateStringField(url, 'url', "Please select an event or enter another url");
  };

  const handleSubmit = () => {
    try {
      validateInputs();
      const customButtonPlaceholder = `$$button\r\nlabel=${label}\r\nurl=${url}\r\n$$`;
      onSubmit(customButtonPlaceholder);
      handleClose();
    } catch (error) {
      handleError(error);
    }
  };

  const handleClose = () => {
    setLabel('');
    setUrl('');
    onClose();
  };

  if (!open) return null;

  return (
    <Dialog open={open} onClose={ handleClose } fullWidth maxWidth='sm'>
      <Title />
      <Content label={label} onLabelChange={setLabel} url={url} onUrlChange={setUrl} />
      <Actions onClose={handleClose} onSubmit={handleSubmit} />
    </Dialog>
  );
};

CustomButtonModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  buttonPosition: PropTypes.object
};

export default CustomButtonModal;