import React from "react";
import PropTypes from "prop-types";

import NumberTextField from "components/NumberTextfield";

const NumberOfEventsSelector = ({ numberOfEvents, onChange }) => {

  return (
    <NumberTextField
      size="small"
      name="number_of_events"
      label={"Max number of events in calendar"} 
      value={numberOfEvents}
      onChange={onChange}
      fullWidth
      color="warning"
    />    
  );
};

NumberOfEventsSelector.propTypes = {
  numberOfEvents:  PropTypes.string,
  onChange: PropTypes.func,
};

export default NumberOfEventsSelector;
