import React from 'react';
import PropTypes from 'prop-types';

import { DialogTitle, IconButton } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";

const Title = ({single, selectedSessionId, onClose}) => {

  const title = single || selectedSessionId ? 'Send new Ticket' : 'Select a Session';
  
  return (
    <DialogTitle>
        {title}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 12,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
  );
};

Title.propTypes = {
  single: PropTypes.bool.isRequired,
  selectedSessionId: PropTypes.number,
  onClose: PropTypes.func.isRequired,
};

export default Title;