import React from "react";
import PropTypes from "prop-types";

import Subtitle from "ui/text/Subtitle";
import Description from "ui/text/Description";

import { offerCategory } from "utils/offersFormHelper";

import bizumApp from "assets/images/bizumApp.png";

import { t } from "i18n/index";
import { Link } from "@mui/material";

const BizumPending = ({redirectUrl, category}) => {

  return (
    <div className="payment-method-bizum-app" >
      <div className="first-step-container">
        <Subtitle>1) {t('profile:memberships:claimModal:processingBizum_part1')}</Subtitle>
        <Description>{t('profile:memberships:claimModal:processingBizum_part2')}</Description>
        <Link fontSize="14px" color="inherit" sx={{wordBreak: "break-all"}} href={redirectUrl} target="_blank">{redirectUrl}</Link>
      </div>
    
      <Subtitle>2) {t('profile:memberships:claimModal:processingBizum_part3')} {category === offerCategory.ticket.value ? t('literal:tickets_sc') : t('literal:products_sc')}</Subtitle>

      <img src={bizumApp} className='image' />

    </div>
  );
};

BizumPending.propTypes = {
  redirectUrl: PropTypes.string,
  category: PropTypes.string,
};

export default BizumPending;
