const supportedPreferences = ["hide_description", "show_application_fee", "allow_invoicing"];

const createFormData = (organization) => {
  const {title, slug, description, external_link, image_file, cover_image_file, preferences} = organization;

  const formData = new FormData();
  const fields = [
    {key: "title", value: title},
    {key: "slug", value: slug},
  ]

  if (description) {
    fields.push({key: "description", value: description});
  }

  if (external_link) {
    fields.push({key: "external_link", value: external_link});
  }

  if (image_file) {
    fields.push({ key: 'image', value: image_file });
  }

  if (cover_image_file){
    fields.push({key: "cover_image", value: cover_image_file})
  }

  fields.forEach(field => {
    formData.append(field.key, field.value);
  });

  Object.keys(preferences).forEach(preference => {
    if (supportedPreferences.includes(preference) && preferences[preference]) {
      formData.append('preferences[]', preference);
    }
  });

  return formData;
};

export default createFormData;
