import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useController, useSuspense } from '@rest-hooks/react';
import { useParams } from 'react-router-dom';

import ClaimResource from 'resources/public/ClaimResource';
import SubscriptionResource from 'resources/Profile/SubscriptionResource';
import MembershipResource from 'resources/Profile/MembershipResource';
import ProfileResource from 'resources/Profile/ProfileResource';

import PaymentModal from 'components/CheckoutModal/PaymentModal';
import ConfirmModal from 'components/ConfirmModal';

import { useError } from 'utils/useErrorController';
import { useToast } from 'utils/context/ToastContext';
import { checkAvailability } from 'utils/profile/claim/checkAvailability';
import { getHelpText } from 'utils/profile/claim/getHelpText';
import { extractPricingId } from 'utils/profile/claim/extractPricingId';
import isMobileDevice from 'utils/mobileAndTabletCheck';

import { t } from "i18n/index";

const ClaimModal = ({ rewardId, claimToken, activeFrequency, userSubscription, handleCloseClaimModal, claimWithNFT }) => {

  const {fetch} = useController();

  const { organizationSlug } = useParams();
  const { hasErrorMessage, loading, setLoading, handleError, handleCloseErrorModal } = useError();

  const { showToast } = useToast();

  useEffect(() => {
    if (helpText && step === 1) {
      handleError(helpText);
    }
  }, [helpText]);

  const pass = useSuspense(ClaimResource.detail(), {id: claimToken});

  const unavailable = checkAvailability(pass);
  const canClaim = pass.participant.can_claim;
  const helpText = getHelpText(pass);

  const ableToPurchase = !unavailable && canClaim && !hasErrorMessage;
  const hasPricings = pass.pricings.length > 0
  const pricingId = hasPricings && extractPricingId(activeFrequency, pass.pricings);

  const paidSubscription = userSubscription && userSubscription.pricing_id !== null;
  
  const stripe_purchase = hasPricings && !claimWithNFT;

  const openCheckoutModalDefaultState = stripe_purchase ? false : true;
  const stepDefaultState = stripe_purchase ? 1 : 2;

  const [openCheckoutModal, setOpenCheckoutModal] = useState(openCheckoutModalDefaultState);
  const [step, setStep] = useState(stepDefaultState);
  const [confirmUpgrade, setConfirmUpgrade] = useState(false);

  const handlePurchase = async () => {
    if (paidSubscription) {
      setConfirmUpgrade(true);
    } else {
      setStep(1);
      setOpenCheckoutModal(true);
    }
  };

  useEffect(() => {
    if (ableToPurchase && !claimWithNFT) {
      handlePurchase();
    } else if (stripe_purchase) {
      handleError(t('errors:default'));
    }
  }, []);

  const handleUpgrade = async () => {
    try {
      setLoading(true);
      const {id} = await fetch(SubscriptionResource.update(), { id: userSubscription.id, organization_slug: organizationSlug, }, { active_frequency: activeFrequency, reward_id: rewardId });
      if (id) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        await fetch(MembershipResource.list(), {organization_slug: organizationSlug});
        await fetch(ProfileResource.detail(), {id: organizationSlug});
        showToast('success', t('profile:memberships:claimModal:upgradeSuccessfulMessage'));
        handleCloseClaimModal();
        setLoading(false);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleModalsClose = () => {
    handleCloseClaimModal();
    handleCloseErrorModal();
    setOpenCheckoutModal(false);
  };

  const isMobile = isMobileDevice();
  const showLargeModal = !isMobile && step === 1;
  const modalWidth = showLargeModal ? "md" : "sm";

  return (
    <>
      <PaymentModal
        pricingId={ pricingId }
        object={ pass }
        open={ openCheckoutModal }
        step={ step }
        setStep={ setStep }
        handleModalsClose={ handleModalsClose }
        stripe_purchase={ stripe_purchase }
        modalWidth={ modalWidth }
      />

      <ConfirmModal
          title={t('literal:upgradingSubscription')}
          open={confirmUpgrade}
          setOpen={setConfirmUpgrade}
          onConfirm={ handleUpgrade }
          loading={loading}
        >
          {t('profile:memberships:claimModal:upgradeConfirmationMessage')}
      </ConfirmModal>
    </>
  );
};

ClaimModal.propTypes = {
  rewardId: PropTypes.number,
  claimToken: PropTypes.string,
  activeFrequency: PropTypes.string,
  userSubscription: PropTypes.object,
  handleCloseClaimModal: PropTypes.func,
  claimWithNFT: PropTypes.bool,
};

export default ClaimModal;
