import React from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from "react-router-dom";

import logo from 'assets/images/logo-membrzclub.png';

import { organizationUrl } from 'utils/urls';
import { getTeacherNavLinks } from 'utils/navLinks';
import LastView from 'utils/localStorage/LastView';

import Button from 'ui/Button';

import LaunchIcon from '@mui/icons-material/Launch';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { Link as MuiLink } from '@mui/material';

const Teacher = ({ organizationsList, activeMenuItem=0 }) => {

  const params = useParams();
  const organizationId = params.organizationId;
  const currentOrganization = organizationsList && organizationsList.find(org => org.id === parseInt(organizationId));
  const { title, slug } = currentOrganization || {};
  const menuItems = getTeacherNavLinks(organizationId);

  return (
    <div>
      <Toolbar className="drawer-logo">
        <img src={ logo } />
      </Toolbar>

      <h2 className='center orange'>{title}</h2>
      {menuItems.map(({id, text, Icon, link}) => (
        <ListItemButton
            key={id}
            className={id == 'onboarding' ? 'onboarding' : ''}
            selected={ id != 'onboarding' && id == activeMenuItem }
            component={Link}
            disabled={!organizationId}
            to={ `/organizations/${organizationId}/${link}`}
            onClick={() => LastView.write(link)}
            >
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          <ListItemText primary={ text } />
        </ListItemButton>
      ))}

      <MuiLink className='preview-profile-page' href={ organizationUrl(slug)} target='_blank'>
        <Button icon={LaunchIcon} variant='contained' secondary>
          Preview Page
        </Button>
      </MuiLink>
    </div>
  );
};

Teacher.propTypes = {
  organizationsList: PropTypes.array,
  activeMenuItem: PropTypes.string
};

export default Teacher;
