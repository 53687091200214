import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useController } from "@rest-hooks/react";

import CheckinEventResource from "resources/checkin/EventResource";
import OrganizationEventResource from "resources/organization/EventResource";

import Actions from "./Actions";
import Title from "./Title";
import Content from "./Content";

import { useError } from "utils/useErrorController";
import { useToast } from "utils/context/ToastContext";

import Dialog from "@mui/material/Dialog";
import { useParams } from "react-router-dom";

const EndSalesModal = ({event, TicketResource, ticketResourceParams, open, onOpenChange}) => {
  
  const { showToast } = useToast();
  const { organizationId, eventUuid } = useParams();

  const EventResource = organizationId ? OrganizationEventResource : CheckinEventResource;

  const availableTickets = useMemo(() => {

    // if in checkin/ticket list user may have already selected a session
    if (ticketResourceParams?.session_id) {
      const targetSession = event.sessions?.find(session => session.id === +ticketResourceParams.session_id );

      const availableSessionTickets = targetSession?.tickets?.filter(ticket => ticket.available) || [];

      return availableSessionTickets.length > 0
        ? [{sessionTitle: targetSession.title, tickets: availableSessionTickets}]
        : [];
    }

    if (!event.single && event.sessions) {
      return event.sessions
        .filter(session => 
          session.tickets && 
          session.tickets.some(ticket => ticket.available)
        )
        .map(session => ({
          sessionTitle: session.title,
          tickets: session.tickets.filter(ticket => ticket.available)
        })) || [];
    }
    
    return event?.tickets?.filter(ticket => ticket.available) || [];
  }, [event?.sessions, event?.tickets]);

  const [ticketIds, setTicketIds] = useState( availableTickets.length === 1 ? [availableTickets[0].id] : [] );

  const handleClose = () => {
    setTicketIds([]);
    onOpenChange(false);
  };

  const { fetch } = useController();
  const { handleError } = useError();

  const handleEndSaleRequest = async () => {
    try {
      //repeat ticketResourceParams
      //params are expected in the request url by the organization and in the request body by the checkin namespace controller
      await fetch(TicketResource.update(), ticketResourceParams, { product_ids: ticketIds, ...ticketResourceParams });

      const message = ticketIds.length > 1 ? "Tickets updated" : "Ticket updated";
      showToast( "success", message );
      handleClose();

      // fetch event details to refresh tickets list and available amounts
      const eventResourceParams = organizationId ? { organization_id: organizationId, id: event.id } : { id: eventUuid };
      await fetch(EventResource.detail(), eventResourceParams);
      
    } catch (error) {
      handleError(error);
    }
  };

  const handleTicketCheckboxChange = (ticketId) => {
    setTicketIds((prevTicketIds) =>
      prevTicketIds.includes(ticketId)
        ? prevTicketIds.filter((id) => id !== ticketId)
        : [...prevTicketIds, ticketId]
    );
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
    
      <Title
        title={event.title}
        onClose={handleClose} />

      <Content
        availableTickets={availableTickets}
        ticketIds={ticketIds}
        onTicketCheckboxChange={handleTicketCheckboxChange}
      />

      <Actions 
        onClose={handleClose}
        onEndSaleRequest={handleEndSaleRequest}
        ticketIds={ticketIds}
      />

    </Dialog>
  );
};

EndSalesModal.propTypes = {
  event: PropTypes.object,
  TicketResource: PropTypes.object,
  ticketResourceParams: PropTypes.object,
  open: PropTypes.bool,
  onOpenChange: PropTypes.func,
};

export default EndSalesModal;
