import React from 'react';
import "./styles.css";

const ViewFinder = () => {

  const renderCorner = (position) => (
    <div className={`viewfinder-corner viewfinder-corner--${position}`} />
  );

  return (
    <div className="viewfinder">
      {renderCorner("top-left")}
      {renderCorner("top-right")}
      {renderCorner("bottom-left")}
      {renderCorner("bottom-right")}
    </div>
  );
};

export default ViewFinder; 