import React  from 'react';
import { useSuspense } from '@rest-hooks/react';

import TicketResource from 'resources/user/TicketResource';

import TicketsGrid from 'components/CardGrids/TicketsGrid';
import Layout from 'components/layouts/index';
import TabPanels from 'components/TabPanels';

import { t } from 'i18n/index';

const pageInfo = {
    name: t('student:tickets:pageTitle'),
    description: t('student:tickets:pageDescription'),
  };

const Tickets = () => {

    const upcomingEventsTickets = useSuspense(TicketResource.list(), { upcoming: true });
    const pastEventsTickets = useSuspense(TicketResource.list());

    const labels = [ `${t('literal:upcoming')}`]
    pastEventsTickets.length > 0 && labels.push(t('literal:past'))

    return (
        <Layout context='student'
                activeMenuItem="tickets"
                pageInfo={ pageInfo } >

            <TabPanels labels={labels}>

                    {upcomingEventsTickets.length > 0 ?
                        <TicketsGrid data={ upcomingEventsTickets } upcoming={true} /> :
                        <p>{t('student:tickets:noneFoundMessage')}</p>
                    }

                    <TicketsGrid data={ pastEventsTickets } />

            </TabPanels>
        </Layout>
    );
};

export default Tickets;
