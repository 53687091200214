import { styled } from '@mui/material/styles';
import { List } from '@mui/material';
export const StyledList = styled(List)({
  '& .MuiListItemText-primary': {
    color: 'var(--primaryTextColor)',
  },
  '& .MuiListItemIcon-root': {
    color: 'var(--highlightColor)',
  },
  '& .MuiListItemButton-root:hover': {
    backgroundColor: 'var(--secondaryBackgroundColor)',
  },
}); 