import { t }from "i18n/index";

export default function copyToClipboard(text, showToast, onCopyComplete) {
    if (navigator && navigator.clipboard) {
        navigator.clipboard.writeText(text)
        .then(() => {
            showToast("success", t('utils:copyToClipboard:success'));
            if (onCopyComplete) onCopyComplete();
        })
        .catch((error) => {
            showToast("warning", t('utils:copyToClipboard:failure', {error}));
        });
      }    
}