import React from "react";
import PropTypes from "prop-types";

import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import { Draggable } from "react-beautiful-dnd";
import Description from "ui/text/Description";
import CustomChip from "ui/CustomChip";

const actionButtons = [
  {
    key: 'link',
    icon: LinkIcon,
    action: (ticket, handlers) => () => handlers.handleUrlCopy(ticket.external_url),
  },
  {
    key: 'edit',
    icon: EditIcon,
    action: (ticket, handlers) => (e) => handlers.handleRedirect(e, `/${ticket.id}/edit`),
  },
  {
    key: 'delete',
    icon: DeleteIcon,
    action: (ticket, handlers) => () => handlers.handleDeleteRequest(ticket.id, ticket.identifier),
  },
];

const ListItem = ({ ticket, disabled, handleDeleteRequest, handleRedirect, handleUrlCopy, index }) => {
  
  const handlers = { handleDeleteRequest, handleRedirect, handleUrlCopy };
  
  return (
    <Draggable key={ticket.id} draggableId={String(ticket.id)} index={index} isDragDisabled={disabled}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`event-form_ticket ${snapshot.isDragging ? "dragging" : ""}`}
          key={index}
        >
          <Description>{ticket.identifier}</Description>
          <div>
            {!ticket.available &&
              <CustomChip label="inactive" minwidth padded />
            }
            {actionButtons.map(({ key, icon: Icon, action }) => (
              <IconButton disabled={disabled} key={key} onClick={action(ticket, handlers)}>
                <Icon color={disabled ? "disabled" : "warning"} />
              </IconButton>
            ))}
            <DragIndicatorIcon color={disabled ? "disabled" : "warning"} />
          </div>
        </div>
      )}
    </Draggable>
  );
};

ListItem.propTypes = {
  ticket: PropTypes.object,
  disabled: PropTypes.bool,
  handleDeleteRequest: PropTypes.func,
  handleRedirect: PropTypes.func,
  handleUrlCopy: PropTypes.func,
  index: PropTypes.integer
};

export default ListItem;
