const storage = localStorage;
const key     = 'cc.checkin_mode';

// -----------------------------------------------------
// Util Definition
// -----------------------------------------------------

const CheckinMode = {
  read:   () => storage.getItem(key),
  write:  (bool) => storage.setItem(key, bool),
  remove: () => storage.removeItem(key)
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default CheckinMode;