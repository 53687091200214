import React from 'react';
import PropTypes from 'prop-types';

import Title from './Title';
import Modules from './Modules';
import Status from './Status';

import Button from 'ui/Button';
import FormSection from 'ui/FormSection';

import DialogContent from '@mui/material/DialogContent';

const EditForm = ({activeOrg, onSubmit, onChange, onClose, loading}) => {

  return (
    <DialogContent className='edit-organization-form'>
      <FormSection slim title={`Edit ${activeOrg.title}`}>
        <Title title={activeOrg.title} onChange={onChange} disabled={activeOrg.newsletter_sender_created}/>
        <Status status={activeOrg.status} onChange={onChange} />
        <Modules activeOrg={activeOrg} onChange={onChange} />

        <div className='button-group'>
        <Button fullwidth loading={loading} secondary onClick={ onClose }>Cancel</Button>
        <Button fullwidth loading={loading} onClick={(e) => onSubmit(e, activeOrg)} >Save Changes</Button>
        </div>
      </FormSection>
    </DialogContent>
  )
};

EditForm.propTypes = {
    activeOrg: PropTypes.object,
    onSubmit: PropTypes.func,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    loading: PropTypes.bool
};

export default EditForm;