import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useController } from '@rest-hooks/react';

import OnboardingResource from 'resources/organization/OnboardingResource';

import Button from 'ui/Button';

import { useToast } from 'utils/context/ToastContext';
import copyToClipboard from 'utils/copyToClipboard';
import { organizationUrl } from 'utils/urls';

import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const listItems = [
  { id: "landing_page", title: "Set up your club profile", description: "Personalize your Club's landing page", link: `settings/details` },
  { id: "page_elements", title: "Customize your Link-In-Bio", description: "Customize your landing page with Links", link: `elements` },
  { id: "tiers", title: "Personalize Membership Tiers", description: "Set up different membership levels", link: `memberships` },
  { id: "share", title: "Share a Link to your club", description: "Share a link to your club and add it as a Link-In-Bio to Instagram, TikTok, and other social media profiles.", action: true },
  { id: "newsletters", title: "Customize your Newsletters", description: "Set up your account to start sending newsletters", link: `settings/email` },
  { id: "posts", title: "Write your first Post", description: "Write and publish your content", link: `content/new` },
  { id: "products", title: "Create your first Product", description: "Define special perks for your members", link: `perks` },
  // { id: "profile_tabs", title: "Customize Profile Tabs", description: "Tailor the layout of user profiles", link: `settings/preferences` },
  { id: "stripe", title: "Set up Payments", description: "Integrate Stripe for payment processing", link: `settings/stripe` },
  { id: "telegram", title: "Add Telegram Group Chats", description: "Integrate Telegram to create private groups with members", link: `settings/integrations` },
  { id: "onboarding_call", title: "Questions? Book an Onboarding Call", description: "Schedule a call with us to help you get started", external_link: "https://calendly.com/fpp_pt/meet" },
];

const Checklist = ({ onboardingDetails }) => {

  const [hoveredItem, setHoveredItem] = useState(null);
  const {id, completed_items, skipped_items, slug} = onboardingDetails;
  const actionedItems = [...completed_items, ...skipped_items];

  const { showToast } = useToast();

  const handleUrlCopy = () => {
    copyToClipboard(organizationUrl(slug), showToast)
  };

  const {fetch} = useController();
  const history = useHistory();

  const handleRedirect = ({id, link, external_link=null}) => {
    if (external_link) {
      handleSkipRequest(id);
      window.open(external_link, '_blank');
    } else if (id === "share") {
      handleSkipRequest(id);
      handleUrlCopy()
    } else {
      history.push(link);
    }
  };

  const handleSkipRequest = async (item_id) => {
    await fetch(OnboardingResource.update(), {organization_id: id, id: item_id}, {skip: item_id});
    const {status} = await fetch(OnboardingResource.detail(), {organization_id: id});

    if (status === 'completed') {
      history.push("memberships")
    }
  };

  const handleSkipAllRequest = async () => {
    await fetch(OnboardingResource.update(), {organization_id: id, id: id}, {skip: "all"});
    await fetch(OnboardingResource.detail(), {organization_id: id});
    history.push("memberships")
  };

  const changeOnHover = (itemId, index) => hoveredItem === index && !actionedItems.includes(itemId);

  return (
    <List className='onboarding-checklist'>
      {listItems.map((item, index) => (
        <ListItem
          key={index}
          onMouseEnter={() => setHoveredItem(index)}
          onMouseLeave={() => setHoveredItem(null)}
          className='item'>

              <ListItemIcon>
                {actionedItems.includes(item.id)
                  ? <CheckBoxIcon color={ completed_items.includes(item.id) ? 'success' : 'grey' }/>
                  : <CheckBoxOutlineBlankIcon />}
              </ListItemIcon>
              <ListItemText primary={item.title} secondary={changeOnHover(item.id, index) ? item.description : null} />

              { changeOnHover(item.id, index) && (
                <div className='actions'>
                  <Button text onClick={() => handleSkipRequest(item.id)} fullwidth>MARK AS DONE</Button>
                  <Button minwidth highlighted onClick={() => handleRedirect(item)} fullwidth>GET STARTED</Button>
                </div>
              )}
              
            </ListItem>
        ))}

        <ListItem
          key={listItems.length + 1}
          sx={{backgroundColor: "#ffff",
                marginTop: '2px',
                display: 'flex',
                justifyContent: 'flex-end'}}>

          <ListItemText
            style={ { textAlign: 'right', marginRight: '20px' } }
            secondary={ actionedItems.length +'/'+ listItems.length + ' completed'} />
          <Button minwidth secondary onClick={handleSkipAllRequest}>SKIP ALL</Button>

        </ListItem>
    </List>
  );
};

Checklist.propTypes = {
  onboardingDetails: PropTypes.object
};

export default Checklist;
