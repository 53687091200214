import React from "react";
import PropTypes from "prop-types";

import MarkdownEditor from 'components/MarkdownEditor';

import FormSection from "ui/FormSection";
import Label from 'ui/text/Label';

const SuccessEmail = ({expandable, product, onProductChange}) => {

  return (
    <FormSection title={"Success Email"} expandable={expandable}>
        <MarkdownEditor
            object={product}
            setObject={onProductChange}
            attribute={"content_email"}
            placeholder={"Success Email"}
            height={"200px"}
        />

        <Label secondary padded>
            An email with this content will be sent to the member upon purchasing this product.
        </Label>

    </FormSection>
  );
};

SuccessEmail.propTypes = {
  expandable: PropTypes.bool,
  product: PropTypes.object,
  onProductChange: PropTypes.func,
};

export default SuccessEmail;
