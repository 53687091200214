import moment from "moment";
import { validateStringField } from "utils/validations/validateStringField";

const handleValidation = (event) => {
    const { title, start_date, single, end_date, start_time, repeat_days, session_tickets, id, universal_time } = event;

    validateStringField(title, "a valid title");
    validateStartDate(start_date)
    if (!single && !id) validateRecurringEvent(start_time, end_date, repeat_days, session_tickets, universal_time);
};

const validateRecurringEvent = (start_time, end_date, repeat_days, session_tickets, universal_time) => {
    if (universal_time !== false) validateStartTime(start_time);
    validateRepeatDays(repeat_days, universal_time);
    validateEndDate(end_date);
    validateSessionTickets(session_tickets);
};

const validateSessionTickets = (session_tickets) => {
    if (session_tickets.length === 0) {
        throw "To create a recurring event, you must add at least one ticket type.";
    }

    const hasValidTitle = session_tickets.some(ticket => ticket.title && ticket.title.trim() !== "");
    if (!hasValidTitle) {
        throw "To create a recurring event, at least one ticket must have a non-empty title.";
    }

    const hasEmptyTitle = session_tickets.some(ticket => 
      Object.keys(ticket).length > 0 && (!ticket.title || ticket.title.trim() === "")
    );
    if (hasEmptyTitle) {
        throw "All tickets must have a non-empty title. Please fill in the title for all tickets";
    }
};

const validateRepeatDays = (repeat_days, universal_time) => {

    if (repeat_days.length === 0) {
        throw "At least one repeat day must be selected.";
    }

    //we only need to iterate over repeat days and validate their individual start times if universal_time is false
    //if universal_time is true or undefined, repeat days individual start times are equal to the event start time which has already been validated
    if (universal_time === false) {
        repeat_days.forEach(day => {
            const dayKey = Object.keys(day)[0]; 
            const dayStartTime = day[dayKey];
            //individual sessions start times can only be set on create, so they are always a moment object
            if (!dayStartTime || !dayStartTime.isValid()) {
                throw "All sessions must have a valid start time.";
            }
        });
    }
};

const validateStartTime = (start_time) => {
    //universal sessions start time can only be set on create, so it is always a moment object
    if (!start_time || !start_time.isValid()) {
        throw "Start time must be present and be a valid time.";
    }
};

const validateEndDate = (date) => {
    // single events: end date can be set when creating or editing but it is not validated as it is not mandatory
    // the backend will discard the end date if it is not a valid date, ex: user input 33/33/3333 33:33

    // recurring events: end date can only be set on create, so it is always a moment object
    if (!date || !date.isValid()) {
        throw "End date must be present and be a valid date.";
    }
};

const validateStartDate = (date) => {
    // date is a string on edit if not changed
    // date is a moment object on create or edit if changed

    // date must be present on create and edit
    // date must be a valid date if is a moment object (has been changed)
    // example, on edit (unchanged): 2024-11-29T00:02:00.000Z is valid
    // example, on create or edit: user input 33/33/3333 33:33 not valid'
    if (!date || (moment.isMoment(date) && !date.isValid())) {
        throw "Start date must be present and be a valid date.";
    }
};

export default handleValidation;