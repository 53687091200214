import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const options = [
  { label: 'Subscribe Newsletter', value: "subscribe" },
  { label: 'Go to Memberships', value: "memberships" },
  { label: 'Go to Events', value: "events" },
  { label: 'Go to Next Event', value: "events/next" },
  { label: 'Go to Store', value: "store" },
];

const SubscribeButtonActionEditor = ({value, onChange}) => {

  const handleChange = (event, newValue) => {
    onChange({target: {name: "custom_cta_action", value: newValue?.inputValue || newValue?.value}});
  };

  const filter = createFilterOptions();
  const filterOptions = (options, params) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    
    const isExisting = options.some((option) => 
      inputValue === option.label || inputValue === option.value
    );

    if (inputValue.trim() !== '' && !isExisting) {
      filtered.push({
        inputValue,
        label: `Redirect to: "${inputValue}"`,
      });
    }
    return filtered;
  };

  const getOptionLabel = (option) => {
    if (typeof option === 'string') {
      const matchedOption = options.find(opt => opt.value === option);
      return matchedOption ? matchedOption.label : option;
    }
    if (option.inputValue) {
      return option.inputValue;
    }
    return option.label;
  };

  const renderOption = (props, option) => {
    const {...optionProps } = props;
    return (
      <li {...optionProps}>
        {option.label}
      </li>
    );
  };

  const renderInput = (params) => (
    <TextField 
      fullWidth 
      color='warning' 
      {...params} 
      label="Action Button"
      helperText={"Choose an action or enter a custom URL."} />
  );

  return (
    <Autocomplete
      key={value}
      value={value}
      defaultValue={value || options[0]}
      onChange={handleChange}
      filterOptions={filterOptions}
      selectOnFocus
      disableClearable 
      handleHomeEndKeys
      options={options}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      size='small'
      renderInput={renderInput}
    />
  );
};

SubscribeButtonActionEditor.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func
};

export default SubscribeButtonActionEditor;