import { useController } from '@rest-hooks/react';

import OrderResource from 'resources/user/OrderResource'

import { useError } from './useErrorController';

const usePaymentIntent = ({
  handleValidation,
  userPhone,
  object,
  pricingId,
  requestedAmount,
  promoCode,
  handleStripeCardPaymentIntent,
  handleEupagoPaymentIntent,
  handlePaypalPaymentIntent
}) => {

  const { handleError, setLoading, validate } = useError();
  const { fetch } = useController();

  const createPaymentIntent = async (activeProvider, paymentMethod) => {
    try {
      
      if (paymentMethod === "mbway") validate(userPhone, handleValidation);
      
      setLoading(true);
      
      const params = { 
        payment_provider: activeProvider, 
        pricing_id: pricingId, 
        amount: requestedAmount,
        user_phone: userPhone,
        promo_code: promoCode
      };

      if (paymentMethod) params.payment_method = paymentMethod;

      const response = await fetch(OrderResource.create(), {organization_id: object.organization.id}, params);  
      
      const {client_secret, id, item_price, amount, stripe_account_id, stripe_publishable_key} = response;

      switch (activeProvider) {
        case "stripe":
          await handleStripeCardPaymentIntent(client_secret, stripe_publishable_key, stripe_account_id, id, item_price, amount);
          break;
        case "eupago":
          await handleEupagoPaymentIntent(id, client_secret);
          break;
        case "paypal":
          handlePaypalPaymentIntent(id);
          return id;
      }

    } catch (error) {
      handleError(error);
    }
  };

  return { createPaymentIntent };
};

export default usePaymentIntent;
