import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import Button from 'ui/Button';

const UploadButtons = ({ attachmentTypes, handleFileUpload, loading, multiple=false, mandatory=false, hiddenFileInputs, hasAttachedImages, hasAttachedVideos }) => {
  
  useEffect(() => {
    if (!hasAttachedImages) {
      hiddenFileInputs.image.current.value = null;
    }
  }, [hasAttachedImages]);

  useEffect(() => {
    if (!hasAttachedVideos && attachmentTypes.includes("video")) {
      hiddenFileInputs.video.current.value = null;
    }
  }, [hasAttachedVideos]);

  return (
    <> 
      {attachmentTypes.map((attachmentType) => {
        if (!attachmentType.condition) { return null; } return (
        <React.Fragment key={attachmentType.type}>
          <Button
            fullwidth
            secondary
            icon={attachmentType.icon}
            loading={loading}
            onClick={(e) => {
              e.preventDefault();
              hiddenFileInputs[attachmentType.type].current.click(e);
            }}
          >
            {`Upload ${attachmentType.label}${multiple && attachmentType.type === "image" ? "s" : ""}${mandatory ? " *" : ""}`} 
          </Button>
          <input
            type="file"
            accept={attachmentType.accept}
            name={attachmentType.type}
            hidden
            ref={hiddenFileInputs[attachmentType.type]}
            onChange={handleFileUpload}
            multiple={multiple && attachmentType.type === "image"}
          />
        </React.Fragment>
      )})}
    </>
  )
};

UploadButtons.propTypes = {
  attachmentTypes: PropTypes.array,
  handleFileUpload: PropTypes.func,
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  mandatory: PropTypes.bool,
  hiddenFileInputs: PropTypes.object,
  hasAttachedImages: PropTypes.bool,
  hasAttachedVideos: PropTypes.bool
};

export default UploadButtons;