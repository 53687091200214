import React from 'react';
import PropTypes from 'prop-types';

import { capitalize } from 'utils/capitalize';

import "./styles.css";

const StatCard = ({type, highlight, title}) => {

  const transformedTitle = capitalize(title).replace('_', ' ')

  return (
  <div className="ui-stat-card" >
    { type === 'number' &&
      <div className="number" >
        { highlight }
      </div>
    }
    { type === 'chart' &&
      <div className="canvas-wrapper" >
        { highlight }
      </div>
    }
    <div className="title" >
      { transformedTitle }
    </div>
  </div>
  )
};

StatCard.propTypes = {
  type: PropTypes.string,
  highlight: PropTypes.oneOfType([
              PropTypes.node,
              PropTypes.number
            ]),
  title: PropTypes.string
};

export default StatCard;