import React, { useEffect } from "react";
import { useSuspense } from '@rest-hooks/react';
import { useParams } from 'react-router-dom';
import PropTypes from "prop-types";

import EventResource from 'resources/Profile/EventResource';
import TicketResource from "resources/Profile/TicketResource";
import TagResource from 'resources/Profile/TagResource';

import EventsList from './EventsList/index';
import SingleCard from './SingleCard';

import { scrollToTop } from 'utils/scrollToTop';
import generateMetatags from "utils/generateMetatags";
import { useSocialMetatags } from "utils/socialMetatagsController";

import './styles.css';

const Events = ({organization_title, organization_logo}) => {

  const { organizationSlug, eventSlug, ticketSlug } = useParams();
  const {setSocialMetatags} = useSocialMetatags();

  useEffect(() => {
    const page = eventSlug ? 'singleEvent' : 'events';
    const event = eventSlug ? events?.[0] : null;

    generateMetatags(page, setSocialMetatags, organization_title, organization_logo, event?.title, event?.short_description, event?.image_url);
  }, []);

  scrollToTop();

  const searchParams = new URLSearchParams(window.location.search);
  const tagName = searchParams.get('tag');
  const organizationTags = useSuspense(TagResource.list(), {organization_slug: organizationSlug, tag_type: 'event'});
  const activeTag = tagName ? organizationTags.find(tag => tag.name === tagName) : null;

  // the existence of eventSlug determines if we are in the single event page
  // the existence of ticketSlug determines if we are in the single event page with secret ticket

  // prior to these changes we were making 1 request in this component to fetch the event details
  // 1 other request to fetch the ticket details inside the single event card component

  // with these changes we were able to remove the request inside the single event card component and reduce the number of requests to 1
  const events = eventSlug
                  ? ticketSlug
                    ? [useSuspense(TicketResource.detail(), {organization_slug: organizationSlug, event_slug: eventSlug, id: ticketSlug})]
                    : [useSuspense(EventResource.detail(), {organization_slug: organizationSlug, id: eventSlug})]
                  : useSuspense(EventResource.list(), {organization_slug: organizationSlug, all: true, tag_id: activeTag?.id});

  return (
    <>
      { eventSlug ?
        <SingleCard event={events[0]} organizationSlug={organizationSlug} organizationTags={organizationTags} />
      :
        <EventsList events={events} organizationTags={organizationTags} activeTag={activeTag} />
      }
    </>
  );
};

Events.propTypes = {
  organization_title: PropTypes.string,
  organization_logo: PropTypes.string,
};

export default Events;
