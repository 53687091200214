import React from "react";
import PropTypes from "prop-types";

import Subtitle from "ui/text/Subtitle";

import { offerCategory } from "utils/offersFormHelper";

import mbwayApp from "assets/images/mbwayApp.png";

import { t } from "i18n/index";

const EupagoPending = ({category}) => {

  return (
    <div className="payment-method-mbway-app" >
      <Subtitle>1) {t('profile:memberships:claimModal:processingMbWay_part1')}</Subtitle>
      <Subtitle>2) {t('profile:memberships:claimModal:processingMbWay_part2')} {category === offerCategory.ticket.value ? t('literal:tickets_sc') : t('literal:products_sc')}</Subtitle>
      <img src={mbwayApp} className='image' />
    </div>
  );
};

EupagoPending.propTypes = {
  category: PropTypes.string,
};

export default EupagoPending;
