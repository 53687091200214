import React from "react"
import PropTypes from "prop-types"

import { Autocomplete, TextField } from "@mui/material";

const TicketSelector = ({ticket, tickets, filteredTickets, onSelect}) => {
  
  return (
    <Autocomplete
            fullWidth
            options={filteredTickets}
            onChange={(event, newValue) => {
              onSelect(event, newValue);
            }}
            disabled={tickets.length === 1}
            defaultValue={tickets.find((element) => element.id === ticket?.id)}
            getOptionLabel={(option) => `${option.identifier} ${option.available_amount ? " (" + option.available_amount + " left)" : ""}`}
            disableClearable
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Select Ticket"
                    name="ticket"
                    fullWidth
                    size="small"
                    color="warning"
                    autoFocus
                />
              )}
            />
  );
};

TicketSelector.propTypes = {
  ticket: PropTypes.object,
  tickets: PropTypes.array,
  filteredTickets: PropTypes.array,
  onSelect: PropTypes.func,
};

export default TicketSelector;