import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';

const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    backgroundColor: 'var(--primaryBackgroundColor)',
    color: 'var(--primaryTextColor)',
    borderRadius: 'var(--radius)',
  },
  '& .MuiDialogTitle-root': {
    color: 'var(--primaryTextColor)',
  },
  '& .MuiDialogContent-root': {
    color: 'var(--primaryTextColor)',
    '&.MuiDialogContent-dividers': {
      borderColor: 'var(--borderColor)',
    }
  },
  '& .MuiIconButton-root[aria-label="close"]': {
    color: 'var(--primaryTextColor)',
    position: 'absolute',
    right: 8,
    top: 8,
    zIndex: 1000,
    '&:hover': {
      color: 'var(--highlightColor)',
    }
  },
});

export default StyledDialog;
