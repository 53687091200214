import React from 'react';
import PropTypes from 'prop-types';

import Content from './Content';

import ConfirmModal from 'components/ConfirmModal';

import { t } from "i18n/index";

const RepeatPurchaseModal = ({open, onConfirm, onClose, objectCategory, objectRedeemedCount}) => {
  
  return (
     <ConfirmModal
        title={t('profile:repeatPurchaseModal:title')}
        open={ open }
        onConfirm={ onConfirm }
        setOpen={ () => { return } }
        handleClose={ onClose } >
        
        <Content 
          objectCategory={objectCategory} 
          objectRedeemedCount={objectRedeemedCount} />

      </ConfirmModal>
  );
};

RepeatPurchaseModal.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  objectCategory: PropTypes.string,
  objectRedeemedCount: PropTypes.number,
};

export default RepeatPurchaseModal;