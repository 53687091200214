import React from "react";
import PropTypes from "prop-types";

import DateInputField from "components/DateInputField";

import VisibilitySelector from "./VisibilitySelector";
import SupplyLimit from "./SupplyLimit";
import RedeemLimit from "./RedeemLimit";
import SettingsVisibility from "./SettingsVisibility";

import FormSection from "ui/FormSection";
import MinAmount from "./MinAmount";

const Availability = ({ onChange, onCheckBoxChange, object, onObjectChange }) => {

  const {redeem_limit, supply_limit, show_end_date, show_supply, visible, unit_price, min_amount, currency, free} = object;

  return (
    <FormSection title={"Availability"}>
      <VisibilitySelector visible={visible} onChange={onChange} />

      <SupplyLimit supply_limit={supply_limit} onChange={onChange} />

      <div className={"row"}>
        <MinAmount
          price={unit_price}
          min_amount={min_amount}
          currency={currency || "USD"}
          onChange={onChange}
          disabled={free} />

        <RedeemLimit redeem_limit={redeem_limit} onChange={onChange} />
      </div>

      <div className={"row"}>
        <DateInputField
          attribute={"start_date"}
          object={object}
          onChange={onObjectChange}
          label={"Start date. Leave empty to be available now"}
        />

        <DateInputField
          attribute={"end_date"}
          object={object}
          onChange={onObjectChange}
          label={"Expiration date. Leave empty for no expiration"}
        />
      </div>

      <SettingsVisibility
        show_end_date={show_end_date}
        show_supply={show_supply}
        onChange={onCheckBoxChange}
      />
    </FormSection>
  );
};

Availability.propTypes = {
  visible: PropTypes.string,
  supply_limit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  redeem_limit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  show_end_date: PropTypes.bool,
  show_supply: PropTypes.bool,
  onChange: PropTypes.func,
  onCheckBoxChange: PropTypes.func,
  object: PropTypes.object,
  onObjectChange: PropTypes.func,
};

export default Availability;
