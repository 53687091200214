import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import CustomButtonModal from './CustomButtonModal';

import { markdownCustomHTML } from 'utils/markdownCustomHTML';

import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';

import smartButtonIcon from '../../assets/images/smart-button-icon.svg';
import mediaGalleryIcon from '../../assets/images/media-gallery-icon.png';
import resetIcon from '../../assets/images/reset_icon.svg';

const TOOLBAR_ICONS = {
  button: smartButtonIcon,
  mediaGallery: mediaGalleryIcon,
  reset: resetIcon,
};

const ToolbarButton = ({ icon, tooltip, onClick, className }) => {
  const button = document.createElement('button');
  button.className = `toastui-editor-toolbar-icons last ${className}`;
  button.style.backgroundImage = `url('${icon}')`;
  button.addEventListener('click', (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  });
  return { el: button, tooltip };
};

const MarkdownEditor = ({
  object, 
  setObject, 
  attribute, 
  placeholder, 
  height="auto", 
  allowImageUpload=false, 
  allowEmbeds=false,
  allowCustomButton=false,
  onMediaGalleryAdd=false, 
  onEmailReset=false,
  setUnsavedChanges
}) => {

  const [openCustomButtonModal, setOpenCustomButtonModal] = useState(false);

  const editorRef = useRef(null);

  const handleMarkdownChange = () => {
    if (setUnsavedChanges) setUnsavedChanges(true);
    const editorInstance = editorRef.current.getInstance();
    const content = editorInstance.getMarkdown();
    setObject({...object, [attribute]: content});
  };

  const handleCustomButtonSubmit = (customButton) => {
    const editorInstance = editorRef.current.getInstance();
    editorInstance.insertText(customButton);
    handleMarkdownChange();
  };

  const getToolbarItems = () => {
    const baseItems = [
      ['heading', 'bold', 'italic'],
      ['quote', 'ul', 'ol'],
      ['link'],
    ];

    if (allowImageUpload) {
      baseItems.push(['image']);
    }

    if (allowCustomButton) {
      baseItems[2].push(
        ToolbarButton({
          icon: TOOLBAR_ICONS.button,
          tooltip: 'Insert Custom Button',
          onClick: () => setOpenCustomButtonModal(true),
          className: 'custom-button-button'
        })
      );
    }

    const customButtons = [];

    if (onMediaGalleryAdd) {
      customButtons.push(
        ToolbarButton({
          icon: TOOLBAR_ICONS.mediaGallery,
          tooltip: 'Add Media Gallery',
          onClick: onMediaGalleryAdd,
          className: 'media-gallery-button'
        })
      );
    }

    if (onEmailReset) {
      customButtons.push(
        ToolbarButton({
          icon: TOOLBAR_ICONS.reset,
          tooltip: 'Reset Email and Subject',
          onClick: onEmailReset,
          className: 'email-reset-button'
        })
      );
    }

    if (customButtons.length > 0) {
      baseItems.push(customButtons);
    }

    return baseItems;
  };

  const customHTMLRenderer = allowEmbeds || allowCustomButton ? markdownCustomHTML : null;
  
  return (
    <>
      <Editor
        previewStyle="vertical"
        ref={editorRef}
        initialValue={object && object[attribute] ? object[attribute] : ''}
        placeholder={placeholder}
        height={height}
        initialEditType="wysiwyg"
        toolbarItems={getToolbarItems()}
        hideModeSwitch={true}
        onChange={handleMarkdownChange}
        customHTMLRenderer={customHTMLRenderer}
      />
      <CustomButtonModal 
        open={openCustomButtonModal} 
        onClose={() => setOpenCustomButtonModal(false)} 
        onSubmit={handleCustomButtonSubmit}
      />
    </>
  );
};

MarkdownEditor.propTypes = {
    object: PropTypes.object,
    setObject: PropTypes.func,
    attribute: PropTypes.string,
    placeholder: PropTypes.string,
    height: PropTypes.string,
    allowImageUpload: PropTypes.bool,
    allowEmbeds: PropTypes.bool,
    allowCustomButton: PropTypes.bool,
    onMediaGalleryAdd: PropTypes.func,
    onEmailReset: PropTypes.func,
    setUnsavedChanges: PropTypes.func
};

export default MarkdownEditor;