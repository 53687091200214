const supportedPreferences = ["hide_description", "show_application_fee", "allow_invoicing"];

const createFormData = (organization) => {
    const {profile_tabs, preferences} = organization;
    const formData = new FormData();

    Object.keys(preferences).forEach(preference => {
      if (supportedPreferences.includes(preference) && preferences[preference]) {
        formData.append('preferences[]', preference);
      }
    });

    profile_tabs.forEach(tab => {
      formData.append('profile_tabs[]', tab);
    })

    return formData;
  };

  export default createFormData;
