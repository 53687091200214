import React from 'react';
import { CircularProgress } from '@mui/material';

const LoadingState = () => {
  return (
    <>
      <div className="loading-backdrop" />
      <div className="loading-spinner-container">
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#F75201" />
              <stop offset="50%" stopColor="#FF8C00" />
              <stop offset="100%" stopColor="#FFA500" />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          size={75}
          thickness={2}
          sx={{ 
            'svg circle': { stroke: 'url(#my_gradient)' },
            '& .MuiCircularProgress-circle': {
              strokeLinecap: 'round',
            },
          }}
        />
      </div>
    </>
  );
};

export default LoadingState;