import React, { useState } from 'react'
import PropTypes from 'prop-types'

import TicketDetailsForm from './TicketDetailsForm';

import CustomModal from 'components/CustomModal';

import Button from 'ui/Button';

import { t } from 'i18n';

const Content = ({ uuid }) => {

  const [customModalContent, setCustomModalContent] = useState(null);

  const handleCloseModal = () => {
    setCustomModalContent(null);
  };

  const handleOpenModal = () => {
    setCustomModalContent({
      title: t('student:tickets:sendTicket'),
      content: <TicketDetailsForm 
                  uuid={uuid} 
                  onDone={handleCloseModal}
                  />,
    });
  };

  return (
    <>
      <div className='fullwidth-card-content'>
        <Button fullwidth secondary onClick={handleOpenModal}>{t('student:tickets:sendTicket')}</Button>
      </div>

      {customModalContent && (
        <CustomModal handleClose={() => setCustomModalContent(null)} customModalContent={customModalContent} />
      )}
    </>
  );
};

Content.propTypes = {
  uuid: PropTypes.string,
};

export default Content;
