import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import About from './About';
import Content from './Content';
import Memberships from './Memberships';
import Perks from './Store';
import Notion from './Notion';
import Events from './Events';

import { checkCustomFields } from 'utils/signUpCustomFields';

import './styles.css';

const Redirect = () => {

  const {organizationSlug} = useParams();

  const history = useHistory();

  const currentPath = history.location.pathname;
  const normalizedPath = currentPath.endsWith('/') ? '' : organizationSlug + '/';

  const components = {
    about: About,
    content: Content,
    memberships: Memberships,
    store: Perks,
    more: Notion,
    events: Events,
  };

  const hasCustomFields = checkCustomFields(organizationSlug);

  const targetRoute = hasCustomFields ? 'store' : 'about';

  const TargetComponent=components[targetRoute];

  if (targetRoute !== 'about') {
    history.push(`/${normalizedPath}${targetRoute}`);
  }

  return <TargetComponent />;
};

export default Redirect;
