import React from 'react';
import PropTypes from 'prop-types';

const SlimInput = ({title, reward_ids, onClick}) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      <span>{title}</span>
      <span style={{ cursor: 'pointer' }} onClick={onClick}>
        { reward_ids?.includes("public") ? "Public" : "Custom" } ⚙️
      </span>
    </div>
  );
};

SlimInput.propTypes = {
  title: PropTypes.string,
  reward_ids: PropTypes.array,
  onClick: PropTypes.func
};

export default SlimInput;