import React from 'react';
import PropTypes from 'prop-types';

import StatCard from 'ui/StatCard/StatCard'

const OrganizationStats = ({ total_count, status }) => {

  return (
    <div className="custom-margin">
      
      <StatCard 
        type='number'
        highlight={total_count}
        title={`${status ? status.charAt(0).toUpperCase() + status.slice(1) : ''} Organizations`} />

    </div>
  )
};

OrganizationStats.propTypes = {
  total_count: PropTypes.number,
  status: PropTypes.string
};

export default OrganizationStats;