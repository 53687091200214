import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Preview from '../Preview';

import Button from 'ui/Button';
import FormSection from 'ui/FormSection';

import Details from './components/Details';
import ActionButton from './components/ActionButton';
import Theme from './components/Theme';
import Images from './components/Images';

const OrganizationForm = ({organization, onOrganizationChange, newOrganization, onUnsavedChanges, onSubmit, loading}) => {

  const [image, setImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);

  const images = [
    {type: "image", label: "Profile Image"},
    {type: "cover_image", label: "Cover Image"}
  ];

  const {logo_url, cover_image_url, title, slug, description, highlight_color, theme, newsletter_sender_created, custom_cta, custom_cta_action} = organization;
  
  const imageSrc = image ? window.URL.createObjectURL(image) : logo_url;
  const coverImageSrc = coverImage ? window.URL.createObjectURL(coverImage) : cover_image_url;

  const hiddenFileInputs = images.map(() => useRef(null));

  const handleChange = ({target: {name, value}}) => {
    onUnsavedChanges(true);

    let newOrg = {...organization, [name]: value};
    onOrganizationChange(newOrg);
  };

  const handleFileUpload = ({target: {files, name}}) => {
    if (files.length === 0) return;

    onUnsavedChanges(true);

    const file = files[0];
    let newOrganization = {...organization, [`${name}_file`]: file, [`${name}_filename`]: file.name};
    onOrganizationChange(newOrganization);

    if (name === 'image') {
      setImage(file)
    } else {
      setCoverImage(file);
    }
  };

  const handleColorChange = (color) => {
    onUnsavedChanges(true);
    let newOrganization = {...organization};
      newOrganization["highlight_color"] = color.hex;
      onOrganizationChange(newOrganization);
  };

  const isDisabled = newsletter_sender_created;

  return (
    <div className='organization-form-and-preview'>
      <div className='organization-form'>
        {newOrganization &&
          <div>
            <p>To create your first collection, we need more details about your community to set up an account!</p>
          </div>
        }
        <FormSection title={"Details"}>
          <Details
            title={title}
            slug={slug}
            description={description}
            isDisabled={isDisabled}
            handleChange={handleChange}
          />
        </FormSection>
  
        <FormSection title={"Theme"}>
          <Theme
            highlight_color={highlight_color}
            theme={theme}
            handleChange={handleChange}
            handleColorChange={handleColorChange}
          />
          <Images
            images={images}
            organization={organization}
            hiddenFileInputs={hiddenFileInputs}
            handleFileUpload={handleFileUpload}
          />

        </FormSection>

        <FormSection title={"Action Button"}>
          <ActionButton
            custom_cta={custom_cta}
            custom_cta_action={custom_cta_action}
            handleChange={handleChange}
          />
        </FormSection>

        <div className={ `new-form-field-footer` }>
          <Button loading={loading} fullwidth onClick={onSubmit}>
            {newOrganization ? 'Create' : 'Update'}
          </Button>
        </div>
      </div>

      <Preview organization={organization} coverImageSrc={coverImageSrc} imageSrc={imageSrc} />
    </div>
  )
};

OrganizationForm.propTypes = {
    organization: PropTypes.object,
    onOrganizationChange: PropTypes.func,
    newOrganization: PropTypes.bool,
    onUnsavedChanges: PropTypes.func,
    onSubmit: PropTypes.func,
    loading: PropTypes.bool,
};

export default OrganizationForm;
