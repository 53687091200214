import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useError } from 'utils/useErrorController';

import { Chip, Avatar } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';

const ImageUploadChip = ({ object, onObjectChange, onAttachmentDelete }) => {

  const [previewUrl, setPreviewUrl] = useState(null);

  const hiddenFileInput = useRef(null);
  const { loading, setLoading } = useError();

  const {file, image_url} = object;

  useEffect(() => {
    if (!file && !image_url) {
      setPreviewUrl(null);
      return;
    }

    if (image_url) {
      setPreviewUrl(image_url);
      return;
    }

    const blobUrl = URL.createObjectURL(file);
    setPreviewUrl(blobUrl);
    
    return () => {
      URL.revokeObjectURL(blobUrl);
      setPreviewUrl(null);
    };

  }, [file, image_url]);

  const handleFileUpload = (event) => {
    setLoading(true);
    
    if (!event.target.files) {
      setLoading(false);
      return;
    }

    const file = event.target.files[0];

    const newObject = {
      ...object,
      file,
      filename: file?.name || '',
      image_url: null
    };

    onObjectChange(newObject);

    setLoading(false);
  };

  const handleChipClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };

  const getChipLabel = () => {
    if (loading) return 'Uploading...';
    if (previewUrl) return 'Upload New Image';
    return 'Upload Image';
  };

  const renderAvatar = () => {
    if (!previewUrl) return null;

    return (
      <Avatar>
        <img 
          src={previewUrl}
          alt="Preview"
          style={{ width: '44px', height: '44px' }}
        />
      </Avatar>
    );
  };

  const chipStyle = {
    height: 50,
    '& .MuiChip-avatar': {
      width: 44,
      height: 44,
      borderRadius: 'var(--radius)',
      backgroundColor: 'var(--primaryBackgroundColor)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  }

  return (
    <>
      <Chip 
        label={getChipLabel()}
        variant="outlined"
        onDelete={(previewUrl) ? onAttachmentDelete : null}
        clickable
        onClick={handleChipClick}
        className="attachment-chip"
        avatar={renderAvatar()}
        icon={(!previewUrl) ? <UploadFileIcon /> : null}
        sx={chipStyle}
      />

      <input 
        ref={hiddenFileInput}
        type="file"
        accept="image/*"
        name="image"
        onChange={handleFileUpload}
        style={{ display: 'none' }}
      />
    </>
  );
};

ImageUploadChip.propTypes = {
  object: PropTypes.object,
  onObjectChange: PropTypes.func,
  onAttachmentDelete: PropTypes.func,
};

export default ImageUploadChip;