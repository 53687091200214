import React from 'react';
import PropTypes from 'prop-types';

import Video from './Video';
import Spotify from './Spotify';
import SoundCloud from './SoundCloud';

import { embedTypes } from "utils/pageElementsHelper";

import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from "@mui/material/ListItemText";

const Embeds = ({ profile, content, element_type }) => {

    const embedElementIcon = embedTypes.find(type => type.value === element_type).logo;  
    
    let Embed;
    switch (element_type) {
        case "youtube_embed":
            Embed = Video;
            break;
        case "spotify_embed":
            Embed = Spotify;
            break;
        case "soundcloud_embed":
            Embed = SoundCloud;
            break;
        default:
            Embed = null;
    }

  return (
    <>
        {profile ? (
            <Embed content={content} />
        ) : (
            <ListItemButton
                onClick={() => window.open(content, "_blank")}
                disableRipple
                disableGutters
                className='list-item-button'
            >
            <ListItemAvatar className="image-container">
                <img className="icon" src={embedElementIcon} />
            </ListItemAvatar>
            <ListItemText sx={{wordWrap: "break-word"}}  primary={content} />
            </ListItemButton>
        )}
    </>)
};

Embeds.propTypes = {
    profile: PropTypes.bool,
    content: PropTypes.string,
    element_type: PropTypes.string,
};

export default Embeds;