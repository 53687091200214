import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';

import Description from 'ui/text/Description';
import Button from 'ui/Button';
import Date from 'ui/Date';

import { getMinPrice, handleEventRedirect } from 'utils/events';

import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { t } from 'i18n/index';
import './styles.css';

const Compact = ({event, hidePrice=false, hideLocation=false}) => {

  const {title, start_date, location, ticketing, ticket_price, tickets, single, sessions} = event;
  const shownStartDate = sessions?.length > 0 ? sessions[0].start_date : start_date;
  let price = ticketing && single ? getMinPrice(tickets) : ticket_price;
  const secondary = [!hideLocation && location, !hidePrice && price]
                    .filter(Boolean)
                    .join('・');

  const history = useHistory();
  const {organizationSlug} = useParams();

  const handleRedirect = (e) => {
    e.stopPropagation();
    handleEventRedirect(event, organizationSlug, history);
  };

  return (
    <div className='ui-event-compact' onClick={(e) => handleRedirect(e)}>
        <Date date={shownStartDate} single={single} />

      <div className='details'>
        <Description bold>{title}</Description>
        {secondary && <Description secondary>{secondary}</Description>}
        {!single &&
          <div className='cta'>
            <EventRepeatIcon fontSize='small' />
            <Description secondary>{t('profile:sessionsCalendarCta')}</Description>
          </div>}
      </div>

      <Button highlighted small icon={ChevronRightIcon} onClick={(e) => handleRedirect(e)}></Button>
    </div>
  )
};

Compact.propTypes = {
    event: PropTypes.object,
    hidePrice: PropTypes.bool,
    hideLocation: PropTypes.bool,
};

export default Compact;
