import React from 'react';
import PropTypes from 'prop-types';

import isMobileDevice from 'utils/mobileAndTabletCheck';

import StyledDialog from './StyledComponents/StyledDialog';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Viewer } from '@toast-ui/react-editor';

const CustomModal = ({handleClose, customModalContent, dividers=true}) => {
  
  const {title, content, button, secondaryButton, supportMarkdown, fullScreen} = customModalContent;

  const showFullScreen = isMobileDevice() && fullScreen;
  
  return (
    <StyledDialog open={!!customModalContent} fullWidth={true} maxWidth='sm' fullScreen={showFullScreen}>
      <DialogTitle>
        {title}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          >
            <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={dividers} >
        {supportMarkdown ?
          <Viewer
              initialValue={content}
              extendedAutolinks={true}
              linkAttributes={{
                  target: '_blank',
                  rel: 'noopener noreferrer',
              }}
          />
        :
        <DialogContentText>
          {content}
        </DialogContentText>}
      </DialogContent>
      {button &&
        <DialogActions sx={{display: 'flex'}}>
          {button}
          {secondaryButton}
        </DialogActions>}
    </StyledDialog>
  );
};

CustomModal.propTypes = {
  handleClose: PropTypes.func,
  customModalContent: PropTypes.object,
  dividers: PropTypes.bool
};

export default CustomModal;