import React from "react";
import PropTypes from "prop-types";

import StatCard from "ui/StatCard/StatCard";
import { formatPrice } from "utils/rewards";

const Stats = ({ tickets }) => {
  const ticket = tickets?.[0];

  const stat1 = ticket ? formatPrice(ticket.total_revenue, ticket.currency) : 0;

  const stat2 = ticket ? ticket.total_count : 0;

  return (
    <div className="ui-stats-grid">
      <StatCard
        type="number"
        highlight={stat1}
        title="Total Revenue"
      />

      <StatCard
        type="number"
        highlight={stat2}
        title="Issued Tickets"
      />
    </div>
  );
};

Stats.propTypes = {
  tickets: PropTypes.array,
};

export default Stats;
