import React, { useState } from "react";
import PropTypes from "prop-types";

import { IconButton, InputAdornment, Stack, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";

import { ChromePicker } from "react-color";

const Theme = ({ highlight_color, theme, handleChange, handleColorChange }) => {
  const [showColorPicker, setShowColorPicker] = useState(false);

  const handleClickShowColorPicker = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowColorPicker(!showColorPicker);
  };

  const handleThemeChange = (event, newTheme) => {    
    if (newTheme !== null) {
      handleChange({
        target: {
          name: "theme",
          value: newTheme,
        },
      });
    }
  };

  return (
    <>
      <Stack
        direction="row"
        spacing={0.5}
        alignItems="center"
        alignSelf="center"
        sx={{ mb: 2, width: '100%' }}
      >
        <ToggleButtonGroup
          value={theme}
          exclusive
          onChange={handleThemeChange}
          fullWidth
        >
          <ToggleButton value="light">
            <LightModeIcon sx={{ mr: 1 }} />
            Light
          </ToggleButton>
          <ToggleButton value="dark">
            <DarkModeIcon sx={{ mr: 1 }} />
            Dark
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      
      <TextField
        fullWidth
        size="small"
        name="highlight_color"
        label="Highlight Color"
        color="warning"
        value={highlight_color || ""}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle color picker visibility"
                onClick={() =>
                  handleChange({
                    target: { name: "highlight_color", value: "#F75201" },
                  })
                }
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
              >
                <RotateLeftIcon />
              </IconButton>
              <IconButton
                aria-label="toggle color picker visibility"
                onClick={handleClickShowColorPicker}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
              >
                <ColorLensIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      {showColorPicker && (
        <div className="color-picker">
          <ChromePicker
            disableAlpha={true}
            color={highlight_color}
            onChangeComplete={handleColorChange}
          />
        </div>
      )}
    </>
  );
};

Theme.propTypes = {
  highlight_color: PropTypes.string,
  theme: PropTypes.string,
  handleChange: PropTypes.func,
  handleClickShowColorPicker: PropTypes.func,
  showColorPicker: PropTypes.bool,
  handleColorChange: PropTypes.func,
};

export default Theme;
