import React from 'react';
import PropTypes from 'prop-types';

import { Viewer } from '@toast-ui/react-editor';

const Text = ({ content, profile }) => {

  return (
    <div style={{margin: profile ? "20px 0px" : "0px 20px 0px 0px", width: "100%"}}>
      <Viewer
        key={content}
        initialValue={content}
        extendedAutolinks={true}
        linkAttributes={{
          target: '_blank',
          rel: 'noopener noreferrer',
        }}
      />
    </div>
  );
};

Text.propTypes = {
  content: PropTypes.string,
  profile: PropTypes.bool,
};

export default Text;