import React from 'react';
import PropTypes from 'prop-types';

import { DialogTitle } from '@mui/material';

import { t } from 'i18n/index';

const TITLE_KEYS = {
  login: 'authForms:authModal:loginTitle',
  store: 'authForms:authModal:storeSignUpTitle',
  event: 'authForms:authModal:eventSignUpTitle',
  default: 'authForms:authModal:defaultSignUpTitle'
};

const Title = ({ logIn, context }) => {
  
  const getTitleKey = () => {
    if (logIn) return TITLE_KEYS.login;
    return TITLE_KEYS[context] || TITLE_KEYS.default;
  };

  return <DialogTitle>{t(getTitleKey())}</DialogTitle>;
};

Title.propTypes = {
    logIn: PropTypes.bool,
    context: PropTypes.string,
};

export default Title;