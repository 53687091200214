import React, { useEffect } from 'react';
import NewsletterResource from 'resources/organization/NewsletterResource';
import { useParams } from 'react-router-dom';

import DataSelectionTable from 'components/DataTable/DataSelectionTable';

import Alert from 'ui/Alert';

import { newslettersColumns } from 'utils/tables/columns/newslettersColumns';
import usePaginationAndFilteringController from 'utils/usePaginationAndFilteringController';

import moment from 'moment';

const NewslettersTabContent = () => {

  const params = useParams();
  const organizationId = parseInt(params.organizationId);

  const {
    objectState, 
    fetchData,
    handlePageChange
  } = usePaginationAndFilteringController({
      fetchDataResource: NewsletterResource,
      fetchDataParams: { organization_id: organizationId },
  })

  useEffect(() => {
    fetchData();
  }, []);

  const getLastRefreshDay = () => {
    const now = moment();
    const isPastRefreshTime = now.hour() > 1 || (now.hour() === 1 && now.minute() >= 30);

    return isPastRefreshTime ? 'today' : 'yesterday';
  };
  
  const lastRefreshDay = getLastRefreshDay();

  return (
    <div style={{ flexGrow: 1 }}>
      <Alert primaryText={`Newsletter stats are updated once per day, and might take up to 24h to be refreshed. Last refresh was ${lastRefreshDay} at 1:30 AM.`} />
      <DataSelectionTable records={objectState.filteredObjects}
                          columns={newslettersColumns}
                          serverPaginationProps={{
                            paginationServer: true,
                            paginationTotalRows: objectState.totalCount,
                            page: objectState.page,
                            onChangePage: handlePageChange
                          }}
                          subHeader={false}
      />
    </div>
  );
};

export default NewslettersTabContent;