import React from 'react';

import checkSign from 'assets/images/checkSign.png';

import { useToast } from 'utils/context/ToastContext';

import './styles.css';

const IMAGES = {
  success: checkSign,
};

const ImageToast = () => {

  const { showImageToast, severity, message } = useToast();

  if (!showImageToast) return null;

  return (
    <>
      <div className="toast-backdrop" />
      <div className="toast-image-container">
        <img 
          src={IMAGES[severity]} 
          alt={message}
          className="toast-image"
        />
        <div className="toast-message">{message}</div>
      </div>
    </>
  );
};

export default ImageToast; 