import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useController, useSuspense } from '@rest-hooks/react';

import RewardResource from 'resources/organization/RewardResource';

import Actions from './Actions';
import Content from './Content';
import EmailSelectionContent from './EmailSelectionContent';
import handleImportValidation from './handleImportValidation';
import handleSingleMemberValidation from './handleSingleMemberValidation';
import { createFormData } from './createFormData';
import Stepper from './Stepper';

import EmailEditorContent from 'components/EmailEditor/Content';
import ErrorModal from 'components/ErrorModal';
import EmailReviewContent from 'components/EmailReviewContent';

import { useToast } from 'utils/context/ToastContext';
import { getButtonLabel, getEmptySubscription, getResource, getTitle, getToastContent } from 'utils/manualSubscriptionsHelpers';
import { activePasses } from 'utils/filterFunctions';
import { validateStringField } from 'utils/validations/validateStringField';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent } from '@mui/material';

const NewSubscriptionModal = ({ open, loading, setLoading, onClose, onError, fetchData, record, validate}) => {

  const params = useParams();
  const organizationId = parseInt(params.organizationId);

  const rewards = useSuspense(RewardResource.list(), {organization_id: organizationId});
  const tiers = rewards.filter(activePasses)
                       .map(({ id, title, sellable }) => ({ identifier: title, id, sellable }));

  const emptySubscription = getEmptySubscription(tiers);
  const tierId = record && tiers.find((tier) => tier.identifier === record.tier).id;
  
  const [activeSubscription, setActiveSubscription] = useState({...emptySubscription, ...record, tier: tierId});
  const [email, setEmail] = useState({content: "", subject: ""});
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState([]);

  const { showToast } = useToast();
  const {fetch} = useController();

  const updatingSubscription = !!record;

  const actionConfig = {
    title: getTitle(updatingSubscription, open),
    button: getButtonLabel(updatingSubscription, open, activeSubscription.send_welcome_email, step),
    toast: getToastContent(updatingSubscription, open),
    perform: getResource(updatingSubscription),
  };

  const handleValidation = open === 'single' ? handleSingleMemberValidation : handleImportValidation;

  const handleResponse = async (id, counter, errors) => {
    const success = (id && !errors && !counter) || (counter && errors.length == 0);
    if (success) {
      handleSuccessfulAction();

    } else if (errors[0][0].includes("encoding")){
      onError(errors[0][0]);
      handleClose();

    } else {      
      fetchData()  

      if (counter) {
        errors.unshift(`${counter} ${counter === 1 ? 'member' : 'members'} added.`);
      }

      setErrors(errors);
      setLoading(false);
    }
  };

  const handleConfirm = () => {
    setStep(4);
  };
  
  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (activeSubscription.send_welcome_email === 'personalized') validateEmail(email);
      const formData = createFormData(activeSubscription, email);
      const {id, counter, errors} = await fetch(actionConfig['perform'], {organization_id: organizationId, id: activeSubscription.id}, formData);
      handleResponse(id, counter, errors);
    } catch (error) {
      onError(error);
    }
  };

  const validateEmail = (email) => {
    validateStringField(email.subject, "the subject of the email");
    validateStringField(email.content, "the body of the email");
  };
  
  const handleInitialAction = () => {
    try {
      validate(activeSubscription, handleValidation);
      if (updatingSubscription) {
        handleSubmit();
      } else {
        setLoading(false);
        setStep(2);
      }
    } catch (error) {
      onError(error);
    }
  };

  const handleEmailSelection = async () => {
    if (activeSubscription.send_welcome_email !== 'personalized') {
      handleSubmit();
    } else {
      setStep(3);
    }  
  };

  const handleSuccessfulAction = async () => {
    fetchData();
    showToast('success', actionConfig['toast']);
    handleClose();
  };

  const handleClose = () => {
    onClose();
    setLoading(false);
    setActiveSubscription(emptySubscription);
    setErrors([])
  };

  const handleBack = () => {
      setStep(step - 1);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth maxWidth='sm'
      > 
        <DialogTitle id="confirm-dialog">{actionConfig['title']}</DialogTitle>
        
        <DialogContent dividers>
          {!updatingSubscription && <Stepper step={step} multiple={open === 'import'}/>}

          {step === 1 &&
            <>
              <Content 
                open={open}
                subscription={activeSubscription}
                onSubscriptionChange={setActiveSubscription}
                tiers={tiers}
                updatingSubscription={updatingSubscription} 
              />
              <Actions
                loading={loading}
                onClose={step > 1 ? handleBack : handleClose}
                onClick={handleInitialAction}
                action={actionConfig['button']}
                secondaryAction={"Cancel"}
                disabled={tiers.length === 0}
              />
            </>
          }

          {step === 2 &&
            <>
              <EmailSelectionContent subscription={activeSubscription} onSubscriptionChange={setActiveSubscription} />
              <Actions 
                loading={loading}
                onClose={step > 1 ? handleBack : handleClose} 
                onClick={handleEmailSelection} 
                action={actionConfig['button']} 
                secondaryAction={"Back"}
                disabled={tiers.length === 0 || activeSubscription.send_welcome_email === null || activeSubscription.send_welcome_email === undefined}
                />
            </>
          }

          {step === 3 &&
            <>
              <EmailEditorContent object={email} onObjectChange={setEmail} />
              <Actions 
                loading={loading}
                onClose={handleBack}
                onClick={handleConfirm}
                action={"Review"}
                secondaryAction={"Back"}/>
            </>
          }

          {step === 4  &&
            <>
              <EmailReviewContent content={email?.content} subject={email?.subject} />
              <Actions 
                loading={loading} 
                onClose={handleBack}
                onClick={handleSubmit}
                action={open === 'single' ? 'Add & Send' : 'Import & Send'}
                secondaryAction={"Back"}
                />
            </>}
            
        </ DialogContent >
      </Dialog>

      <ErrorModal message={errors} open={errors && errors.length > 0} onClose={handleClose}/>
    </> 
  );
};

NewSubscriptionModal.propTypes = {
  open: PropTypes.string,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  onClose: PropTypes.func,
  onError: PropTypes.func,
  fetchData: PropTypes.func,
  record: PropTypes.object,
  validate: PropTypes.func
};

export default NewSubscriptionModal;