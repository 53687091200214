import React from 'react';

import { DialogTitle } from '@mui/material';

const Title = () => {
  
  return (
    <DialogTitle>
      Insert Custom Button
    </DialogTitle>
  );
};

export default Title;