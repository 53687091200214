import { validateEmail } from "utils/validations/validateEmail";
import { priceRegex } from "utils/pricingsConsts";

export const handleValidation = (offer) => {
    const {id, title, description, reward_ids, notification_list, unit_price, image_files, category, event_id, free, content_text, min_amount} = offer;
    validateTitle(title);
    if (category !== "ticket") validateDescription(description);
    validateEligibilityCriteria(reward_ids);
    validateNotificationList(notification_list);
    validatePrice(unit_price, free);
    if (category !== "ticket") validateImages(image_files);
    if (category === "ticket") validateEvent(event_id);
    if (!id && content_text) validateContentText(content_text);
    if (min_amount) validateMinAmount(min_amount);
};

const validateTitle = (title) => {
    if (title.trim() === ""){
        throw "Please provide a title";
    }
};

const validateDescription = (description) => {
    if (description.trim() === ""){
        throw "Please provide a description";
    }
};

const validateEligibilityCriteria = (reward_ids) => {
    if (!reward_ids || reward_ids.length === 0) {
        throw "Please specify in Eligibility Criteria, who you would like to be able to redeem this perk";
    }
};

const validateNotificationList = (notification_list) => {
    const isArray = Array.isArray(notification_list);
    if (isArray || !notification_list) {
        return;
    }

    const emailsArray = notification_list.replace(/\s+/g, '').split(',');
    if (emailsArray === undefined || emailsArray === null || emailsArray.length === 0) {
        throw errorMessage;
    } else {
        emailsArray.forEach((email) => {
            validateEmail(email, null, errorMessage);
        });
    }
};

const validatePrice = (price, free) => {
    if (!price && free) {
        return
    } else if (!price && !free) {
        throw "Please provide a price or indicate that you would like this to be free";
    } else if (!priceRegex.test(price)) {
        throw "Price must be in this format: 123456.12";
    }
};

const validateImages = (image_files) => {
    if (!image_files || image_files.length === 0) {
        throw "Please upload at least 1 image";
    }
};

const validateEvent = (event_id) => {
    if (!event_id) {
        throw "Please associate an event to this ticket";
    }
};

const validateContentText = (content_text) => {
    if (content_text.length > 1000) {
        throw "Content text must be less than 1000 characters";
    }
};

const validateMinAmount = (min_amount) => {
    if (min_amount < 1) {
        throw "Minimum amount must be higher than 0";
    }
};

const errorMessage = "Please enter one or more valid email addresses, separated by commas.";
