export const createFormData = (pageElement) => {
    const formData = new FormData();

    const {element_type, reward_ids, link_title, content, file, settings, image_url} = pageElement;
    
    const fields = [
        { key: 'element_type', value: element_type },
        { key: 'reward_ids', value: reward_ids },
        { key: 'link_title', value: link_title },
    ];
    
    if (settings)  fields.push({ key: 'settings', value: JSON.stringify(settings) });
    if (content)   fields.push({ key: 'content', value: content});
    if (file)      fields.push({ key: 'image', value: file });
    if (image_url) fields.push({ key: 'image_url', value: image_url });

    fields.forEach(field => {
        formData.append(field.key, field.value);
    });
    
    return formData;
};