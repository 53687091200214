import React from "react";
import PropTypes from "prop-types";

import NumberTextField from "components/NumberTextfield";

const SupplyLimit = ({supply_limit, onChange}) => {

  const label = "Total stock. Leave empty for unlimited"
                      
  return (
      <NumberTextField
        fullWidth
        size="small"
        color="warning"
        name="supply_limit"
        value={supply_limit || ''}
        onChange={onChange}
        label={label}
        />
  );
};

SupplyLimit.propTypes = {
    supply_limit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
};

export default SupplyLimit;