import React from 'react';
import PropTypes from 'prop-types';

import { InputAdornment, TextField } from '@mui/material';

const Details = ({title, slug, description, isDisabled, handleChange}) => {
  
  return (
    <>
      <TextField
        fullWidth
        size="small"
        name="title"
        label="Display Name *"
        color="warning"
        value={title || ''}
        onChange={handleChange}
      />

      <TextField
        fullWidth
        size="small"
        name="slug"
        label="Username *"
        color="warning"
        value={slug || ''}
        disabled={ isDisabled }
        helperText={isDisabled && "Please contact us if you would like to update your Username."}
        InputProps={{
          startAdornment: <InputAdornment position="start">membrz.club/</InputAdornment>,
        }}
        onChange={handleChange}
      />

      <TextField
        fullWidth
        size="small"
        name="description"
        label="Description"
        multiline
        rows={3}
        color="warning"
        value={description || ''}
        onChange={handleChange}
      />
    </>
  );
};

Details.propTypes = {
  title: PropTypes.string,
  slug: PropTypes.string,
  description: PropTypes.string,
  isDisabled: PropTypes.bool,
  handleChange: PropTypes.func,
};

export default Details;