export const defaultTheme = {
  // Global properties
  font_family: "Inter",
  highlight_color: "#F75201",
  primary_text_color: "#101828",
  secondary_text_color: "#667085",
  
  // Button colors
  primary_button_color: "#000000",
  secondary_button_color: "#FFFFFF",
  button_text_color: "#FFFFFF",
  
  // Background/Cards colors
  primary_background_color: "#FFFFFF",
  secondary_background_color: "#F4F4F4",
  border_color: "#DADADA",
  
  // Stripe theme
  stripe_theme: "stripe",
};

export const darkTheme = {
  // Global properties
  font_family: "Inter",
  highlight_color: "#F75201",
  primary_text_color: "white",
  secondary_text_color: "color-mix(in srgb, white 70%, transparent)",
  
  // Button colors
  primary_button_color: "#F4F4F4",
  secondary_button_color: "transparent",
  button_text_color: "black",
  
  // Background/Cards colors
  primary_background_color: "#23272a",
  secondary_background_color: "#2c2f33",
  border_color: "#36393e",
  
  // Stripe theme
  stripe_theme: "night",
};

export function getContainerClassName(isLogin, classLayout) {
  const additionalClasses = [];

  if (isLogin) {
    additionalClasses.push("login");
  } else {
    additionalClasses.push("public");
  }

  additionalClasses.push(classLayout);
  return additionalClasses.join(' ');
}
