import React from 'react';
import PropTypes from 'prop-types';

import ProfileImages from 'ui/ProfileImages';

import { darkTheme, defaultTheme } from 'utils/themes';

import './styles.css';

const Preview = ({organization, coverImageSrc, imageSrc}) => {

  const {slug, title, highlight_color = '#F75201', custom_cta, description, theme} = organization;

  const selectedTheme = theme === 'dark' ? darkTheme : defaultTheme;
  const {primary_background_color, primary_text_color, secondary_text_color} = selectedTheme;

  return (
    <div 
      className='profile-preview' 
      style={{
        backgroundColor: primary_background_color,
      }}>
      <div className='browser-skeleton'>
        <span></span>
        <span></span>
        <span></span>
        <div className='url-bar'>
          membrz.club/{slug}
          <em className='preview'>preview</em>
        </div>
      </div>

      <ProfileImages coverImageSrc={coverImageSrc} profileImageSrc={imageSrc} primaryBackgroundColor={primary_background_color}/>

      <h1 className="ui-title bold" style={{color: primary_text_color }}>{title}</h1>
      <p className='ui-description' style={{color: secondary_text_color }}>200 members</p>

      <button style={{borderColor: highlight_color, backgroundColor: highlight_color}} className='ui-button' onClick={(e) => e.preventDefault()}>
          {custom_cta || 'Join the club'}
      </button>

      <p className='ui-description' style={{color: primary_text_color }}>{description}</p>
    </div>
  );
};

Preview.propTypes = {
  organization: PropTypes.object,
  imageSrc: PropTypes.string,
  coverImageSrc: PropTypes.string
};

export default Preview;
