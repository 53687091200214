import React from 'react';
import PropTypes from 'prop-types';

import ContentComposer from './ContentComposer';
import LinkComposer from './LinkComposer';
import SocialSelector from './SocialSelector';
import CalendarComposer from './CalendarComposer';
import EmbedSwitch from './EmbedSwitch';

import LockSelector from 'components/LockSelector';

import FormSection from 'ui/FormSection';

import { isSocialElement, isEmbeddedableElement, detectEmbedType, possibleSettings, isEmbeddedElement } from 'utils/pageElementsHelper';
import { useMetadataFetcher } from 'utils/useMetadataFetcher';
import { validateUrl } from 'utils/validations/validateUrl';
import { useError } from 'utils/useErrorController';

import { DialogContent } from '@mui/material';

const Content = ({ pageElement, setPageElement, passes }) => {

  const {id, element_type, reward_ids, content, settings} = pageElement;

  const showSocialSelector   = element_type === "social" || isSocialElement(element_type);
  const showContentComposer  = element_type !== "calendar";
  const showLockSelector     = element_type === "link" || isEmbeddedableElement(content);
  const showEmbedSwitch      = isEmbeddedableElement(content) && !pageElement.id;

  const {
    fetchedMetadata,
    setFetchedMetadata,
    fetchMetadata,
    updateElementWithMetadata,
    validForMetadataFetch,
  } = useMetadataFetcher();

  const { loading, handleError } = useError();

  const updateElementType = (newPageElement, value) => {
    if (!newPageElement.element_type) {
      newPageElement.element_type = isEmbeddedableElement(value) ? `${detectEmbedType(value)}_embed` : "link";
    }

    if (!newPageElement.id && !value && (newPageElement.element_type === "link" || isEmbeddedElement(newPageElement.element_type))) {
      newPageElement.element_type = "";
    }

    return newPageElement;
  };

  const handlePasteEvent = async (value, newPageElement) => {
    await fetchAndAssignMetadata(value, newPageElement);
    setFetchedMetadata(true); 
  };

  const onContentBlur = async () => {
    if (fetchedMetadata) {
      // Reset fetchedMetadata so that metadata can be fetched again on next blur
      setFetchedMetadata(false);
      return;
    }

    await fetchAndAssignMetadata(content, pageElement);
  };

  const fetchAndAssignMetadata = async (content, pageElement) => {
    try {

      const newPageElement = { ...pageElement };
      const { element_type, id } = newPageElement;

      if (!validForMetadataFetch(content, element_type, id)) return;

      validateUrl(content, "link");
      const metadata = await fetchMetadata(content);

      const updatedElement = updateElementWithMetadata(newPageElement, metadata);
      setPageElement(updatedElement);

    } catch (error) {
      handleError(error);
    }
  }

  const handleChange = async ({target: {name, value}, nativeEvent}) => {

    let newPageElement = { ...pageElement };

    // If name is in the possibleSettings array, update the settings object
    if (possibleSettings.includes(name)) {
      newPageElement.settings = { ...settings, [name]: value };
    } else {
      newPageElement[name] = value;
    }

    if (name === "content") {
      newPageElement = updateElementType(newPageElement, value);
    }

    // Remove file, image_url and title if element_type is not link
    if (newPageElement.element_type !== "link") {
      newPageElement["file"] = null;
      newPageElement["filename"] = "";
      newPageElement["image_url"] = null;
      newPageElement["link_title"] = "";
    }

    setPageElement(newPageElement);

    if (name === "content" && nativeEvent?.inputType === "insertFromPaste") {
      await handlePasteEvent(value, newPageElement);
    }
  };

  const onAttachmentDelete = () => {
    setPageElement({ 
      ...pageElement, 
      image_url: null,
      filename: null,
      file: null
    });
  };

  return (
    <DialogContent dividers>
      <FormSection slim>
        {!id && showSocialSelector &&
          <SocialSelector
            element_type={element_type}
            onChange={handleChange}
          />
        }
        {showContentComposer &&
          <ContentComposer
            object={pageElement}
            onObjectChange={setPageElement}
            onChange={handleChange}
            onBlur={onContentBlur}
            loading={loading}
          />
        }
        { element_type === "calendar"  &&
          <CalendarComposer
            settings={settings}
            onChange={handleChange}
          />
        }
        { showEmbedSwitch &&
          <EmbedSwitch
            object={pageElement}
            onChange={handleChange}
          />
        }
        { element_type === "link"  &&
          <LinkComposer
            object={pageElement}
            onObjectChange={setPageElement}
            onChange={handleChange}
            loading={loading}
            onAttachmentDelete={onAttachmentDelete}
          />
        }
        {showLockSelector &&
          <LockSelector
            reward_ids={reward_ids}
            onChange={handleChange}
            passes={passes} 
            slim
          />
        }
      </FormSection>
    </DialogContent>
  );
};

Content.propTypes = {
    pageElement: PropTypes.object,
    setPageElement: PropTypes.func,
    passes: PropTypes.array,
    orgHasCalendar: PropTypes.bool
};

export default Content;
