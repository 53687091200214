import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import PointsChip from "../Navbar/PointsChip";

import { StyledList } from "components/StyledComponents/StyledList";
import { StyledDivider } from "components/StyledComponents/StyledDivider";

import CoursesList from "utils/localStorage/CoursesList";

import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import SettingsIcon from "@mui/icons-material/Settings";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import { t } from "i18n";


const Menu = ({ anchor, label, points, action, toggleDrawer, userName }) => {
  const history = useHistory();

  const redirectTo = (link) => {
    history.push(link);
  };

  const coursesList = CoursesList.read();
  const userIsManager = coursesList && coursesList.length > 0;

  const menuItems = [
    {label: label, action: action, icon: PowerSettingsNewIcon, divider: !userIsManager && userName}
  ];

  if (userIsManager) {
    menuItems.unshift(
      { label: t("literal:manageClub"), action: () =>   redirectTo(`/organizations/${coursesList[0].organization_id}`), icon: ManageAccountsIcon, divider: true }
    );
  }

  if (userName){
    menuItems.unshift(
      {label: t('literal:profile'), action: () => redirectTo('/profile'), icon: LocalPoliceIcon },
      {label: t('literal:tickets'), action: () => redirectTo('/tickets'), icon: LocalActivityIcon },
      {label: t('literal:billingHistory'), action: () => redirectTo('/orders'), icon: ShoppingBasketIcon },
      {label: t('literal:preferences'), action: () => redirectTo('/settings/details'), icon: SettingsIcon }
    );
  }

  const showPoints = points !== null && !!userName;

  return (
    <Box
      sx={{
        width: 250,
        backgroundColor: "var(--primaryBackgroundColor)",
        height: "100%",
        minHeight: "100vh",
      }}
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {showPoints && <PointsChip points={points} />}

      <StyledList>
        {menuItems.map((item) => (
          <>
            {item.divider && (
              <StyledDivider />
            )}
            <ListItem key={item.label} disablePadding>
              <ListItemButton onClick={item.action}>
                <ListItemIcon>
                  <item.icon />
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          </>
        ))}
      </StyledList>
    </Box>
  );
};

Menu.propTypes = {
  anchor: PropTypes.string,
  label: PropTypes.string,
  points: PropTypes.number,
  action: PropTypes.func,
  toggleDrawer: PropTypes.func,
  userName: PropTypes.string,
};

export default Menu;