import React from 'react';
import PropTypes from 'prop-types';

import MarkdownEditor from 'components/MarkdownEditor';

const Body = ({ email, onChange, onEmailReset }) => {

  return (
    <MarkdownEditor
            object={email}
            setObject={onChange}
            attribute={"body"}
            placeholder={"Email Body"}
            onEmailReset={onEmailReset}
            />
  )
};

Body.propTypes = {
  email: PropTypes.object,
  onChange: PropTypes.func,
  onEmailReset: PropTypes.func,
};

export default Body;