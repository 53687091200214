import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';
import Subtitle from 'ui/text/Subtitle';

import { t } from "i18n/index";

const BuyButton = ({onAction, loading, disableBuyButton, priceWithCurrency, discountPriceWithCurrency, available, timeToStart, isTicket}) => {

  const priceContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '6px'
  };

  return (
    <>
      {isTicket ?
        <div style={{display: 'flex', flexDirection: 'column', gap: '8px', width: '100%'}}>
            <div style={priceContainerStyle}>
              <Subtitle struck={discountPriceWithCurrency} small>
                {priceWithCurrency}
              </Subtitle>
              {discountPriceWithCurrency && <Subtitle small>{discountPriceWithCurrency}</Subtitle>}
            </div>

          <Button onClick={ onAction } highlighted fullwidth smallPadded loading={loading} disabled={disableBuyButton}>
            {available 
              ? t('literal:buyNow')
              : timeToStart 
                ? t('literal:availableIn', { timeToStart })
                : t('literal:soldOut')}
          </Button>
        </div>
      :
        <Button onClick={ onAction } fullwidth loading={loading} disabled={disableBuyButton} highlighted>
          {available 
            ? <div className='button-content'>
                <div style={priceContainerStyle}>
                  <span style={{textDecoration: discountPriceWithCurrency ? 'line-through' : 'none'}}>
                    {priceWithCurrency}
                  </span>
                  {discountPriceWithCurrency && <span>{discountPriceWithCurrency}</span>}
                </div>
                <span>{t('literal:buyNow')}</span>
              </div>
            : timeToStart
              ? <span>{t('literal:availableIn', {timeToStart})}</span>
              : <span>{t('literal:soldOut')}</span>
          }
        </Button>
      }
    </>
  );
};

BuyButton.propTypes = {
  onAction: PropTypes.func,
  loading: PropTypes.bool,
  disableBuyButton: PropTypes.bool,
  priceWithCurrency: PropTypes.string,
  discountPriceWithCurrency: PropTypes.string,
  available: PropTypes.bool,
  timeToStart: PropTypes.string,
  isTicket: PropTypes.bool
};

export default BuyButton;
