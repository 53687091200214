import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useController, useSuspense } from '@rest-hooks/react';

import ManagerResource from 'resources/organization/ManagerResource';

import DataSelectionTable from 'components/DataTable/DataSelectionTable';
import ConfirmModal from 'components/ConfirmModal';

import AddMemberModal from './AddMemberModal';

import Button from 'ui/Button';

import { membersColumns } from 'utils/tables/columns/membersColumns';
import { useToast } from 'utils/context/ToastContext';
import { useError } from 'utils/useErrorController';

const MembersForm = () => {

  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [selectedManager, setSelectedManager] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const { loading, setLoading, handleError } = useError();
  const { showToast } = useToast();
  const { fetch } = useController();
  const params = useParams();
  
  const organizationId = parseInt(params.organizationId);
  const managers = useSuspense(ManagerResource.list(), { organization_id: organizationId });

  const handleInviteMember = useCallback(
      async (email) => {
        try {
          setLoading(true);
          const { id } = await fetch(ManagerResource.create(), { organization_id: organizationId }, { email: email });

          // success!
          if (id){
            await fetch(ManagerResource.list(), { organization_id: organizationId });
            showToast('success', `New organization member invited`);
            setShowAddMemberModal(false);
            setLoading(false);
          }
        }  catch (error) {
          handleError(error);
        }
      },
      [fetch, organizationId],
  );

  const handleDeleteRequest = (manager) => {
    setSelectedManager(manager);
    setConfirmDelete(true);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await fetch(ManagerResource.delete(), { id: selectedManager.id, organization_id: organizationId });
      await fetch(ManagerResource.list(), { organization_id: organizationId });
      setLoading(false);
    } catch (error) {
      handleError(error);
    }
  };

  const showConfirmModal = selectedManager && confirmDelete;
  
  return (
    <div>
          <p>Collaborators can manage your memberships, content and profile.</p>

          <DataSelectionTable records={managers}
                            columns={membersColumns(handleDeleteRequest, loading)}
          />

          <Button minwidth loading={loading} onClick={ () => setShowAddMemberModal(true) }>Add Member</Button>

          <AddMemberModal open={ showAddMemberModal }
                          onSubmit={ handleInviteMember }
                          onClose={ () => setShowAddMemberModal(false) } />

          {showConfirmModal && 
            <ConfirmModal
              title={`Restrict ${selectedManager.name} access?`}
              open={confirmDelete}
              setOpen={setConfirmDelete}
              onConfirm={ handleDelete }
            >
              This action will delete {selectedManager.name} from your collaborators list and they will no longer be able to manage your memberships, content and profile. Would you like to continue?
            </ConfirmModal>}
        </div>
  );
};

MembersForm.propTypes = {
    managers: PropTypes.object,
    organizationId: PropTypes.number,
    handleError: PropTypes.func,
};

export default MembersForm;
