import { useState } from 'react'
import PropTypes from 'prop-types'
import { useError } from './useErrorController'
import { useController } from '@rest-hooks/react';

const defaultObjectState = {
    filteredObjects: [],
    query: '',
    totalCount: 0,
    page: 1,
};

const usePaginationAndFilteringController = ({fetchDataResource, fetchDataParams}) => {
    const [objectState, setObjectState] = useState(defaultObjectState)
    const {setLoading, handleError} = useError();
    const {fetch} = useController();
    
    const fetchData = async (page = 1, filteringParams = {}) => {
      try {
        setLoading(true);
        const newFilteredObjects = await fetch(fetchDataResource.list(), {
          ...fetchDataParams,
          page: page,
          ...filteringParams,
        });

        setObjectState((prevState) => ({
          ...prevState,
          filteredObjects: newFilteredObjects,
          page: page,
          filteringParams: filteringParams,
          totalCount: newFilteredObjects.length > 0 ? newFilteredObjects[0].total_count : 0,
        }));
        setLoading(false);
      } catch (error) {
        handleError(error);
      }
    };
    
    const handlePageChange = (page) => {
      fetchData(page, objectState.filteringParams);
    };
  
    const handleQueryChange = (newQuery) => {

      //if we only use filteringParams.query, because it is updated on change and after fetch, those changes are shown to user on search input field
      setObjectState((prevState) => ({
        ...prevState,
        query: newQuery,
      }));
  
      if (newQuery.length > 2 || newQuery.length === 0) {
        const newFilteringParams = { ...objectState.filteringParams };
        newQuery
          ? (newFilteringParams.query = newQuery)
          : delete newFilteringParams.query;
        fetchData(1, newFilteringParams);
      }
    };

    const handleFilterChange = (key, identifier) => {

      //update filteringParams twice (now and after fetch) to show selected filter from the moment it is selected rather than just after fetch
      //or create separate state vars for each filter and update those instead like we did with query
    
      let newFilteringParams = { ...objectState.filteringParams };

      if (Array.isArray(key)) {
        key.forEach((k, index) => {
          newFilteringParams = updateFilteringParams(newFilteringParams, k, identifier?.[index]);
        });
      } else {
        newFilteringParams = updateFilteringParams(newFilteringParams, key, identifier);
      }

      fetchData(1, newFilteringParams);
    };

    const updateFilteringParams = (params, key, identifier) => {
      identifier
        ? (params[key] = identifier)
        : delete params[key];
      
      return params
    };

    return {
        objectState,
        fetchData,
        handleFilterChange,
        handleQueryChange,
        handlePageChange
  };
};

usePaginationAndFilteringController.propTypes = {
    fetchDataRequest: PropTypes.func,
};

export default usePaginationAndFilteringController