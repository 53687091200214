import React from 'react';
import PropTypes from 'prop-types';

import { useHistory, useParams } from 'react-router-dom';
import { useSuspense } from '@rest-hooks/react';

import ContentResource from 'resources/Profile/ContentResource';
import TagResource from 'resources/Profile/TagResource';

import Label from 'ui/text/Label';

import { useError } from 'utils/useErrorController';
import formattedDate from 'utils/formattedDate';

import StyledChip from 'components/StyledComponents/StyledChip';

const Tags = ({ tag_ids, created_at, setPosts }) => {

    const createdAt = formattedDate(created_at, 'fromNow');
    const showTags = tag_ids && tag_ids.length > 0;
  
    const {organizationSlug} = useParams();
    const organizationTags = useSuspense(TagResource.list(), {organization_slug: organizationSlug, tag_type: 'post'});
  
    const history = useHistory();
  
    const {handleError} = useError();
    const onTagSelect = async (tag) => {
      try {
        history.push(`?tag=${tag.name}`);
        const posts = await fetch(ContentResource.list(), {organization_slug: organizationSlug, page: 1, tag_id: tag.id});
        setPosts(posts);
      } catch (error) {
        handleError(error)
      }
    };

  return (
    <div className='tags'>
      <Label secondary>{createdAt}</Label>
      {
        showTags && tag_ids.map(tag_id => {
          const tag = organizationTags.find(tag => tag.id === tag_id)
          return (
          <StyledChip key={tag_id} label={tag?.name} onClick={() => onTagSelect(tag)} backgroundVariant='secondary'/>
        )})
      }
    </div>
  );
};

Tags.propTypes = {
    tag_ids: PropTypes.array,
    created_at: PropTypes.string,
    setPosts: PropTypes.func,
};

export default Tags;