import React from "react";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useSuspense } from "@rest-hooks/react";

import EventResource from "resources/Profile/EventResource";

import CardGrid from "containers/public/Profile/Body/Events/EventsList/CardGrid";

import Compact from "ui/Modules/Event/Compact";
import Extended from "ui/Modules/Event/Extended";
import Button from "ui/Button";

import { t } from "i18n/index";

const Calendar = ({ view, hidePrice, hideLocation }) => {

    const {organizationSlug} = useParams();
    const events = useSuspense(EventResource.list(), {organization_slug: organizationSlug});

    const history = useHistory();
    const handleViewAll = () => {
      history.push(`/${organizationSlug}/events`);
    };

  const viewComponents = {
    empty: () => <div className="ui-calendar-empty"><span>No upcoming events</span></div>,
    list: () => <CompactView events={events} hidePrice={hidePrice} hideLocation={hideLocation} handleViewAll={handleViewAll} />,
    extended: () => <ExtendedView events={events} hidePrice={hidePrice} hideLocation={hideLocation} handleViewAll={handleViewAll} />,
    grid: () => (
      <div className='grid-calendar'>
        <CardGrid events={events} />
      </div>
    )
  };

  return (
      <>{events.length > 0 ? (viewComponents[view] || viewComponents.grid)() : viewComponents.empty()}</>
  );
};

/* eslint-disable react/prop-types */
const CompactView = ({events, hidePrice, hideLocation, handleViewAll}) => {
  const showViewAll = events.length > 0 && events[0].show_view_all;

  return (
    <div className="ui-calendar-compact">
      {events.map((event, index) => (
        <Compact key={index} event={event} hidePrice={hidePrice} hideLocation={hideLocation} />
      ))}

      {showViewAll && false &&
        <Button minwidth secondary onClick={handleViewAll}>{t('literal:viewAll')}</Button>
      }
    </div>
  );
};
/* eslint-enable react/prop-types */

/* eslint-disable react/prop-types */
const ExtendedView = ({events, hidePrice, hideLocation, handleViewAll}) => {
  const showViewAll = events.length > 0 && events[0].show_view_all;

  return (
    <div className="ui-calendar-extended">
      {events.map((event, index) => (
        <Extended key={index} event={event} hidePrice={hidePrice} hideLocation={hideLocation} />
      ))}

      {showViewAll && false &&
        <Button minwidth secondary onClick={handleViewAll}>{t('literal:viewAll')}</Button>
      }
    </div>
  );
};
/* eslint-enable react/prop-types */

Calendar.propTypes = {
  view: PropTypes.string,
  hidePrice: PropTypes.bool,
  hideLocation: PropTypes.bool,
};

export default Calendar;
