import React from 'react'
import PropTypes from 'prop-types'

import NumberTextField from 'components/NumberTextfield';

import { courseType } from 'utils/constants';

import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const courseForm = ({organizationId, course, onChange, permissions, sellableCollectibles, transformedRoyalties}) => {
  return (
    <div className="new-course-wrapper">
        
          { !organizationId && 
          <>
            <h3 className="new-form-section">Collection Details</h3> 
            <p> Please provide details about your first collection.</p>
          </>   
          }
          
          <div className={ `new-form-field` }>
            <TextField
              fullWidth
              name="title"
              label="Collection Title *"
              color="warning"
              value={ course.title }
              onChange={onChange}
            />
          </div>
          <div className={ `new-form-field` }>
            <TextField
              fullWidth
              multiline
              minRows={ 3 }
              name="description"
              label="Collection Description"
              color="warning"
              value={ course.description }
              onChange={onChange}
            />
          </div>
          <div className={ `new-form-field` }>
            <FormControl fullWidth>
              <InputLabel id="course-category-label" color="warning">Collection Type</InputLabel>
              <Select 
                labelId="course-category-label"
                label="Collection Type"
                name="category"
                color="warning"
                value={ course.category }
                onChange={onChange}
              > 
                {Object.values(courseType).map((type) => {
                  if (type.value === courseType.SELLABLECOLLECTIBLES.value && (!permissions || !permissions.hasSales)) {
                    return null;
                  }
                  if (type.value === courseType.MYSTERYBOX.value && (!permissions || !permissions.hasMysterybox)) {
                    return null;
                  }
                  return (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          { sellableCollectibles &&
            <div className={ `new-form-field` }>
              <NumberTextField
                fullWidth
                label={`Royalties Percentage. ${transformedRoyalties ? "Your input will be transformed to match this format: 75.1 %." : "Leave emtpy for 0 %."} `}
                color="warning"
                name="royalties"
                onChange={onChange}
                value={course.royalties}
              />
            </div>
          }
          <div className={ `new-form-field` }>
            <TextField
              fullWidth
              name="external_link"
              label="Collection Website"
              color="warning"
              value={ course.external_link }
              onChange={onChange}
            />
          </div>
      </div>
  )
}

courseForm.propTypes = {
  organizationId: PropTypes.number,
  course: PropTypes.object,
  onChange: PropTypes.func,
  permissions: PropTypes.object,
  sellableCollectibles: PropTypes.bool,
  transformedRoyalties: PropTypes.bool,
}

export default courseForm