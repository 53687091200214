import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

import Toast from 'components/Toast';

const ToastContext = createContext(null);

const NOTIFICATION_SOUNDS = {
  success: 'https://cdn.pixabay.com/audio/2025/01/29/audio_7e63556dae.mp3',
};

export function ToastProvider({ children }) {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showImageToast, setShowImageToast] = useState(false);
  const [severity, setSeverity] = useState("warning");
  const [message, setMessage] = useState("Membrz Club");

  const showToast = (severity, message, playSound = false, useImageToast = false) => {
    setSeverity(severity);
    setMessage(message);

    if (useImageToast) {
      setShowImageToast(true);
      setTimeout(hideToast, 2000);
    } else {
      setShowSnackbar(true);
    }

    if (playSound && NOTIFICATION_SOUNDS[severity]) {
      const audio = new Audio(NOTIFICATION_SOUNDS[severity]);
      audio.play().catch(e => console.warn('Audio playback failed:', e));
    }
  };

  const hideToast = () => {
    setShowSnackbar(false);
    setShowImageToast(false);
  };

  return (
    <ToastContext.Provider 
      value={{ 
        showSnackbar, 
        showImageToast,
        severity, 
        message, 
        showToast, 
        hideToast 
      }}
    >
      <Toast />
      {children}
    </ToastContext.Provider>
  );
}

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired
};

const useToast = () => useContext(ToastContext);

export { ToastContext, useToast };