import React, { useState } from 'react';
import PropTypes from 'prop-types';

import copyToClipboard from 'utils/copyToClipboard';
import { useToast } from 'utils/context/ToastContext';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PreviewIcon from '@mui/icons-material/Preview';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import KeyIcon from '@mui/icons-material/Key';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EmailIcon from '@mui/icons-material/Email';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import LinkIcon from '@mui/icons-material/Link';
import QueueIcon from '@mui/icons-material/Queue';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Divider } from '@mui/material';

const ActionButtons = ({event, loading, showCheckInButton, organizationId, history, handleConfirmDeleteModal, openCheckinCredentialsModal, openEmailModal, openEndSalesModal, onDuplicate, onNewTicket}) => {

    const { status, id, external_url, has_ticket, ticketing, single, over, has_issued_tickets } = event;

    const { showToast } = useToast();

    const draft = status === 'draft';

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const handlePreview = () => {
      window.open(external_url, '_blank');
      handleMenuClose();
    };

    const handleUrlCopy = () => {
      copyToClipboard(external_url, showToast);
      handleMenuClose();
    };

    const handleDuplicate = (id) => {
      onDuplicate(id);
      handleMenuClose();
    };

    const actionButtonsData = [
        {
          label: 'Tickets',
          icon: LocalActivityIcon,
          action: () => {
            history.push(`/organizations/${organizationId}/events/${id}/tickets`);
            handleMenuClose();
          },
          disabled: loading || draft,
          show: has_ticket && !showCheckInButton,
        },
        {
          label: 'End Sales',
          icon: EventBusyIcon,
          action: () => {
            openEndSalesModal();
            handleMenuClose();
          },
          disabled: loading || draft,
          show: has_ticket && !showCheckInButton,
        },
        {
          divider: true,
          show: has_ticket && !showCheckInButton,
        },
        {
          label: 'Edit Event',
          icon: EditIcon,
          action: () => {
            history.push(`/organizations/${organizationId}/events/${id}/edit`);
            handleMenuClose();
          },
          disabled: loading,
          show: true,
        },
        {
          label: 'Create New Ticket',
          icon: QueueIcon,
          action: () => {
            history.push(`/organizations/${organizationId}/perks/new?eventId=${id}`);
            handleMenuClose();
          },
          disabled: loading || over,
          show: single,
        },
        {
          label: 'Send Ticket',
          icon: ForwardToInboxIcon,
          action: () => {
            onNewTicket(event);
            handleMenuClose();
          },
          disabled: loading || over,
          show: has_ticket,
        },
        {
          label: 'Check-In Link & Code',
          icon: KeyIcon,
          action: () => {
            openCheckinCredentialsModal(event);
            handleMenuClose();
          },
          disabled: loading || draft,
          show: ticketing,
        },
        {
          label: 'Copy Event Link',
          icon: LinkIcon,
          action: handleUrlCopy,
          disabled: loading || draft,
          show: true,
        },
        {
          label: 'Preview',
          icon: PreviewIcon,
          action: handlePreview,
          disabled: loading,
          show: true,
        },
        {
          divider: true,
          show: true,
        },
        {
          label: 'Send Email to guests',
          icon: EmailIcon,
          action: () => {
            openEmailModal();
            handleMenuClose();
          },
          disabled: loading || draft || !has_issued_tickets,
          show: true,
        },
        {
          label: 'Duplicate',
          icon: ContentCopyIcon,
          action: () => handleDuplicate(id),
          disabled: loading,
          show: true,
        },
        {
          label: 'Delete',
          icon: DeleteIcon,
          action: () => {
            handleConfirmDeleteModal(event);
            handleMenuClose();
          },
          disabled: loading || has_issued_tickets,
          show: true,
        },
    ];

    return (
      <div>
        <IconButton onClick={handleMenuOpen} color='warning'>
          <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
          {actionButtonsData
            .filter(data => data.show)
            .map((data, index) => (
              data.divider ? (
                <Divider key={`divider-${index}`} />
              ) : (
                <MenuItem
                  key={data.label}
                  onClick={data.action}
                  disabled={data.disabled}
                >
                  <ListItemIcon>
                    <data.icon color='warning'/>
                  </ListItemIcon>
                  <ListItemText primary={data.label} />
                </MenuItem>
              )
            ))}
        </Menu>
      </div>
    );
};

ActionButtons.propTypes = {
    event: PropTypes.object,
    loading: PropTypes.bool,
    showCheckInButton: PropTypes.bool,
    organizationId: PropTypes.number,
    history: PropTypes.object,
    handleConfirmDeleteModal: PropTypes.func,
    openCheckinCredentialsModal: PropTypes.func,
    openEmailModal: PropTypes.func,
    openEndSalesModal: PropTypes.func,
    onDuplicate: PropTypes.func,
    onNewTicket: PropTypes.func
};

export default ActionButtons;
