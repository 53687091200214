import React from 'react';
import PropTypes from 'prop-types';

import Title from './Title';

import useAuthModalController from 'utils/useAuthModalController';

import DialogContent from '@mui/material/DialogContent';
import StyledDialog from 'components/StyledComponents/StyledDialog';

const AuthModal = ({open, onClose, done, organizationSlug=null, allowPartialSignIn=false, context=null}) => {
  
  const { logIn, Form, handleClose, handleLoginChange } = useAuthModalController(onClose);

  return (
    <StyledDialog open={open} onClose={ handleClose } fullWidth maxWidth='sm'>
        
      <Title logIn={logIn} context={context} />

      <DialogContent dividers>

        <Form done={done} 
              organizationSlug={organizationSlug} 
              onLoginChange={handleLoginChange} 
              showSignUp={true}
              allowPartialSignIn={allowPartialSignIn}
              context={context} />

      </DialogContent>
    </StyledDialog>
  );
};

AuthModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  done: PropTypes.func,
  organizationSlug: PropTypes.func,
  allowPartialSignIn: PropTypes.func,
  context: PropTypes.string
};

export default AuthModal;