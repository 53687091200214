import React from 'react';
import PropTypes from 'prop-types';

import { Avatar, ListItemButton } from '@mui/material';

const OrganizationItem = ({title, logo_url, selected, onChange}) => {
  return (
    <ListItemButton 
      fullWidth
      onClick={onChange}
      className='switcher-menu-item'
      >
      <Avatar sx={{ width: 32, height: 32, border: '2px solid #e76d3e'}} src={logo_url} />
      <p className={`organization-name ${selected ? 'selected' : ''}`}>
          {title}
      </p>
    </ListItemButton>
  );
};

OrganizationItem.propTypes = {
  title: PropTypes.string,
  logo_url: PropTypes.string,
  selected: PropTypes.bool,
  onChange: PropTypes.func
};

export default OrganizationItem;