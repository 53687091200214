import InsertLinkIcon from '@mui/icons-material/InsertLink';
import TitleIcon from '@mui/icons-material/Title';
import GroupsIcon from '@mui/icons-material/Groups';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import discord from '../assets/images/remix_icons/discord.svg';
import email from '../assets/images/remix_icons/email.svg';
import facebook from '../assets/images/remix_icons/facebook.svg';
import instagram from '../assets/images/remix_icons/instagram.svg';
import linkedin from '../assets/images/remix_icons/linkedin.svg';
import soundcloud from '../assets/images/remix_icons/soundcloud.svg';
import spotify from '../assets/images/remix_icons/spotify.svg';
import telegram from '../assets/images/remix_icons/telegram.svg';
import tiktok from '../assets/images/remix_icons/tiktok.svg';
import website from '../assets/images/remix_icons/website.svg';
import x from '../assets/images/remix_icons/x.svg';
import youtube from '../assets/images/remix_icons/youtube.svg';
import bandcamp from '../assets/images/remix_icons/bandcamp.svg';
import whatsapp from '../assets/images/remix_icons/whatsapp.svg';

export const emptyPageElement = {
    content: "",
    image_file: null,
    element_type: "",
    reward_ids: ["public"],
    link_title: "",
};

export const pageElementTypes = [
  {
    value: 'link',
    title: 'Link',
    icon: InsertLinkIcon,
  },
  {
    value: "social",
    title: 'Social',
    icon: GroupsIcon,
  },
  {
    value: 'title',
    title: 'Section Header',
    icon: TitleIcon,
  },
  {
    value: 'embed',
    title: 'Embed',
    icon: PlayCircleOutlineIcon,
  },
];

export const pageInfo = {
  name: "Link-In-Bio",
  description: "Set up and organize links and social media profiles to be displayed on the About section of your club.",
  action: true,
};

export const isSocialElement = (element_type) => {
  return socialTypes.some(socialType => socialType.value === element_type);
};

export const socialTypes = [
  {
    value: "facebook",
    title: "Facebook",
    logo: facebook,
  },
  {
    value: "x",
    title: "X",
    logo: x,
  },
  {
    value: "instagram",
    title: "Instagram",
    logo: instagram,
  },
  {
    value: "youtube",
    title: "Youtube",
    logo: youtube,
  },
  {
    value: "linkedin",
    title: "Linkedin",
    logo: linkedin,
  },
  {
    value: "tiktok",
    title: "TikTok",
    logo: tiktok,
  },
  {
    value: "whatsapp",
    title: "WhatsApp",
    logo: whatsapp,
  },
  {
    value: "telegram",
    title: "Telegram",
    logo: telegram,
  },
  {
    value: "discord",
    title: "Discord",
    logo: discord,
  },
  {
    value: "spotify",
    title: "Spotify",
    logo: spotify,
  },
  {
    value: "soundcloud",
    title: "SoundCloud",
    logo: soundcloud,
  },
  {
    value: "bandcamp",
    title: "Bandcamp",
    logo: bandcamp,
  },
  {
    value: "email",
    title: "Email",
    logo: email,
  },
  {
    value: "website",
    title: "Website",
    logo: website,
  },
];

export const isEmbeddedElement = (element_type) => {
  if (element_type === "embed") return true;
  return embedTypes.some(embedType => embedType.value === element_type);
};

export const embedTypes = [
  {
    value: "youtube_embed",
    title: "Youtube",
    logo: youtube,
  },
  {
    value: "spotify_embed",
    title: "Spotify",
    logo: spotify,
  },
  {
    value: "soundcloud_embed",
    title: "SoundCloud",
    logo: soundcloud,
  },
]

export const calendarViewOptions = [
  {
    label: "Compact",
    value: "list"
  },
  {
    label: "Extended",
    value: "extended"
  },
  {
    label: "Grid",
    value: "grid"
  },
];

export const possibleSettings = ["number_of_events", "view", "hide_price", "hide_location"];

export const detectEmbedType = (url) => {
  const youtubeRegexes = [
    /https?:\/\/youtu\.be\/(.+)/,
    /https?:\/\/(?:www\.)?youtube\.com\/watch\?v=([^&]+)/,
    /https?:\/\/(?:www\.)?youtube\.com\/v\/([^#?]+)/,
    /https?:\/\/(?:www\.)?youtube\.com\/live\/([^#?]+)/,
    /https?:\/\/m\.youtube\.com\/watch\?v=([^&]+)/,
    /https?:\/\/m\.youtube\.com\/v\/([^#?]+)/,
    /https?:\/\/(?:www\.)?youtube\.com\/shorts\/([^?]+)/,
    /https?:\/\/(?:www\.)?youtube\.com\/playlist\?list=([^&]+)/
  ];

  const spotifyRegexes = [
    /https?:\/\/open\.spotify\.com\/intl-pt\/([^?]+)/,
    /https?:\/\/open\.spotify\.com\/([^?]+)/
  ];

  const soundcloudRegexes = [
    /https?:\/\/soundcloud\.com\/([^?]+)/
  ];

  if (youtubeRegexes.some(regex => regex.test(url))) return 'youtube';
  if (spotifyRegexes.some(regex => regex.test(url))) return 'spotify';
  if (soundcloudRegexes.some(regex => regex.test(url))) return 'soundcloud';
  return null;
};

export const isEmbeddedableElement = (content) => {
  return detectEmbedType(content) !== null;
};

