import React from 'react';
import PropTypes from 'prop-types';

import NumberTextField from 'components/NumberTextfield';

const PercentOffe = ({percent_off, onChange, disabled}) => {

  const label = 'Percent off *';

  return (
        <NumberTextField
            fullWidth
            size='small'
            color="warning"
            name='percent_off'
            value={percent_off || ''}
            onChange={onChange}
            label={label}
            disabled={disabled} />
  );
};

PercentOffe.propTypes = {
  percent_off: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default PercentOffe;