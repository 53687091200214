
import React from 'react';
import { useSuspense } from '@rest-hooks/react';
import { useParams } from 'react-router-dom';

import PayoutResource from 'resources/organization/PayoutResource';

import DataSelectionTable from 'components/DataTable/DataSelectionTable';
import WithPermission from 'components/WithPermission';
import SalesFallback from 'components/fallbacks/Sales';

import FormSection from 'ui/FormSection';
import Description from 'ui/text/Description';

import { payoutColumns } from 'utils/tables/columns/payoutColumns';

const StripePayoutsTabContent = () => {

  const params = useParams();

  const organizationId = parseInt(params.organizationId);

  const payouts = useSuspense(PayoutResource.list(), {organization_id: organizationId});

  return (
    <>
      {
        payouts.length > 0 ? 
          <WithPermission permission={'hasSales'} fallback={<SalesFallback />}>
            <div style={{ flexGrow: 1 }}>
                <DataSelectionTable records={payouts}
                                    columns={payoutColumns}
                />
            </div>
          </WithPermission>
        :
          <FormSection> 
            <Description>We apologize!</Description>
            <Description>The Payouts section is not currently available as we are working on improving it to provide an even better experience for you.</Description>
            <Description>In the meantime, if you need detailed information about your payouts, don&#39;t hesitate to reach out to your Account Manager or email us at hello@membrz.club.</Description>
            <Description>We are happy to assist!</Description>
          </FormSection>
      }      
    </>
)};

export default StripePayoutsTabContent;
