const storage = localStorage;
const key     = 'cc.last_view';

// -----------------------------------------------------
// Util Definition
// -----------------------------------------------------

const LastView = {
  read:   () => storage.getItem(key),
  write:  (name) => storage.setItem(key, name),
  remove: () => storage.removeItem(key)
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default LastView;