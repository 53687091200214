import React, { useState, useEffect } from "react";
import { useController, useSuspense } from "@rest-hooks/react";
import PropTypes from "prop-types";

import TicketFilter from "./TicketFilter";
import Header from "./Header";
import Stats from "./Stats";
import QRCodeScanner from "./QRCodeScanner";
import NewTicketModal from "./NewTicketModal.js";

import DataSelectionTable from "components/DataTable/DataSelectionTable";
import TabPanels from "components/TabPanels";
import CustomModal from "components/CustomModal";

import Content from "./CheckinModal/Content";
import Actions from "./CheckinModal/Actions";

import { useError } from "utils/useErrorController";
import { useToast } from "utils/context/ToastContext";
import { ticketsColumns } from "utils/tables/columns/ticketsColumns";
import usePaginationAndFilteringController from "utils/usePaginationAndFilteringController";
import useEndSalesController from "utils/useEndSalesController";
import fetchTicket from "utils/fetchTicket";
import CheckinMode from "utils/localStorage/CheckinMode";

const TicketsList = ({TicketResource, ticketResourceParams, event, sessionId=null}) => {
  
  const [customModalContent, setCustomModalContent] = useState(null);
  const [openNewTicketModal, setOpenNewTicketModal] = useState(false);
  const [openQRCodeScanner, setOpenQRCodeScanner] = useState(false);

  const { fetch } = useController();
  const { handleError, setLoading } = useError();
  const { showToast } = useToast();

  const {allow_checkout, single, sessions, tickets} = event;

  const stats = useSuspense(TicketResource.list(), {...ticketResourceParams, stats: true});

  const {imported_tickets_count} = stats[0];

  const session = !single && sessions.find(session => session.id === +sessionId);
  const selectableTickets = session ? session.tickets : tickets;
  const filteredTickets = selectableTickets.filter(ticket => ticket.sellable && ticket.available)

  const {
    objectState,
    fetchData,
    handleFilterChange,
    handleQueryChange,
    handlePageChange
  } = usePaginationAndFilteringController({
      fetchDataResource: TicketResource,
      fetchDataParams: ticketResourceParams,
  });

  //fetchTickets on mount
  useEffect(() => {
    fetchData();
  }, [ticketResourceParams]);

  const handleCheckIn = async (ticket_uuid, showFastCheckinToast, intent = "check_in",  orderAmount = 1) => {
    try {
      setLoading(true);

      const duplicatedTicketResourceParams = {...ticketResourceParams};
      if (orderAmount > 1 && intent === "check_in") duplicatedTicketResourceParams.target = "group";
      if (intent === "check_out") duplicatedTicketResourceParams.intent = "check_out";

      await fetch(TicketResource.update(), { id: ticket_uuid, ...duplicatedTicketResourceParams}, duplicatedTicketResourceParams);
      setLoading(false);

      let message = "";
      if (intent === "check_out") {
        message = "Check Out Successful";
      } else if (orderAmount > 1) {
        message = `Checked In ${orderAmount} Tickets Successfully`;
      } else {
        message = "Check In Successful";
      }

      showToast("success", message, showFastCheckinToast, showFastCheckinToast);

      fetchData();
      setCustomModalContent(null);
    } catch (error) {
      handleError(error);
    }
  };

  const handleOpenTicketDetails = (ticket) => {

    const {title, redeem_state, uuid, order_amount} = ticket;
    setCustomModalContent({
      title: <strong>{title}</strong>,
      content: <Content ticket={ticket}/>,
      button: (
        <Actions
          uuid={uuid}
          redeemState={redeem_state}
          orderAmount={order_amount}
          allowCheckout={allow_checkout}
          onCheckIn={handleCheckIn}
        />
      ),
      fullScreen: true
    });
  };

  const handleQRCodeRead = async (result) => {
    try {
      setLoading(true);
      const newSelectedTicket = await fetchTicket({result, ...ticketResourceParams});
      
      const fastCheckinMode = CheckinMode.read() === "fast";
      const singlePurchase = newSelectedTicket.order_amount === 1;
      const possibleCheckout = allow_checkout && newSelectedTicket.redeem_state === "completed";

      const automaticallyCheckIn = fastCheckinMode && singlePurchase && !possibleCheckout;

      if (automaticallyCheckIn) {
        handleCheckIn(newSelectedTicket.uuid, true);
      } else {
        setLoading(false);
        handleOpenTicketDetails(newSelectedTicket);
      }

    } catch (error) {
      setLoading(false);
      setCustomModalContent(null);
      handleError(error);
    }
  };

  const handleOpenQRCodeScanner = () => {
    setOpenQRCodeScanner(true);
  };

  const handleOpenNewTicketModal = () => {
    setOpenNewTicketModal(true);
  };

  const {
    openEndSalesModal,
    EndSalesModalComponent,
    handleEndSalesModalOpen
  } = useEndSalesController(
    event,
    TicketResource,
    ticketResourceParams
  );

  return (
    <>
      <TabPanels labels={["Tickets", "Stats"]}>
        <>
          <Header
            onCheckIn={handleOpenQRCodeScanner}
            onSellTickets={handleOpenNewTicketModal}
            openEndSalesModal={handleEndSalesModalOpen}
            allowCheckout={allow_checkout}
          />

          <DataSelectionTable
            records={objectState.filteredObjects}

            columns={ticketsColumns(
              handleOpenTicketDetails, imported_tickets_count
            )}

            searchLabel="Search by Ticket ref, Order or Member"
            onQueryChange={handleQueryChange}
            queryString={objectState.query}

            serverPaginationProps={{
              paginationServer: true,
              paginationTotalRows: objectState.totalCount,
              page: objectState.page,
              onChangePage: handlePageChange
            }}

            filter={
              <TicketFilter
                onChange={handleFilterChange}
              />
            }
          />
        </>

        <div>
          <Stats
            TicketResource={TicketResource}
            ticketResourceParams={ticketResourceParams}
            stats={stats}
          />
        </div>
      </TabPanels>

      {openQRCodeScanner && (
        <QRCodeScanner
          open={openQRCodeScanner}
          onClose={() => setOpenQRCodeScanner(false)}
          onQRCodeRead={handleQRCodeRead}
          allowCheckout={allow_checkout}
        />
      )}

      {customModalContent && (
        <CustomModal
          handleClose={() => setCustomModalContent(null)}
          customModalContent={customModalContent}
        />
      )}

      {openNewTicketModal && (
        <NewTicketModal
          tickets={filteredTickets}
          onClose={() => setOpenNewTicketModal(false)}
          open={openNewTicketModal}
          done={fetchData}
          TicketResource={TicketResource}
          ticketResourceParams={ticketResourceParams}
        />
      )}

      {openEndSalesModal &&
        <EndSalesModalComponent />}
    </>
  );
};

TicketsList.propTypes = {
  TicketResource: PropTypes.object,
  ticketResourceParams: PropTypes.object,
  event: PropTypes.object,
  sessionId: PropTypes.string,
};

export default TicketsList;
