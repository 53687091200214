import React from 'react';
import PropTypes from 'prop-types';

import { DialogContent, TextField } from '@mui/material';
import EventUrlSelector from './EventUrlSelector';
import Label from 'ui/text/Label';

const Content = ({label, onLabelChange, url, onUrlChange}) => {
  return (
    <DialogContent dividers style={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
    
      <TextField size="small" name="label" label="Label" color='warning' fullWidth value={label} onChange={event => onLabelChange(event.target.value)} />
      <EventUrlSelector url={url} onUrlChange={onUrlChange} />
      <Label small secondary padded>Select one of your upcoming events or enter another url.</Label>

    </DialogContent>
  );
};

Content.propTypes = {
  label: PropTypes.string,
  onLabelChange: PropTypes.func,
  url: PropTypes.string,
  onUrlChange: PropTypes.func
};

export default Content;