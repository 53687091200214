import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useController } from '@rest-hooks/react';

import PageElementResource from 'resources/Profile/PageElementResource';
import ProfileResource from 'resources/Profile/ProfileResource';
import MembershipResource from 'resources/Profile/MembershipResource';
import ProductResource from 'resources/Profile/ProductResource';

import AuthModal from 'components/authForms/AuthModal';

import CustomModal from 'components/CustomModal';

import Button from 'ui/Button';

import AuthToken from 'utils/localStorage/AuthToken';
import { useError } from 'utils/useErrorController';

import ListItemAvatar from '@mui/material/ListItemAvatar';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import LockIcon from '@mui/icons-material/Lock';

import { t } from "i18n/index";
import { embedTypes, isEmbeddedElement } from 'utils/pageElementsHelper';

const Locked = ({ pageElement, organizationId }) => {
    const [openAuthModal, setOpenAuthModal] = useState(false);
    const [customModalContent, setCustomModalContent] = useState(null);

    const history = useHistory();
    const { fetch } = useController();
    const { organizationSlug } = useParams();
    const { handleError } = useError();

    const handleRedirect = (e) => {
        e.preventDefault();
        history.push(`memberships`); // TODO: scroll to top
    };

    const handleClick = () => {
        const authToken = AuthToken.read();
        if (authToken) {
            handleCustomModal();
        } else {
            setOpenAuthModal(true);
        }
    };

    const {image_url, link_title, tier_titles, element_type } = pageElement;

    const handleSuccessfulAuth = async () => {
        try {
            setOpenAuthModal(false);
            await fetch(ProfileResource.detail(), {id: organizationSlug});
            await fetch(MembershipResource.list(), {organization_slug: organizationSlug});
            await fetch(ProductResource.list(), {organization_slug: organizationSlug});
            const {links} =  await fetch(PageElementResource.detail(), {organization_slug: organizationSlug, id: organizationId})
            
            const activePageElement = links.find(element => element.id === pageElement.id)
            const {locked, content} = activePageElement;

            if (locked) {
                handleCustomModal();
            } else {
                window.open(content, "_blank");
            }

        } catch (error) {
            handleError(error);
        }
    };

    const handleCustomModal = () => {
        if (tier_titles.includes('private')) {
            setCustomModalContent(privateElementContent);
        } else {
            setCustomModalContent(exclusiveElementContent);
        }
    };
    
    const privateElementContent =
        { 
            title: t('literal:privateLink'),
            content: t('profile:about:privateElement'),
            button: <Button highlighted onClick={handleRedirect}>{t('literal:seeMemberships')}</Button>
        };

    const tierTitles = tier_titles?.join(', ');
    const suffix = tier_titles?.length > 1 ? 's' : '';
    
    const exclusiveElementContent = 
        { 
            title: t('literal:privateLink'),
            content: t('profile:about:exclusiveElement', { suffix, tierTitles }),
            button: <Button highlighted onClick={handleRedirect}>{t('literal:seeMemberships')}</Button>
        };

    const logo = isEmbeddedElement(pageElement) && embedTypes.find(type => type.value === element_type).logo;  
    const image = image_url || logo;
    const title = link_title !== 'null' && link_title !== '' && link_title;

  return (
    <>
        <ListItemButton
            onClick={() => handleClick()}
            disableRipple
            disableGutters
            className='list-item-button locked'
        >   
            {image && (
                <ListItemAvatar className="image-container">
                    <img className="image" src={image} />
                </ListItemAvatar>
            )}
            {title  && <ListItemText primary={link_title} />}
            <ListItemIcon>
                <LockIcon sx={{ color: 'var(--primaryTextColor)' }} />
            </ListItemIcon>
        </ListItemButton>

        <AuthModal
            open={ openAuthModal }
            onClose={ () => setOpenAuthModal(false) }
            done={ handleSuccessfulAuth }
            organizationSlug={ organizationSlug } />

        {customModalContent &&
            <CustomModal open={ !!customModalContent }
                     handleClose={ () => setCustomModalContent(null) }
                     customModalContent={ customModalContent } />
        }
    </>
    
  )
};

Locked.propTypes = {
    pageElement: PropTypes.object,
    organizationId: PropTypes.number,
};

export default Locked;