const otherSupportedPreferences = ["hide_description", "show_application_fee"];

const createFormData = (invoice_notification_list, allow_invoicing, preferences) => {
  
  const isArray = Array.isArray(invoice_notification_list);
  const emailsArray = isArray ? invoice_notification_list : invoice_notification_list.replace(/\s+/g, '').split(',');
  
  const formData = new FormData();
  
  Object.keys(preferences).forEach(preference => {
    if (otherSupportedPreferences.includes(preference) && preferences[preference]) {
      formData.append('preferences[]', preference);
    }      
    if (preference === "allow_invoicing" && allow_invoicing) {
      formData.append('preferences[]', 'allow_invoicing');
    }
  });

  formData.append('invoice_notification_list', JSON.stringify(emailsArray));
  
  return formData;
};

export default createFormData;
