import React from 'react';
import PropTypes from 'prop-types';

import StatCard from 'ui/StatCard/StatCard';

import moment from 'moment';

const Overview = ({data}) => {

  const transformValue = (key) => {
    if (key === 'visit_duration') {
      const durationInSeconds = data[key].value;
      const beginOfDay = moment().startOf('day');
      const formattedDuration = beginOfDay.add(durationInSeconds, 'seconds').format('mm[m] ss[s]');
      return formattedDuration;

    } else {
      return data[key].value;
    }
  };    

  return (
    <div className="ui-stats-grid">
      {Object.keys(data).map(key => (
        <StatCard 
          key={key} 
          title={key} 
          type='number'
          highlight={ transformValue(key) }/>
      ))}
    </div>
  );
};

Overview.propTypes = {
  data: PropTypes.object
};

export default Overview;