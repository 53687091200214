import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { QrReader } from 'react-qr-reader';

import ViewFinder from './ViewFinder';
import LoadingState from './LoadingState';

import FormSection from 'ui/FormSection';

import isMobileDevice from 'utils/mobileAndTabletCheck';
import CheckinMode from 'utils/localStorage/CheckinMode';
import { useError } from 'utils/useErrorController';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Stack, Typography, Switch } from '@mui/material';

import { debounce } from 'lodash';

import "./styles.css"

const QRCodeScanner = ({ open, onClose, onQRCodeRead, allowCheckout }) => {
  const [checked, setChecked] = useState(CheckinMode.read() === "fast");

  const { loading } = useError();

  const showFullScreen = isMobileDevice();

  const throttledQRCodeResult = useCallback(
    debounce((result) => {
      onQRCodeRead(result);
    }, 2000, { leading: true, trailing: false }),
    []
  );

  const handleQRCodeResult = (result) => {
    if (result) throttledQRCodeResult(result.text);
  };

  const handleChange = ({target: {checked}}) => {
    const newValue = checked ? 'fast' : 'normal';
    CheckinMode.write(newValue)
    setChecked(checked);
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth='sm' fullScreen={showFullScreen}>
      <DialogTitle>
        {allowCheckout ? "Scan QR Code" : "Check In"}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
            color: 'black',
          }}
          >
            <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <>
          <QrReader
            constraints={ {facingMode: 'environment'} }
            onResult={handleQRCodeResult}
            containerStyle={
              {
                width: '100%',
                height: '80%',
                marginBottom: '10px',
              }
            }
            videoContainerStyle={
              {
                width: '100%',
                height: '100%'
              }
            }
            ViewFinder={loading ? LoadingState: ViewFinder}
            scanDelay={300}
          />
          <FormSection slim title={"Check-in mode"}>
            <Stack direction="row" spacing={0.5} alignItems="center" alignSelf="center">
              <Typography>Normal</Typography>
                  <Switch color="warning"
                      checked={checked}
                      onChange={handleChange} />
              <Typography>Fast Track</Typography>
            </Stack>
          </FormSection>
        </>
      </DialogContent>
    </Dialog>
  );
};

QRCodeScanner.propTypes = {
  open: PropTypes.bool,
  allowCheckout: PropTypes.bool,
  onClose: PropTypes.func,
  onQRCodeRead: PropTypes.func,
};

export default QRCodeScanner;
