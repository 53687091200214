import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { useController, useSuspense } from '@rest-hooks/react';
import { useHistory, useParams } from 'react-router-dom';

import EventResource from 'resources/organization/EventResource';
import CheckinTokenResource from 'resources/organization/CheckinTokenResource';

import ConfirmModal from 'components/ConfirmModal';

import CheckinCredentialsModal from './CheckinCredentialsModal';
import ListItemComponent from './ListItemComponent';
import NewTicketModal from './NewTicketModal';

import Button from 'ui/Button';
import SplitButton from 'ui/SplitButton';

import { useToast } from 'utils/context/ToastContext';
import { useError } from 'utils/useErrorController';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import { Divider, List } from '@mui/material';
import Description from 'ui/text/Description';

const EventsList = ({upcoming = false, past = false}) => {
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [openNewTicketModal, setOpenNewTicketModal] = useState(false);
    const [openCheckinCredentialsModal, setOpenCheckinCredentialsModal] = useState(false);
    const [checkinCredentials, setCheckinCredentials] = useState({});

    const { fetch } = useController();
    const history = useHistory();
    const params  = useParams();

    const organizationId = params.organizationId;
    const requestParams = {organization_id: organizationId};
    if (upcoming) requestParams.upcoming = true
    if (past) requestParams.past = true

    const data = useSuspense(EventResource.list(), requestParams);
    const draftEvents = data.filter(event => event.status === 'draft');
    const publishedvents = data.filter(event => event.status === 'published');

    const { showToast } = useToast();
    const { handleError, loading, setLoading } = useError();

    const handleDelete = async () => {
        try {
            setLoading(true);
            await fetch(EventResource.delete(), {organization_id: organizationId, id: selectedEvent.id});

            showToast('success', 'Your request is being processed and the event will be deleted soon.');
            setSelectedEvent(null);
            setLoading(false);
        } catch (error) {
            handleError(error)
        }
    };

    const handleDuplicate = async (originalId) => {
      try {
          setLoading(true);
          const {id} = await fetch(EventResource.create(), {organization_id: organizationId}, {id: originalId});
          setLoading(false);
          history.push(`/organizations/${organizationId}/events/${id}/edit`);
      } catch (error) {
          handleError(error)
      }
  };
    const handleConfirmDeleteModal = (event) => {
        setSelectedEvent(event);
        setConfirmDelete(true);
    };

    const generateCheckInCredentials = async () => {
        try {
            setLoading(true);
            const {checkin_url, secret_token} = await fetch(CheckinTokenResource.create(), {organization_id: organizationId}, {event_id: selectedEvent.id});
            await fetch(EventResource.list(), requestParams);
            
            setCheckinCredentials({checkin_url, secret_token});
            setLoading(false);
        } catch (error) {
            handleError(error)
        }
    };

    const handleCheckinCredentialsModalOpen = (event) => {
        setSelectedEvent(event);
        setCheckinCredentials({
            checkin_url: event.checkin_url,
            secret_token: event.checkin_secret_token
        });
        setOpenCheckinCredentialsModal(true);
    };

    const handleCheckinCredentialsModalClose = () => {
        setOpenCheckinCredentialsModal(false);
        setSelectedEvent(null);
    };

    const handleNewTicketModalOpen = (event) => {
        setOpenNewTicketModal(true);
        setSelectedEvent(event);
    };

    const handleNewTicketModalClose = () => {
        setOpenNewTicketModal(false);
        setSelectedEvent(null);
    };

    const MainButton = () =>
        <Button icon={AddCircleOutlineIcon} highlighted fullwidth onClick={() => history.push(`/organizations/${organizationId}/events/new`)} loading={loading}>
            Add Single Event
        </Button>;

    const buttonOptions = [
        {label: 'Add Recurring Event', onClick: () => history.push(`/organizations/${organizationId}/events/new?recurring=true`), icon: <ControlPointDuplicateIcon/>},
    ];

    return (
        <div className='org-event-list'>
            <List sx={{padding: 'var(--mobilePadding)'}}>

                <SplitButton MainButton={MainButton} options={buttonOptions} disabled={loading} highlighted />

                {publishedvents.map((event, index) => (
                  <ListItemComponent 
                    key={index + 1}
                    event={event}
                    organizationId={organizationId}
                    loading={loading}
                    history={history}
                    onDelete={handleConfirmDeleteModal}
                    onCheckin={handleCheckinCredentialsModalOpen}
                    onDuplicate={handleDuplicate}
                    onNewTicket={handleNewTicketModalOpen}
                  />
                ))}

                {draftEvents.length > 0 &&
                  <Divider sx={{my: 3}}>
                    <Description>Draft Events</Description>
                  </Divider>}

                {draftEvents.map((event, index) => (
                  <ListItemComponent 
                    key={index + 1}
                    event={event}
                    organizationId={organizationId}
                    loading={loading}
                    history={history}
                    onDelete={handleConfirmDeleteModal}
                    onCheckin={handleCheckinCredentialsModalOpen}
                    onDuplicate={handleDuplicate}
                  />
                ))}
            </List>

            {openCheckinCredentialsModal &&
                <CheckinCredentialsModal 
                    open={openCheckinCredentialsModal}
                    loading={loading}
                    onClose={handleCheckinCredentialsModalClose}
                    onGenerate={generateCheckInCredentials}
                    checkinCredentials={checkinCredentials}
                />}

            {openNewTicketModal &&
                <NewTicketModal
                    open={openNewTicketModal}
                    onClose={handleNewTicketModalClose}
                    event={selectedEvent}
                />}

            <ConfirmModal
                    title={`Deleting event`}
                    open={confirmDelete}
                    setOpen={setConfirmDelete}
                    onConfirm={handleDelete}
                >
                    You are about to delete an event. Would you like to continue?
            </ConfirmModal>

        </div>
    )
};

EventsList.propTypes = {
    upcoming: PropTypes.bool,
    past: PropTypes.bool
};

export default EventsList;