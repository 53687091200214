import React, { useCallback } from 'react';
import { useController, useSuspense } from '@rest-hooks/react';
import { useHistory, useParams } from "react-router-dom";

import CourseResource from 'resources/organization/CourseResource';
import RewardResource from 'resources/organization/RewardResource';
import OrganizationResource from 'resources/organization/OrganizationResource';

import Form from './form'
import handleValidation from './handleValidation';
import createFormData from './createFormData';

import Layout from 'components/layouts/index';

import { useToast } from 'utils/context/ToastContext';
import { courseType, rewardType } from 'utils/constants';
import { useError } from 'utils/useErrorController';

/* eslint-disable react/prop-types */
const NewReward = ({membership=false}) => {

  const { fetch } = useController();
  const history = useHistory();

  const params = useParams();
  const courseId = parseInt(params.courseId);
  const organizationId = parseInt(params.organizationId);

  const searchParams = new URLSearchParams(window.location.search);
  const mysteryBoxReward = searchParams.has('mysteryBox') && searchParams.get('mysteryBox') === 'true';

  const title = membership ?
                `Create new membership pass` :
                mysteryBoxReward ?
                `Create your collection's mystery box settings` :
                `Create new credential`;

  const pageInfo = {
    name: title
  };

  const course = useSuspense(CourseResource.detail(), {id: courseId, organization_id: organizationId});
  const organization = useSuspense(OrganizationResource.detail(), {id: organizationId});

  const mysteryBox = course.category === courseType.MYSTERYBOX.value;
  
  const { handleError, loading, setLoading, validate} = useError();
  const { showToast } = useToast();

  const handleOnSubmit = useCallback(
    async (e, reward, templateVars) => {
      try {
        e.preventDefault();
        validate({reward, course}, handleValidation);
        const formData = createFormData(reward, templateVars, mysteryBox, mysteryBoxReward);
        const {id, course_id, organization_id} = await fetch(RewardResource.create(), {course_id: courseId, organization_id: organizationId}, formData);
        // success!
        if (id){
          fetch(RewardResource.list(), {course_id: courseId, organization_id: organizationId});
          showToast('success', `New ${rewardType[reward.category.toUpperCase()].label} created`);
          const collectionType = membership ? 'memberships' : 'collections';
          history.push(`/organizations/${organization_id}/${collectionType}/${course_id}`);
          setLoading(false);
        }
      }  catch (error) {
        handleError(error)
      }
    },
    [fetch],
  );

  return (
    <Layout context='teacher'
            back={ true }
            pageInfo={ pageInfo } >

      <Form course={ course }
            loading={ loading }
            mysteryBoxReward={ mysteryBoxReward }
            onSubmit={ handleOnSubmit } 
            handleError={ handleError }
            currency={organization.currency} />
            
    </Layout>
  )
};


export default NewReward;
