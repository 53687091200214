import { validateEmail } from "utils/validations/validateEmail";
import { validateUrl } from "utils/validations/validateUrl";

export const handleValidation = (pageElement) => {
  const {content, reward_ids, element_type, link_title} = pageElement;
  validateType(element_type);
  if (element_type !== "calendar") {
    validateContent(content);
  }
  if (element_type === "link") {
    validateVisibilityCriteria(reward_ids);
    validateLinkTitle(link_title);
  }
  if (element_type !== "title" && element_type !== "calendar" && element_type !== "text") {
    validateContentPattern(element_type, content);
  }
};

const validateType = (element_type) => {
  if (!element_type) {
    throw "Please specify what type of element you are creating";
  } else if (element_type == "social") {
    throw "Please specify the network this url points to";
  }
};

const validateContent = (content) => {
  if (content.trim() === ""){
    throw "Please specify the element's content";
  } else if (content.includes("sets/artist-stations")) {
    throw "Artist stations Urls are not embeddable";
  }
};

const validateVisibilityCriteria = (reward_ids) => {
  if (!reward_ids || reward_ids.length === 0) {
    throw "Please specify the visibility criteria";
  }
};

const validateLinkTitle = (link_title) => {
  if (link_title.trim() === "") {
    throw "Please specify the link title";
  }
};

const validateContentPattern = (element_type, content) => {
  if (element_type === "email") {
    validateEmail(content, "email");
  } else {
    validateUrl(content, "link");
  }
};
