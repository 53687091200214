import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import moment from "moment";

const DateRangeFilter = ({ onChange, defaultStartDate = null, defaultEndDate = null }) => {
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleDateSelect = (newValue) => {
    if (open && !startDate) {
      setStartDate(newValue);
      setTimeout(() => setOpen(true));
    } else {
      setEndDate(newValue);
      if (onChange && newValue) {
        onChange(["start_date", "end_date"], [startDate, newValue]);
      }
    }
  };

  const formatDisplayValue = () => {
    if (open && !startDate) {
      return "Select start date";
    } else if (startDate && !endDate) {
      return `${moment(startDate).format("DD/MM")} - Select end date`
    } else if (startDate && endDate) {
      return `${moment(startDate).format("DD/MM")} - ${moment(endDate).format("DD/MM")}`
    } else {
      return "";
    }
  };

  const handleTextFieldClick = () => {
    setOpen(true);
    if (startDate && endDate) {
      resetDates();
    }
  };

  const resetDates = (e) => {
    if (e) e.stopPropagation();
    setOpen(false);
    setStartDate(null);
    setEndDate(null);
    onChange(["start_date", "end_date"]);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div>
        <TextField
          ref={anchorRef}
          color="warning"
          size="small"
          label="Filter by Date"
          value={formatDisplayValue()}
          InputProps={{ 
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={resetDates}>
                  <CloseIcon/>
                </IconButton>
              </InputAdornment>
            ),
          }}
          onClick={handleTextFieldClick}
        />
        <DatePicker
          open={open}
          onClose={() => setOpen(false)}
          label={open && startDate ? "Select End Date" : "Select Start Date"}
          value={open && startDate ? endDate : startDate}
          onChange={handleDateSelect}
          minDate={open && startDate ? startDate : undefined}
          renderInput={() => <></>}
          PopperProps={{ anchorEl: anchorRef.current }}

        />
      </div>
    </LocalizationProvider>
  );
};

DateRangeFilter.propTypes = {
  onChange: PropTypes.func,
  defaultStartDate: PropTypes.string,
  defaultEndDate: PropTypes.string
};

export default DateRangeFilter;
