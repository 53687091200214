import urljoin  from 'url-join';

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export function onChainUrl(contract, token_id) {
  return `https://polygonscan.com/token/${contract}?a=${token_id}`
}
export function onNftScanUrl(contract, token_id) {
  return `https://polygon.nftscan.com/${contract.toLowerCase()}/${token_id}`
}

export function rewardClaimUrl(token_id) {
  return `https://${window.location.host}/claim/${token_id}`
}

export function publicReward(token_id) {
  return `https://${window.location.host}/tokens/${token_id}`
}

export function rewardMediaDownload(token_id) {
  return urljoin(process.env.REACT_APP_API_HOST, '/downloads/', token_id.toString(), '/media');
}

export function rewardPDFDownload(token_id) {
  return urljoin(process.env.REACT_APP_API_HOST, '/downloads/', token_id.toString(), '/pdf');
}

export function organizationUrl(organizationSlug) {
  return `${window.location.protocol}//${window.location.host}/${organizationSlug}`
}

export const termsOfUseLink    = "https://crowdclass.com/mc_terms"
export const privacyPolicyLink = "https://crowdclass.com/mc_privacypolicy"
export const externalUrl       = "https://membrz.club"
