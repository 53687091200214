import { styled } from '@mui/material/styles';
import { Chip } from '@mui/material';

const StyledChip = styled(Chip)(({ backgroundVariant = 'primary' }) => ({
  color: 'var(--primaryTextColor)',
  borderColor: 'var(--borderColor)',
  backgroundColor: `var(--${backgroundVariant}BackgroundColor)`,
  '& .MuiChip-deleteIcon': {
    color: 'var(--primaryTextColor)',
    '&:hover': {
      color: 'var(--primaryTextColor)',
    },
  },
}));

export default StyledChip;