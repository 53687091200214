import React, { useEffect } from 'react';
import { useController } from '@rest-hooks/react';
import { useParams } from 'react-router-dom';

import OrderResource from 'resources/organization/OrderResource';
import OrderDownloadResource from 'resources/organization/OrderDownloadResource';

import OrderFilter from './OrderFilter';

import DataSelectionTable from 'components/DataTable/DataSelectionTable';
import WithPermission from 'components/WithPermission';
import SalesFallback from 'components/fallbacks/Sales';
import OrderStats from 'components/OrderStats';

import { downloadCSV } from 'utils/downloadFile';
import { ordersColumns } from 'utils/tables/columns/ordersColumns';
import usePaginationAndFilteringController from 'utils/usePaginationAndFilteringController';
import { useError } from 'utils/useErrorController';
import useOrderDetailsModal from 'utils/useOrderDetailsModal';

const OrdersTabContent = () => {

  const { handleError } = useError();
  const params = useParams();
  const organizationId = parseInt(params.organizationId);

  const {
    objectState,
    fetchData,
    handlePageChange,
    handleFilterChange,
  } = usePaginationAndFilteringController({
      fetchDataResource: OrderResource,
      fetchDataParams: { organization_id: organizationId },
  })

  useEffect(() => {
    fetchData(1, {status: "completed"});
  }, []);

  const {fetch} = useController();

  const handleDownload = async () => {
    try {
      const response = await fetch(OrderDownloadResource.list(), {organization_id: organizationId, ...objectState.filteringParams});
      downloadCSV(response, 'orders.csv', 'text/csv');
    } catch (error) {
      handleError(error.message);
    }
  };

  const {handleOpenOrderDetails, OrderDetailsModalComponent} = useOrderDetailsModal();

  return (
    <WithPermission permission={'hasSales'} fallback={<SalesFallback />}>

      <OrderStats orders={objectState.filteredObjects} />
      <div style={{ flexGrow: 1 }}>
        <DataSelectionTable 
          records={objectState.filteredObjects}
          columns={ordersColumns(handleOpenOrderDetails)}
          onClick={handleDownload}
          serverPaginationProps={{
            paginationServer: true,
            paginationTotalRows: objectState.totalCount,
            page: objectState.page,
            onChangePage: handlePageChange
          }}
          filter={
            <OrderFilter
              onChange={handleFilterChange}
            />
          }
        />
      </div>

      <OrderDetailsModalComponent />

    </WithPermission>
  );
};

export default OrdersTabContent;
