import React from 'react';
import PropTypes from 'prop-types';

import Image from 'ui/Image';

import './styles.css';
import isMobileDevice from 'utils/mobileAndTabletCheck';

const ProfileImages = ({coverImageSrc, profileImageSrc, primaryBackgroundColor}) => {

  const isMobile = isMobileDevice();
  const coverImageHeight = isMobile ? '150px' : '210px' ;
  const profileImageHeight = isMobile ? '140px' : '190px';

  return (
    <div className='ui-profile-images'>
        <Image image={coverImageSrc} height={coverImageHeight}/>
        <Image className={`profile-pic ${isMobile && 'mobile'}`} image={profileImageSrc} rounded border height={profileImageHeight} primaryBackgroundColor={primaryBackgroundColor}/>  
    </div>
  );
};

ProfileImages.propTypes = {
    coverImageSrc: PropTypes.string,
    profileImageSrc: PropTypes.string,
    primaryBackgroundColor: PropTypes.string
};

export default ProfileImages;