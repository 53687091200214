const compare = (a, b, direction) => {
  let comparison = 0;

  if (a > b) {
    comparison = 1;
  } else if (a < b) {
    comparison = -1;
  }

  return direction === "desc" ? comparison * -1 : comparison;
};

const sortNumbers = (aNumber, bNumber, direction) => {
  return compare(aNumber, bNumber, direction);
};

const sortFields = (aValue, bValue, direction) => {
  const aField = aValue.toLowerCase();
  const bField = bValue.toLowerCase();

  return compare(aField, bField, direction);
};

const sortInvalidValues = (aValue, bValue, direction) => {
  if (!aValue && !bValue) {
    return 0;
  } else if (!aValue) {
    return direction === "desc" ? -1 : 1;
  } else if (!bValue) {
    return direction === "desc" ? 1 : -1;
  }
};

export const customSort = (rows, selector, direction) => {
  return rows.sort((a, b) => {
    const aValue = selector(a);
    const bValue = selector(b);

    if (!aValue && aValue !== 0 || !bValue && bValue !== 0) {
      return sortInvalidValues(aValue, bValue, direction);
    } else if (!isNaN(aValue) && !isNaN(bValue)) {
      return sortNumbers(parseFloat(aValue), parseFloat(bValue), direction);
    } else {
      return sortFields(aValue, bValue, direction);
    }
  });
};
