import AuthToken from 'utils/localStorage/AuthToken';

const API_HOST = process.env.REACT_APP_API_HOST;

const constructInit = () => {
  const authToken = AuthToken.read();
  return {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authToken}`,
    },
  };
};

export default async function fetchStats (rootUrl) {

    const url = new URL(`${API_HOST}/${rootUrl}/stats`);
    const init = constructInit();

    const response = await fetch(url, init);

    if (!response.ok) {
      throw `Network response error: ${response.status}`;
    }

    const parsed_response = await response.json();

    return parsed_response;
  }
