import React from "react";
import PropTypes from "prop-types";

import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { calendarViewOptions } from "utils/pageElementsHelper";

const ViewSelector = ({view, onChange}) => {
  const handleChange = (event) => {
    onChange({target: {name: "view", value: event.target.value}});
  };

  return(
    <FormControl>
        <InputLabel size="small" color="warning">
          View
        </InputLabel>

      <Select
        label="View"
        color="warning"
        size="small"
        fullWidth
        value={view}
        onChange={handleChange}
      >
        {calendarViewOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

ViewSelector.propTypes = {
  view: PropTypes.string,
  onChange: PropTypes.func
};

export default ViewSelector;
