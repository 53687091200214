import React from 'react';
import PropTypes from 'prop-types';

import Description from 'ui/text/Description';

import { Link } from '@mui/material';

import { t } from "i18n/index";

const Content = ({objectCategory, objectRedeemedCount}) => {

  const category = objectCategory === 'ticket' 
    ? t('literal:ticket_sc') 
    : t('literal:product_sc');

  const secondDescription = objectCategory === 'ticket' 
    ? t('profile:repeatPurchaseModal:descriptionSecond:ticket') 
    : t('profile:repeatPurchaseModal:descriptionSecond:product');
    
  const fourthDescription = objectCategory === 'ticket' 
    ? t('profile:repeatPurchaseModal:descriptionFourth:ticket') 
    : t('profile:repeatPurchaseModal:descriptionFourth:product');

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>

      <Description>
        {t('profile:repeatPurchaseModal:descriptionFirst:part1', {category})}
        {` ${objectRedeemedCount} ${objectRedeemedCount > 1 ? ` ${t('literal:times_sc')} ` : ` ${t('literal:once_sc')} `}`}
        {t('profile:repeatPurchaseModal:descriptionFirst:part2')}
      </Description>
      
      <Description>{secondDescription}</Description>

      <Description>
        {t('profile:repeatPurchaseModal:descriptionThird')}
      </Description>

      <Description>
        {fourthDescription}
        <Link href="mailto:hello@membrz.club" color="inherit" underline="hover">hello@membrz.club</Link>
        .
      </Description>
    
    </div>
  );
};

Content.propTypes = {
  objectCategory: PropTypes.string,
  objectRedeemedCount: PropTypes.number,
};

export default Content;